/** @jsxImportSource @emotion/react */
import "twin.macro";

import { useState } from "react";

import { CircularProgress } from "@mui/material/";

import PropTypes from "prop-types";

import { Button, PageTitle } from "@features/ui";

import { Contained } from "../StyledComponents";

const SimpleReportGenerator = ({
  reportTitle,
  reportGetFunc,
  children,
  disabled = false,
}) => {
  const [isLoading, setIsLoading] = useState(false);

  const handleGetReport = async () => {
    setIsLoading(true);
    await reportGetFunc();
    setIsLoading(false);
  };

  return (
    <Contained tw="space-y-6">
      <PageTitle title={reportTitle} fallbackUrl="/reports" />
      <div tw="flex justify-center min-w-min w-8/12 mx-auto">{children}</div>
      <div tw="flex justify-center gap-4 mb-8">
        <Button
          variant="contained"
          color="secondary"
          onClick={() => handleGetReport()}
          disabled={disabled}
        >
          GENERATE REPORT
        </Button>
      </div>
      {isLoading && (
        <div tw="flex justify-center">
          <CircularProgress size={100} tw="mt-16" />
        </div>
      )}
    </Contained>
  );
};

SimpleReportGenerator.propTypes = {
  reportTitle: PropTypes.string.isRequired,
  reportGetFunc: PropTypes.func.isRequired,
  children: PropTypes.object.isRequired,
};

export default SimpleReportGenerator;
