import { useSelector } from "react-redux";

import { useCurrentRegionalTerritorySubStates } from "@features/states";
import { Variant } from "@models/Variant";

export default function useGetVariantAvailableInventory() {
  const territory = useSelector((state: any) => state.app.territory);
  const { isRegional, subStateIds } = useCurrentRegionalTerritorySubStates();

  return function getVariantAvailableInventory(variant: Variant) {
    if (!variant.isActive) return 0;
    return variant.variantAllocations.reduce((total, alloc) => {
      const isMatch = isRegional
        ? alloc.subState && subStateIds.includes(alloc.subState.id)
        : alloc.territory?.id === territory;

      if (isMatch) {
        total += alloc.qty;
      }
      return total;
    }, 0);
  };
}
