/** @jsxImportSource @emotion/react */
import "twin.macro";
import tw from "twin.macro";

import React from "react";
import { Link } from "react-router-dom";

import { Warning } from "@mui/icons-material";
import { Tooltip } from "@mui/material";

import { addDays, endOfDay, isBefore } from "date-fns";
import { SmartTable, showFirst } from "src/components/SmartTable";
import {
  Column,
  PaginatedResourceProps,
} from "src/components/SmartTable/SmartTable";
import {
  formatDateString,
  formatMoneyString,
  formatUtcDate,
  utcDate,
} from "src/utility/utilityFunctions";

import { getAnaplanProgramsLabel } from "@features/anaplanPrograms/helpers";
import BudgetStatusChip from "@features/budgets/BudgetStatusChip";
import { OrderSetVariantSummary } from "@models/OrderSetVariantSummary";
import useRoleIs from "@utils/useRoleIs";

import { displayOrderSetStatus } from "../helpers";

type ApprovalsOrderSetVariantSummariesTableProps = PaginatedResourceProps & {
  data: OrderSetVariantSummary[];
  view: "approvals" | "rollup";
};

const isOverDue = (osvs: OrderSetVariantSummary) =>
  osvs.orderCalendarMonth && osvs.orderSetStatus === "in-progress"
    ? isBefore(
        addDays(
          endOfDay(utcDate(osvs.orderCalendarMonth.purchasingCloseDate)),
          1
        ),
        endOfDay(new Date())
      )
    : false;

const costColumn = {
  align: "right" as const,
  render: (value) => formatMoneyString(value),
};

const ApprovalsOrderSetVariantSummariesTable = ({
  data,
  view,
  ...tableProps
}: ApprovalsOrderSetVariantSummariesTableProps) => {
  const readOnly = useRoleIs()("read-only", "compliance");
  const columns: Column<OrderSetVariantSummary>[] = [
    {
      id: "budgets",
      label: "Budget Status",
      render: (budgets) => <BudgetStatusChip budgets={budgets} />,
    },
    {
      id: "orderSet.id",
      label: "Order-set #",
      sort: "id",
      render: (id) =>
        readOnly ? (
          id
        ) : (
          <Link
            tw="font-medium underline tracking-wide"
            to={`/orders/open/${id}#${view}`}
          >
            {id}
          </Link>
        ),
    },
    {
      id: "userName",
      label: "Ordered By",
      sort: "user-name",
      render: (name, row) => (
        <div tw="flex items-center">
          {isOverDue(row) && (
            <Tooltip
              placement="top-start"
              title="This order was not submitted on it's intended schedule, please notify your administrator if you are seeing this message."
            >
              <Warning fontSize="small" color="inherit" tw="pl-2" />
            </Tooltip>
          )}
          {row.orderSetStatus === "partial-approval-error" && (
            <Tooltip placement="top-start" title={"Partial Approval Error"}>
              <div tw="bg-red-50 text-red-800 rounded-lg p-1 items-center flex">
                <Warning tw="text-base" color="inherit" />
              </div>
            </Tooltip>
          )}
          {name}
        </div>
      ),
    },
    { id: "territoryNames", label: "Territory" },
    { id: "sequenceNumber", label: "Sequence #", sort: "item-number" },
    { id: "programName", label: "Program", sort: "program-name" },
    ...(view === "rollup"
      ? [
          {
            id: "anaplanPrograms",
            label: "Anaplan Program",
            render: getAnaplanProgramsLabel,
          },
        ]
      : []),
    {
      id: "variant.item.brands",
      label: "Brand",
      render: (brands) => showFirst(brands.map((b) => b.name)),
    },
    {
      id: "itemTypeDescription",
      label: "Item Type",
      sort: "item-type-description",
    },
    {
      id: "variant.item.comment",
      label: "Item Desc.",
    },
    { id: "stateNames", label: "State" },
    { id: "qtyPerPack", label: "Qty / Pack" },
    {
      id: "totalItemQty",
      label: "Total Items",
    },
    { id: "estimatedCost", label: "Est. Cost", ...costColumn },
    {
      id: "totalEstimatedCost",
      label: "Est. Total Costs",
      ...costColumn,
      render: (v, row) =>
        formatMoneyString(row.orderSetType === "from-inventory" ? 0 : v),
    },
    {
      id: "totalBeaconCost",
      label: "Total Beacon Cost",
      ...costColumn,
    },
    {
      id: "totalEstimatedFreight",
      label: "Est. Freight",
      ...costColumn,
    },
    {
      id: "totalEstimatesTaxes",
      label: "Est. Taxes",
      ...costColumn,
    },
    {
      id: "totalExtendedCost",
      label: "Est. Extended Total",
      ...costColumn,
      render: (v, row) =>
        formatMoneyString(
          row.orderSetType === "from-inventory"
            ? v - +row.totalEstimatedCost
            : v
        ),
    },
    {
      id: "orderSetSubmittedAt",
      label: "Order Submitted",
      sort: "submitted-at",
      render: (v) => formatUtcDate(v),
    },
    {
      id: "orderCalendarMonth.orderWindowCloseDate",
      label: "Order Window Close",
      render: formatDateString,
    },
    {
      id: "orderCalendarMonth.inMarketStartDate",
      label: "In Market Date",
      render: formatDateString,
    },
    {
      id: "orderSetStatus",
      label: "Status",
      render: (status) => displayOrderSetStatus(status),
    },
  ];

  return (
    <SmartTable
      columns={columns}
      rows={data}
      {...tableProps}
      rowCss={(row) => isOverDue(row) && tw`bg-red-100 hover:bg-red-50`}
    />
  );
};

export default ApprovalsOrderSetVariantSummariesTable;
