import { Navigate } from "react-router-dom";

import * as permissions from "src/constants/permissions";
import _ from "lodash";
import General from "src/pages/ProfileGeneral";

import AddressOptions from "@components/Profile/AddressOptions";
import CustomAddresses from "@components/Profile/CustomAddresses";
import DistributorAddresses from "@components/Profile/DistributorAddresses";
import DistributorEmails from "@components/Profile/DistributorEmails";
import FavoriteItems from "@components/Profile/FavoriteItems";

import { protectedRoute } from "./types";

const routeProfile: protectedRoute = {
  path: "profile",
  defaultChildrenAllowedRoles: _.without(
    permissions.NON_SUPPLIER_ROLES,
    "read-only",
    "compliance"
  ),
  children: [
    {
      path: "general",
      element: <General />,
      allowedRoles: permissions.ALL_ROLES,
    },
    { path: "favorite-addresses", element: <AddressOptions /> },
    { path: "custom-addresses", element: <CustomAddresses /> },
    { path: "distributor-change-requests", element: <DistributorAddresses /> },
    { path: "distributor-email-update", element: <DistributorEmails /> },
    { path: "favorite-items", element: <FavoriteItems /> },
    {
      path: "*",
      element: <Navigate to="/profile/general" />,
      allowedRoles: permissions.ALL_ROLES,
    },
  ],
};

export default routeProfile;
