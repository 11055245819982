/** @jsxImportSource @emotion/react */
import "twin.macro";

import { formatMoneyString } from "src/utility/utilityFunctions";

import { OrderType } from "@models/Order";

import { OrderCosts } from "./helpers";

export const DisplayBillable = ({
  billable,
  estimate,
  orderType,
}: {
  billable: number | string;
  estimate: number | string;
  orderType: OrderType;
}) => {
  // There is a distinction to be made between orderType and orderSetType, but for this component we don't care
  // since on from-inventory order-sets, you can only have from-inventory orders.
  if (orderType === "from-inventory" && +billable !== +estimate) {
    return (
      <span>
        <span tw="line-through opacity-50">
          &nbsp;{formatMoneyString(estimate)}&nbsp;
        </span>
        <span tw="pl-0.5">{formatMoneyString(billable)}</span>
      </span>
    );
  } else {
    return <span>{formatMoneyString(estimate)}</span>;
  }
};

export const BillableItemCost = ({
  costs,
  orderType,
}: {
  costs: OrderCosts;
  orderType: OrderType;
}) => {
  const billable =
    +costs.billableTotalActualCost || +costs.billableTotalEstimatedCost;
  const estimate = +costs.totalActualCost || +costs.totalEstimatedCost;

  return (
    <DisplayBillable
      billable={billable}
      estimate={estimate}
      orderType={orderType}
    />
  );
};

export const BillableBeaconCost = ({
  costs,
  orderType,
}: {
  costs: OrderCosts;
  orderType: OrderType;
}) => {
  const billable = +costs.billableTotalBeaconCost;
  const estimate = +costs.totalBeaconCost;

  return (
    <DisplayBillable
      billable={billable}
      estimate={estimate}
      orderType={orderType}
    />
  );
};

export const BillableShippingCost = ({
  costs,
  orderType,
}: {
  costs: OrderCosts;
  orderType: OrderType;
}) => {
  const billable =
    +costs.billableTotalActualShippingCost ||
    +costs.billableTotalEstimatedShippingCost;
  const estimate =
    +costs.totalActualShippingCost || +costs.totalEstimatedShippingCost;

  return (
    <DisplayBillable
      billable={billable}
      estimate={estimate}
      orderType={orderType}
    />
  );
};

export const BillableTaxCost = ({
  costs,
  orderType,
}: {
  costs: OrderCosts;
  orderType: OrderType;
}) => {
  const billable = +costs.billableTotalEstimatedTax;
  const estimate = +costs.totalEstimatedTax;

  return (
    <DisplayBillable
      billable={billable}
      estimate={estimate}
      orderType={orderType}
    />
  );
};
