/** @jsxImportSource @emotion/react */
import "twin.macro";

import { useEffect, useMemo, useState } from "react";
import { useDispatch } from "react-redux";

import {
  useHandleSubmitErrors,
  useProgramInfo,
} from "../../../hooks/planningToolHooks";
import {
  deleteItemProgram,
  updateItemProgram,
} from "../../../redux/slices/planningTool/itemProgramSlice";
import {
  defaultFormValues,
  useCustomForm,
} from "../../../utility/inputHelpers";
import ReadOnlyField from "../../Forms/ReadOnlyField";
import { ConfirmButton } from "../../StyledComponents";
import SaveStatusChip from "../../Utility/SaveStatusChip";
import CouponForm from "./CouponForm";
import FormErrors from "./FormErrors";
import InfoAccordions from "./InfoAccordions";
import {
  compareWithOrderType,
  couponDataFromForm,
  fields,
  formDataFromCoupon,
} from "./helpers";

const useSyncData = (setValue, data) => {
  const [key, value] = Object.entries(data)[0];
  useEffect(() => {
    setValue(key, value);
  }, [setValue, value, key]);
};

const TemplatedCouponEdit = ({ item }) => {
  const dispatch = useDispatch();
  const { isSubmitted, orderType: programOrderType } = useProgramInfo();

  const [isSaving, setIsSaving] = useState(false);

  const savedData = useMemo(() => formDataFromCoupon(item), [item]);
  const { estimatedQty, couponEstimatedRedemption } = savedData;

  const compareFunction = compareWithOrderType(programOrderType);

  const formAttrs = useCustomForm({
    mode: "onBlur",
    defaultValues: savedData,
    compareFunction,
  });

  const {
    control,
    isSaved,
    handleSubmit,
    setError,
    clearErrors,
    setValue,
    formState: { errors },
  } = formAttrs;

  useHandleSubmitErrors(setError, item.id);

  const requiredFields = fields.default.required.concat(
    isSubmitted ? fields.submitted.required : []
  );

  let disabledFields = isSubmitted
    ? fields.submitted.disabled
    : fields.default.disabled;

  disabledFields = disabledFields.concat(fields.templatedCoupon.disabled);

  // Default props for controlled inputs
  const controlled = (name, rules) => ({
    name,
    control,
    rules: { ...rules, required: requiredFields.includes(name) },
    disabled: disabledFields.includes(name),
    hidden: fields.templatedCoupon.hidden.includes(name),
    onBlur: () => {
      clearErrors();
      handleSubmit(handleSave)();
    },
  });

  const handleSave = (data) => {
    if (!compareFunction(defaultFormValues(data), savedData)) {
      setIsSaving(true);
      dispatch(
        updateItemProgram(
          item,
          couponDataFromForm(data, programOrderType),
          () => {
            setIsSaving(false);
          }
        )
      );
    }
  };

  const handleDelete = () => {
    if (item.id) {
      dispatch(deleteItemProgram(item.id));
    }
  };

  useSyncData(setValue, { estimatedQty });
  useSyncData(setValue, { couponEstimatedRedemption });

  return (
    <div tw="space-y-4">
      <div tw="flex justify-between items-end pb-2">
        <div tw="space-x-4 flex grow mr-4">
          <ReadOnlyField
            label="Barcode ID"
            value={item.couponBarcodeId || "N/A"}
          />

          <ReadOnlyField
            label="Sequence Number"
            value={item.itemNumber || "N/A"}
          />
        </div>
        <SaveStatusChip isSaved={isSaved} isSaving={isSaving} />
      </div>
      <FormErrors errors={errors} onClose={clearErrors} />
      <CouponForm form={formAttrs} controlled={controlled} item={item} />
      <div>Spec Code: {item.standardSpecificationCode ?? "N/A"}</div>
      <InfoAccordions item={item} type="templated-coupon" />
      <ConfirmButton
        confirm={
          isSubmitted
            ? "Are you sure you want to remove this item from this program? This can't be undone."
            : "Are you sure you want to permanently delete this item? This can't be undone."
        }
        danger
        onClick={handleDelete}
        disabled={isSaving}
      >
        {isSubmitted ? "Cancel Item" : "Delete Item"}
      </ConfirmButton>
    </div>
  );
};

export default TemplatedCouponEdit;
