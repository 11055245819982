import { Brand } from "@models/Brand";
import { CsvSchema } from "@utils/csv";

const brandSchema: CsvSchema<Brand> = [
  { id: "id", label: "ID" },
  { id: "name", label: "Name" },
  { id: "mandatoryLine", label: "Mandatory Line" },
  { id: "externalId", label: "External ID" },
  { id: "company", label: "Company" },
  { id: "brandTypeCode", label: "Brand Type Code" },
  { id: "brandGroupCode", label: "Brand Group Code" },
  { id: "isActive", label: "Is Active", f: (v) => (v ? "Yes" : "No") },
];

export const brandCsvSchema = brandSchema;
