/** @jsxImportSource @emotion/react */
import tw from "twin.macro";

import { AttachMoney } from "@mui/icons-material";
import { Tooltip } from "@mui/material";

const Tag = tw.div`inline-block px-1 py-0.5 text-xs font-medium tracking-wider rounded bg-neutral-100 text-neutral-800`;

const BrandFunded = () => (
  <Tooltip title="Brand Funded" arrow placement="top">
    <Tag tw="bg-lime-100">
      <AttachMoney tw="text-base text-lime-900" />
    </Tag>
  </Tooltip>
);

const Tags = {
  Restock: ({ brandFunded }: { brandFunded?: boolean }) => (
    <div tw="flex items-center mr-2 gap-1">
      <Tag tw="bg-blue-100 text-blue-900">RESTOCK</Tag>
      {brandFunded && <BrandFunded />}
    </div>
  ),
  DirectShip: () => <Tag tw="mr-2">DIRECT SHIP</Tag>,
};

export default Tags;
