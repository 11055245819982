/** @jsxImportSource @emotion/react */
import tw from "twin.macro";

import { Divider } from "@mui/material";

import { formatMoneyString } from "src/utility/utilityFunctions";

import { OrderVariant } from "@models";

import PaperTooltip from "../../ui/PaperTooltip";
import { DisplayBillable } from "../BillableCosts";
import { getExtendedCost } from "../helpers";

export default function OrderVariantCostBreakdown({
  orderVariant,
}: {
  orderVariant: OrderVariant;
}) {
  const extendedCost = getExtendedCost(orderVariant, orderVariant.orderType);
  return (
    <PaperTooltip
      tw="mt-3"
      placement="right-end"
      title={
        <>
          <h3 tw="font-medium mb-3">Cost break-down</h3>
          <div tw="grid grid-cols-[auto 1fr] gap-x-4 gap-y-1 text-sm">
            <div tw="text-neutral-500 tracking-wide">Item</div>
            <div
              css={[
                tw`text-right`,
                +orderVariant.billableTotalEstimatedCost === 0 &&
                  tw`line-through opacity-50`,
              ]}
            >
              {orderVariant.qty} x{" "}
              {formatMoneyString(orderVariant.estimatedCost)} ={" "}
              {formatMoneyString(orderVariant.totalEstimatedCost)}
            </div>

            {orderVariant.variant.item.includeBeacon && (
              <>
                <div tw="text-neutral-500 tracking-wide">Beacons</div>
                <div tw="text-right">
                  <DisplayBillable
                    billable={orderVariant.billableTotalEstimatedCost}
                    estimate={orderVariant.totalEstimatedCost}
                    orderType={orderVariant.orderType}
                  />
                </div>
              </>
            )}

            <div tw="text-neutral-500 tracking-wide">Freight</div>
            <div tw="text-right">
              <DisplayBillable
                billable={orderVariant.billableTotalEstimatedShippingCost}
                estimate={orderVariant.totalEstimatedShippingCost}
                orderType={orderVariant.orderType}
              />
            </div>

            <div tw="text-neutral-500 tracking-wide">Taxes</div>
            <div tw="text-right">
              <DisplayBillable
                billable={orderVariant.billableTotalEstimatedTax}
                estimate={orderVariant.totalEstimatedTax}
                orderType={orderVariant.orderType}
              />
            </div>

            <Divider tw="col-span-2 my-1" />

            <div tw="text-neutral-500 tracking-wide">Total</div>
            <div tw="font-medium text-right">
              {formatMoneyString(extendedCost)}
            </div>
          </div>
        </>
      }
    >
      <div tw="hover:bg-neutral-100 rounded">
        ={formatMoneyString(extendedCost)}
      </div>
    </PaperTooltip>
  );
}
