/** @jsxImportSource @emotion/react */
import "twin.macro";
import tw from "twin.macro";

import { useState } from "react";
import { Link } from "react-router-dom";

import { Card, TextField } from "@mui/material";

import _ from "lodash";
import { NON_SUPPLIER_ROLES } from "src/constants/permissions";
import { useNoFetch } from "src/hooks/UtilityHooks";

import { PageTitle, Type } from "@features/ui";
import { CldImage } from "@services/cloudinary";
import useRoleIs from "@utils/useRoleIs";

const sectionIcons = {
  General: "v1617273999/prod/Icons/SELECT_Icons_Global_Network_nmevmk.png",
  "Gallo Ops": "v1617273999/prod/Icons/SELECT_Icons_Production_zownaa.png",
  Vendor:
    "v1617273998/prod/Icons/SELECT_Icons_Shopper_Marketing_-_POS_el3abn.png",
  Finance:
    "v1617273999/prod/Icons/SELECT_Icons_Precise_Spend_Tracking_baw9z1.png",
  "Select Only":
    "v1617273999/prod/Icons/SELECT_Icons_Order_Management_m5zx9c.png",
};

const purchaserAdmin = ["purchaser", "select-purchaser", "super", "read-only"];
const noField1 = _.without(NON_SUPPLIER_ROLES, "field1");

const reports = [
  {
    section: "General",
    name: "Roadmap Forecast",
    desc: "Budgeting for Forward Deployed",
    link: "/reports/roadmap-forecast",
  },
  {
    section: "General",
    name: "Must Haves PDF",
    desc: "Images of key items that should be in the field by month",
    link: "/reports/must-haves",
    permissions: purchaserAdmin,
  },
  {
    section: "General",
    name: "New Item PDF",
    desc: "This report provides a PDF export of items, filtered by order type.",
    link: "/reports/items",
  },
  {
    section: "General",
    name: "Order Window Summary Presentation",
    desc: "This report provides a PDF export of items shipping to a territory, state, or specific address, on a specified order window. The PDF includes product images and quantities shipping to each location. Can also be exported nationally for an order window, for central's insight.",
    link: "/reports/order-window-summary-presentation",
  },
  {
    section: "General",
    name: "Order History By Order",
    desc: "This provides a detailed overview of the orders placed on RTA giving the following order detail; Order number, order type/window, shipping information, estimated/actual costs, etc.",
    link: "/orders/history/order",
  },
  {
    section: "General",
    name: "Order History By Item",
    desc: "Provides a detailed overview of the items ordered on RTA giving the following order detail; Order number, Sequence Number, order type/window, shipping detail, tracking information, estimated/actual costs, quantities ordered, etc.",
    link: "/orders/history/item",
  },
  {
    section: "General",
    name: "Shelf Inventory Report",
    desc: "Gives an overview of the territories an item's inventory is allocated too. This report only gives insight into territory specific inventory. It does not take into consideration general or national inventory.",
    link: "/reports/variant-allocation",
    permissions: ["field1"],
  },
  {
    section: "Gallo Ops",
    name: "Available Inventory Report",
    desc: "Snapshot of available inventory by territory.",
    link: "/reports/available-inventory",
  },
  {
    section: "Gallo Ops",
    name: "Item Inventory Report",
    desc: "Provides you with a snapshot over that the past year for ordered inventory items. Select a warehouse to see on hand inventory and order totals on items for the past 90 days, and past year.",
    link: "/reports/inventory",
    permissions: [...purchaserAdmin, "field2"],
  },
  // inventory
  {
    section: "Gallo Ops",
    name: "Inbound Inventory Report",
    desc: "Provides a snapshot of POs inbound to the Rapid Displays warehouse for Inventory, including Brand and Total quantity of units inbound.",
    link: "/reports/rapid-report",
    permissions: ["super", "purchaser"],
  },
  {
    section: "Gallo Ops",
    name: "Shelf Inventory Report",
    desc: "Gives an overview of the territories an item's inventory is allocated too. This report only gives insight into territory specific inventory. It does not take into consideration general or national inventory.",
    link: "/reports/variant-allocation",
    permissions: [...purchaserAdmin, "field2"],
  },
  {
    section: "Gallo Ops",
    name: "Single Item Inventory",
    desc: "Provides a quick snapshot of a single item's inventory and where it's located; warehouse location, inventory levels, territory allocations, and pending orders.",
    link: "/reports/single-item-inventory",
    permissions: [...purchaserAdmin, "field2"],
  },
  // order windows
  {
    section: "Gallo Ops",
    name: "Order Window Summary Report",
    desc: "Provides a summary of the total aggregated quantities for each item ordered on a Pre-Order / Ad Hoc Window.",
    link: "/reports/order-window-summary",
    permissions: noField1,
  },
  {
    section: "Gallo Ops",
    name: "Order Window Detail Report",
    desc: "Gives detailed order information for all items included in a Pre-Order / Ad Hoc Order Window, including who ordered it and where the item is shipping too.",
    link: "/reports/order-window-detail",
    permissions: noField1,
  },
  {
    section: "Gallo Ops",
    name: "Order Window Items Report",
    desc: "Exports a high-level overview of the items offered in a Pre-Order / Ad Hoc Order window, including item types/descriptions and program detail.",
    link: "/reports/order-window-items",
    permissions: noField1,
  },
  {
    section: "Gallo Ops",
    name: "Pre Order Non-Participant Report",
    desc: "Find out which users haven't ordered on a specific pre order window yet. Just select an order window in the filters by typing the name of the order window you'd like to find, and hit generate report.",
    link: "/reports/pre-order-non-participant",
    permissions: noField1,
  },
  {
    section: "Gallo Ops",
    name: "Pre-Order MOQ Analysis",
    desc: "This analysis provides a breakdown of ordered units by territory for any sequence #s that have not met MOQ on a specified Order Window (both channels).",
    link: "/reports/pre-order-moq-analysis",
    permissions: purchaserAdmin,
  },
  {
    section: "Gallo Ops",
    name: "Order Window MOQ",
    desc: "This analysis provides a breakdown of ordered units by territory for all sequence #s on a specified Order Window including pictures, MOQ, and Inventory on hand when applicable.",
    link: "/reports/order-window-moq",
    permissions: purchaserAdmin,
  },
  {
    section: "Gallo Ops",
    name: "Order Window Spend",
    desc: "This analysis provides a breakdown of spending by program for all sequence #s on a specified Order Window including pictures, MOQ, and Inventory on hand when applicable. Total spend is inclusive of shipping and tax.",
    link: "/reports/order-window-spend",
    permissions: purchaserAdmin,
  },
  {
    section: "Gallo Ops",
    name: "Brand Recap",
    desc: "This analysis provides a breakdown of a brand’s items on a specific order window. The report lists the new and rerun items that were selected for a given order window, diving into the production costs, quantity ordered, and overall item execution to evaluate how that brand performed from an execution standpoint.",
    link: "/reports/brand-recap",
    permissions: purchaserAdmin,
  },
  // POs
  {
    section: "Gallo Ops",
    name: "Purchase Order Report",
    desc: "Shows high level detail regarding the costs associated with a purchase order; Extended Costs, Freight Costs, Additional Freight Costs and invoice numbers.",
    link: "/reports/purchase-order-report",
    permissions: purchaserAdmin,
  },
  {
    section: "Gallo Ops",
    name: "Open Purchase Order Report",
    desc: 'Exports a report of all the open purchase orders currently on RTA based on ship date. Open purchase orders are those that carry a "In Progress" or "Shipping Hold" status and do not have an invoice number in RTA.',
    link: "/reports/open-purchase-orders",
    permissions: purchaserAdmin,
  },
  {
    section: "Gallo Ops",
    name: "PO Delivery Date Report",
    desc: "Highlights how delivery dates on POs have changed.",
    link: "/reports/po-delivery-date-report",
    permissions: purchaserAdmin,
  },
  {
    section: "Gallo Ops",
    name: "PO Compliance Report",
    desc: "Gives a detailed overview of the items currently pending compliance, in violation, or denied. It provides information such as order detail (PO #, Item #, Ship to state), compliance status, and compliance email status.",
    link: "/reports/po-compliance",
    permissions: purchaserAdmin,
  },
  // others
  {
    section: "Gallo Ops",
    name: "Request for Price Summary Report",
    desc: "Provides you with a breakdown of submitted quotes from Suppliers for a Preorder or Ad Hoc Ordering Window.",
    link: "/reports/rfp-summary",
    permissions: purchaserAdmin,
  },
  {
    section: "Gallo Ops",
    name: "Ordered Items Report",
    desc: "Provides a snapshot of the items ordered on RTA. You can filter the report by Order status and/or Order Type which will provide the total quantities ordered and canceled for an item.",
    link: "/reports/ordered-items",
    permissions: ["super"],
  },

  {
    section: "Gallo Ops",
    name: "Compliance Audit Report",
    desc: "Shows a history of actions related to compliance such as Price Overrides, Internal Approvals, Item Overrides etc.",
    link: "/reports/compliance-audit",
    permissions: [...purchaserAdmin, "compliance"],
  },
  {
    section: "Gallo Ops",
    name: "Custom Coupons",
    desc: "Provides a snapshot of the custom coupons ordered on RTA. The report provides you with detail regarding who ordered the coupon, where it's shipping, quantities ordered, and item details (type, promotion, etc.)",
    link: "/reports/custom-coupon",
    permissions: noField1,
  },
  {
    section: "Gallo Ops",
    name: "Coupon Production Report",
    desc: "",
    link: "/reports/coupon-production",
    permissions: noField1,
  },
  {
    section: "Vendor",
    name: "CDC Reports",
    desc: "A series of reports that are used by the CDC or Rapid Displays for monthly focus programs, specifically for print items. These reports include the transfer advice report, CDC ship list, and CDC in-bound list.",
    link: "/reports/cdc",
    permissions: ["super", "select-purchaser"],
  },
  {
    section: "Vendor",
    name: "Bulk PO Shipping Report",
    desc: "This allows the user to export and upload ship lists for multiple POs at the same time.",
    link: "/reports/po-ship-list",
    permissions: ["super", "select-purchaser", "purchaser"],
  },
  {
    section: "Vendor",
    name: "Select Invoicing Report",
    desc: "",
    link: "/reports/invoicing",
    permissions: ["super", "select-purchaser"],
  },
  {
    section: "Finance",
    name: "Order Detail Report",
    desc: "Provides you with estimated and actual order costs which can be filtered by actual or estimated ship dates.",
    link: "/reports/order-detail",
    permissions: purchaserAdmin,
  },
  {
    section: "Finance",
    name: "Finance Journal Report",
    desc: "This gives a snapshot of coded orders and proper GL accounts which can be filter by ship date.",
    link: "/reports/finance-journal",
    permissions: purchaserAdmin,
  },
  {
    section: "Finance",
    name: "Supplier Spend Reports",
    desc: "This provides a snapshot of the total spend on RTA broken down by the Supplier. This can either be run has a high-level summary report or a more in-depth detail report which shows detail down to the PO level.",
    link: "/reports/supplier-spend",
    permissions: ["super"],
  },
  {
    section: "Finance",
    name: "QN Invoice Report",
    desc: "This report supports the QN budget reconciliation process.",
    link: "/reports/qn-invoice",
    permissions: purchaserAdmin,
  },
  {
    section: "Finance",
    name: "PO Accrual Reporting",
    desc: "This report exports all POs that should be accrued for a specific year – can be pulled as a summary or detailed report.",
    link: "/reports/po-accrual",
    permissions: purchaserAdmin,
  },
  {
    section: "Select Only",
    name: "Check Invoice Report",
    desc: 'Provides a detailed breakdown of an invoice and what POs are included on that invoice. This is also known as the "Invoice Double Check" Report.',
    link: "/reports/invoice-double-check",
    permissions: ["super", "select-purchaser"],
  },
  {
    section: "Select Only",
    name: "Product List Report",
    desc: "Provides a list of all items in RTA, including order status, brand, est. cost, and more.",
    link: "/reports/product-list",
    permissions: ["super"],
  },
  {
    section: "Select Only",
    name: "Product List PDF Report",
    desc: "Provides a list of all items in RTA, including product images rendered as a PDF.",
    link: "/reports/product-list-pdf",
    permissions: ["super"],
  },
];

const ReportsLanding = () => {
  const roleIs = useRoleIs();
  const [search, setSearch] = useState("");

  useNoFetch();

  let filteredReports = reports;

  filteredReports = filteredReports.filter(
    (report) => !report.permissions || roleIs(report.permissions as any)
  );
  if (search.length > 0) {
    const terms = search.toLowerCase().split(/[\W-]+/g);
    filteredReports = filteredReports.filter((report) => {
      return terms.every(
        (term) =>
          report.name.toLowerCase().includes(term) ||
          report.desc.toLowerCase().includes(term) ||
          report.link.toLowerCase().includes(term)
      );
    });
  }

  const groupedReports = _.groupBy(filteredReports, "section");

  return (
    <div tw="max-w-screen-md mx-auto py-8 space-y-6">
      <PageTitle title="Reports" />
      <TextField
        id="reports-search"
        size="medium"
        placeholder="Search reports"
        fullWidth
        autoFocus
        value={search}
        onChange={(e) => setSearch(e.target.value)}
        css={{
          ".MuiOutlinedInput-input": tw`text-lg`,
        }}
      />
      {Object.entries(groupedReports).map(([section, reports]) => (
        <Card
          key={section}
          component="section"
          tw="relative flex items-start p-3 px-6 overflow-visible"
        >
          <div tw="w-40 flex-none sticky top-[var(--header-height)] m-4">
            <Type.H2 tw="mb-2 text-center">{section}</Type.H2>
            <div tw="rounded-full border-4 border-neutral-100">
              <CldImage
                src={`https://res.cloudinary.com/brandhub/image/upload/${sectionIcons[section]}`}
                alt={section}
              />
            </div>
          </div>
          <div tw="w-full max-w-prose">
            {reports.map((report, i) => (
              <Link
                key={i}
                className="group"
                tw="
      block rounded-lg p-4
      border border-transparent
      transition hover:(bg-neutral-50 border-neutral-100)"
                to={report.link}
              >
                <h3 tw="text-lg text-neutral-800 font-medium group-hover:underline">
                  {report.name}
                </h3>
                <p tw="text-neutral-600">{report.desc}</p>
              </Link>
            ))}
          </div>
        </Card>
      ))}
    </div>
  );
};

export default ReportsLanding;
