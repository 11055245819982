import { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";

import { FormControlLabel, Switch, TextField, Typography } from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";

import { FixedHeightScrollLastChild } from "@components/StyledComponents";
import { RequestReportButton, useReport } from "@features/reports";
import { PageTitle } from "@features/ui";

import BrandModal from "../components/Settings/BrandModal";
import BrandTable from "../components/Settings/BrandTable";
import { useDetailedInput } from "../hooks/InputHooks";
import {
  clearAllBrands,
  clearBrands,
  fetchAssets,
  fetchBrands,
} from "../redux/slices/items/brandSlice";

const useStyles = makeStyles((theme) => ({
  ...theme.global,
  inputField: {
    marginBottom: "15px",
    width: "48%",
    [theme.breakpoints.down("md")]: {
      width: "100%",
    },
  },
}));

const AdminBrands = () => {
  const classes = useStyles();
  const dispatch = useDispatch();

  const debounce = useRef(null);

  const [modal, handleModal] = useState(false);
  const [isActive, setIsActive] = useState(true);
  const [currentBrandId, setCurrentBrandId] = useState(null);

  const { brandList, isLoading } = useSelector((state) => state.brands);

  const handleModalClose = () => {
    handleModal(false);
    setCurrentBrandId(null);
  };

  const handleModalOpen = (id) => {
    setCurrentBrandId(id);
    handleModal(true);
  };

  const handleBrandSearch = (value, _type, _filter) => {
    clearTimeout(debounce.current);

    debounce.current = setTimeout(() => {
      dispatch(fetchBrands(value, isActive));
    }, 250);
  };

  useReport("brands", ["brands", { filter: { isActive: isActive || null } }]);

  const { value: search, bind: bindSearch } = useDetailedInput(
    "",
    handleBrandSearch
  );

  useEffect(() => {
    dispatch(fetchBrands());
    dispatch(fetchAssets());
    dispatch(clearAllBrands());
    return () => dispatch(clearBrands());
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <FixedHeightScrollLastChild>
      {modal && (
        <BrandModal
          open={modal}
          handleClose={handleModalClose}
          id={currentBrandId}
          brandList={brandList}
        />
      )}
      <div className={classes.titleBar}>
        <PageTitle title="Edit Brands" />
        <div className={classes.innerConfigDiv}>
          <RequestReportButton reportName="brands">
            Export Brand Report
          </RequestReportButton>
        </div>
      </div>

      <div style={{ display: "flex", alignItems: "center", width: "100%" }}>
        <TextField
          size="small"
          className={classes.inputField}
          variant="outlined"
          color="secondary"
          name="search"
          type="text"
          label="Search Brands"
          value={search}
          {...bindSearch}
        />
        <div
          style={{
            width: "22%",
            height: "100%",
            display: "flex",
            alignItems: "center",
            marginLeft: "3%",
          }}
        >
          <Typography className={classes.bodyText}>Status:</Typography>
          <FormControlLabel
            style={{ marginLeft: "5px" }}
            control={
              <Switch
                checked={isActive}
                onChange={() => {
                  dispatch(fetchBrands(search, !isActive));
                  setIsActive(!isActive);
                }}
                name="active-switch"
              />
            }
            label={isActive ? "Active" : "Inactive"}
          />
        </div>
      </div>

      <BrandTable
        handleBrandClick={handleModalOpen}
        brands={brandList}
        isLoading={isLoading}
      />
    </FixedHeightScrollLastChild>
  );
};

export default AdminBrands;
