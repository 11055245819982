/** @jsxImportSource @emotion/react */
import "twin.macro";

import { without } from "lodash";

import { Contained } from "@components/StyledComponents";
import { Dashboard } from "@features/ui";
import useRoleIs from "@utils/useRoleIs";

import {
  PLANNING_TOOL_ROLES,
  PLANNING_TOOL_SUPPLIER,
  RFQ_ROLES,
} from "../../constants/permissions";

const PlanningDash = () => {
  const { role } = useRoleIs();

  const cardData = [
    {
      titleText: "Search Programs",
      link: "/planning/programs",
      info: "Search and filter all programs.",
      image:
        "https://res.cloudinary.com/brandhub/image/upload/v1610483653/prod/Icons/Item_Catalog_Current_ykkrrc.png",
      roles: PLANNING_TOOL_ROLES,
      icon: "list-check",
    },
    {
      titleText: "On-Demand Order",
      link: "/orders/items/on-demand",
      info: "Place orders for items that will need to be produced for your order",
      image:
        "https://res.cloudinary.com/brandhub/image/upload/v1610483653/prod/Icons/Place_On_Demand_Order_zoymv2.png",
      roles: [PLANNING_TOOL_SUPPLIER],
      icon: "truck-clock",
    },
    {
      titleText: "Create National Program",
      link: "/planning/programs#new",
      info: "Create national programs and coupons, and view records.",
      image:
        "https://res.cloudinary.com/brandhub/image/upload/w_1000,ar_16:9,c_fill,g_auto,e_sharpen/v1617273998/prod/Icons/SELECT_Icons_Shopper_Marketing_-_POS_el3abn.png",
      roles: without(PLANNING_TOOL_ROLES, PLANNING_TOOL_SUPPLIER, "field2"),
      icon: "square-plus",
    },
    {
      titleText: "Activate Regional or Key Account Program",
      link: "/planning/activate",
      info: "Activate programs fed from anaplan",
      image:
        "https://res.cloudinary.com/brandhub/image/upload/w_1000,ar_16:9,c_fill,g_auto,e_sharpen/v1617273998/prod/Icons/SELECT_Icons_Shopper_Marketing_-_POS_el3abn.png",
      roles: without(PLANNING_TOOL_ROLES, PLANNING_TOOL_SUPPLIER),
      // icon: "arrow-up-from-square",
      icon: "square-check",
    },
    {
      titleText: "Search RFQs",
      link: "/planning/rfqs",
      info: "Filtered quote view including product level quote details.",
      image:
        "https://res.cloudinary.com/brandhub/image/upload/w_1000,ar_16:9,c_fill,g_auto,e_sharpen/v1617273999/prod/Icons/SELECT_Icons_Order_Management_m5zx9c.png",
      roles: RFQ_ROLES,
      icon: "list-timeline",
    },

    {
      titleText: "Reports",
      link: "/planning/reports",
      info: "Run program reports.",
      image:
        "https://res.cloudinary.com/brandhub/image/upload/v1610483653/prod/Icons/Wrap_Up_Reports_hwy0en.png",
      roles: without(PLANNING_TOOL_ROLES, PLANNING_TOOL_SUPPLIER),
      icon: "chart-bar",
    },
  ].filter(({ roles }) => roles.includes(role));

  return (
    <Contained>
      <h2 tw="mb-6 mt-4 text-2xl text-neutral-600">Program Planning</h2>
      <Dashboard>
        {cardData.map((data, index) => (
          <Dashboard.Card
            key={index}
            title={data.titleText}
            secondary={data.info}
            link={data.link}
            icon={data.icon}
            color={"gray"}
          />
        ))}
      </Dashboard>
    </Contained>
  );
};

export default PlanningDash;
