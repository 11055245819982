/** @jsxImportSource @emotion/react */
import tw from "twin.macro";

import { useEffect } from "react";

import { SelectInput } from "@components/Forms/ControlledInputs";
import { useActiveOrderWindows } from "@features/orderCalendarMonths";

import { Bordered } from "../components/StyledComponents";
import { useFilterContext } from "../filterContext";

const Chip = () => {
  const { control, getValues, setValue, submitFilters } = useFilterContext();
  const options = useActiveOrderWindows({ endDate: "reportDate" });

  const disabled = options.length < 2;

  useEffect(() => {
    const orderWindowId = getValues("orderWindowId");
    const firstOptionId = options[0]?.id ?? null;
    if (!orderWindowId && firstOptionId) {
      setValue("orderWindowId", firstOptionId);
      submitFilters();
    } else if (
      orderWindowId &&
      !options.find((ow) => ow.id === orderWindowId)
    ) {
      setValue("orderWindowId", firstOptionId);
      submitFilters();
    }
  }, [options, setValue, getValues, submitFilters]);

  if (options.length === 0) {
    return (
      <Bordered tw="px-3 text-sm bg-neutral-200 text-neutral-500">
        No Active Order Windows
      </Bordered>
    );
  }
  if (options.length === 1) {
    return (
      <Bordered tw="px-3 text-sm bg-neutral-200">
        <span tw="text-neutral-600">Order Window is&nbsp;</span>
        <span tw="font-medium text-neutral-800">
          {options[0]?.orderWindowName}
        </span>
      </Bordered>
    );
  }

  return (
    <Bordered role={disabled ? "disabled" : "button"} tw="h-8">
      <SelectInput
        control={control}
        name="orderWindowId"
        displayEmpty
        options={options.map((ocm) => ({
          id: ocm.id,
          name: ocm.orderWindowName,
        }))}
        label=""
        renderValue={(val) =>
          val ? (
            <>
              <span tw="text-neutral-600">Order Window is&nbsp;</span>
              <span tw="font-medium text-neutral-800">
                {options.find((ow) => ow.id === val)?.orderWindowName}
              </span>
            </>
          ) : (
            <b>Select Order Window</b>
          )
        }
        css={{
          "&": tw`h-full rounded-full appearance-none bg-neutral-200`,
          ".MuiOutlinedInput-root fieldset": tw`border-0`,
          ".MuiInputBase-root": tw`text-sm text-neutral-600`,
        }}
        onChange={() => submitFilters()}
      />
    </Bordered>
  );
};

const filterActiveOrderWindowSelector = {
  name: "activeOrderWindow",
  formControls: ["orderWindowId"],
  Chip,
  alwaysShow: true,
};

export default filterActiveOrderWindowSelector;
