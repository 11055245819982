import { createQueryKeys } from "@lukemorales/query-key-factory";
import { useQuery } from "@tanstack/react-query";

import { State } from "@models/State";
import client, { RequestParams } from "@services/api";
import { QueryOptions } from "@utils/reactQuery";

export const statesKeyFactory = createQueryKeys("states", {
  list: (params) => ({
    queryKey: [params],
    queryFn: () =>
      client.get<State[]>("states", { params }).then((res) => res.data),
  }),
});

export const useStatesQuery = (
  params?: RequestParams,
  config?: QueryOptions<State[]>
) => {
  return useQuery({
    ...statesKeyFactory.list(params),
    staleTime: Infinity,
    ...config,
  });
};
