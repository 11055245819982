/** @jsxImportSource @emotion/react */
// Inputs controlled by react-hook-form
import "twin.macro";

import { ReactNode, forwardRef } from "react";
import { Controller, ControllerProps } from "react-hook-form";

import {
  Autocomplete,
  AutocompleteProps,
  AutocompleteValue,
  Checkbox,
  CheckboxProps,
  FormControl,
  FormControlLabel,
  FormControlLabelProps,
  FormHelperText,
  FormLabel,
  InputBase,
  InputBaseProps,
  InputLabel,
  ListSubheader,
  MenuItem,
  RadioGroup,
  RadioGroupProps,
  Select,
  SelectProps,
  TextFieldProps,
} from "@mui/material";
import { DatePicker, DatePickerProps } from "@mui/x-date-pickers/DatePicker";

import { addYears, format } from "date-fns";

import { transformEventValue } from "../../utility/inputHelpers";
import { utcDate } from "../../utility/utilityFunctions";
import { DefaultTextField } from "./DefaultInputs";

type Omit<T, K extends keyof T> = Pick<T, Exclude<keyof T, K>>;
type WithOptional<T, K extends keyof T> = Omit<T, K> & Partial<Pick<T, K>>;

type InputOption = {
  id: string;
  name: string;
  [key: string]: any;
};
const defaultOptions = [{ id: "", label: "" }];

type SelectInputProps = Omit<ControllerProps<any>, "render"> &
  SelectProps & {
    options: InputOption[];
    label: string;
  };

export const SelectInput: React.FC<SelectInputProps> = ({
  control,
  name,
  options,
  label,
  className,
  rules,
  disabled,
  onChange,
  fullWidth,
  ...props
}) => (
  <Controller
    control={control}
    name={name}
    rules={rules}
    render={({ field, fieldState: { error } }) => (
      <FormControl
        className={className}
        {...{ error: !!error, disabled }}
        size="small"
        fullWidth={fullWidth}
        hidden={props.hidden}
      >
        <InputLabel id={name} className="MuiInputLabel-outlined">
          {label}
        </InputLabel>
        <Select
          label={label}
          labelId={name}
          variant="outlined"
          margin="dense"
          className={className}
          tw="bg-white"
          {...field}
          {...props}
          value={field.value || ""}
          onChange={(...args) => {
            field.onChange(...args);
            onChange?.(...args);
          }}
          MenuProps={{
            ...props.MenuProps,
            PaperProps: { sx: { maxHeight: 400 } },
          }}
        >
          {(options?.length ? options : defaultOptions).map((o, i) =>
            o.header ? (
              <ListSubheader key={i}>{o.label}</ListSubheader>
            ) : (
              <MenuItem key={`${o.id}-${i}`} value={o.id}>
                {o.name ?? o.label}
              </MenuItem>
            )
          )}
        </Select>
        {error && <FormHelperText>{error?.message}</FormHelperText>}
      </FormControl>
    )}
  />
);

export type ControlledAutocompleteInputProps<
  Multiple extends boolean,
  DisableClearable extends boolean,
> = Omit<ControllerProps<any>, "render"> &
  WithOptional<
    Omit<
      AutocompleteProps<
        InputOption,
        Multiple,
        DisableClearable,
        undefined,
        "div"
      >,
      "onChange"
    >,
    "renderInput"
  > & {
    label?: string;
    onChange?: (
      option: AutocompleteValue<
        InputOption,
        Multiple,
        DisableClearable,
        undefined
      >
    ) => void;
  };
export const AutocompleteInput = <
  Multiple extends boolean = false,
  DisableClearable extends boolean = false,
>({
  control,
  name,
  options,
  label,
  rules,
  onChange,
  ...props
}: ControlledAutocompleteInputProps<Multiple, DisableClearable>) => {
  // Create a lookup for faster fetching
  const optionsLookup = options.reduce((obj, a) => {
    obj[a.id] = a;
    return obj;
  }, {});

  const getOpObj = (option) => {
    if (!option.id) option = optionsLookup[option];
    return option;
  };

  return (
    <Controller
      control={control}
      name={name}
      rules={rules}
      render={({ field, fieldState: { error } }) => (
        <Autocomplete
          options={options}
          size="small"
          isOptionEqualToValue={(option, value) =>
            option?.id === getOpObj(value)?.id
          }
          getOptionLabel={(option) =>
            getOpObj(option)?.label || getOpObj(option)?.name || ""
          }
          renderInput={(params) => (
            <DefaultTextField
              {...params}
              label={label}
              error={!!error}
              helperText={error?.message}
            />
          )}
          slotProps={{
            popper: { placement: "bottom-start" },
          }}
          {...field}
          {...props}
          // overrides
          onChange={(_, data) => {
            const newValue = (Array.isArray(data)
              ? data
              : data?.id || "") as unknown as Multiple extends true
              ? InputOption[]
              : string;
            field.onChange(newValue);
            onChange?.(data);
          }}
          value={field.value || null}
        />
      )}
    />
  );
};

type CheckboxInputProps = Omit<ControllerProps<any>, "render"> &
  CheckboxProps & {
    label: string;
    labelProps?: Omit<FormControlLabelProps, "control" | "label">;
  };

export const CheckboxInput = ({
  control,
  name,
  label,
  labelProps,
  onChange,
  ...props
}: CheckboxInputProps) => (
  <Controller
    control={control}
    name={name}
    render={({ field: { value, ...field } }) => (
      <FormControlLabel
        label={label}
        {...labelProps}
        control={
          <Checkbox
            checked={value ?? false}
            inputProps={{ "aria-label": label }}
            {...field}
            {...props}
            onChange={(e, checked) => {
              const event = {
                ...e,
                target: { ...e.target, value: e.target.checked },
              };
              field.onChange(event);
              onChange?.(e, checked);
            }}
          />
        }
      />
    )}
  />
);

type ControlledTextInputType = Omit<ControllerProps<any>, "render"> &
  TextFieldProps & {
    rules?: ControllerProps["rules"] & {
      transform?: (value: string) => string;
      transformOnBlur?: (value: string) => string;
    };
  };
export const TextInput = ({
  control,
  name,
  rules,
  onBlur,
  onChange,
  ...props
}: ControlledTextInputType) => {
  const { transform, transformOnBlur, ...registerRules } = rules || {};
  return (
    <Controller
      control={control}
      name={name}
      rules={registerRules}
      render={({ field: { ref: _, ...field }, fieldState: { error } }) => (
        <DefaultTextField
          {...field}
          {...props}
          value={field.value ?? ""}
          onChange={(e) => {
            const te = transform ? transformEventValue(e, transform) : e;
            field.onChange(te);
            onChange && onChange(te);
          }}
          onBlur={(e) => {
            const te = transformOnBlur
              ? transformEventValue(e, transformOnBlur)
              : e;
            transformOnBlur && field.onChange(te);
            onBlur && onBlur(te);
            field.onBlur();
          }}
          error={!!error}
          helperText={error?.message || props.helperText}
        />
      )}
    />
  );
};

type ControlledInputBaseProps = Omit<ControllerProps, "render"> &
  InputBaseProps & {
    rules?: ControllerProps["rules"] & {
      transform?: (value: string) => string;
      transformOnBlur?: (value: string) => string;
    };
    defaultValue?: string;
  };
export const ControlledInputBase = forwardRef<
  HTMLInputElement,
  ControlledInputBaseProps
>(
  (
    { name, control, onChange, onBlur, rules, defaultValue = "", ...props },
    ref
  ) => {
    const { transform, transformOnBlur, ...registerRules } = rules || {};
    return (
      <Controller
        control={control}
        name={name}
        rules={registerRules}
        render={({ field: { ref: _, ...field }, fieldState: { error } }) => (
          <InputBase
            inputRef={ref}
            {...field}
            {...props}
            value={field.value ?? defaultValue}
            onChange={(e) => {
              const te = transform ? transformEventValue(e, transform) : e;
              field.onChange(te);
              onChange && onChange(te);
            }}
            onBlur={(e) => {
              const te = transformOnBlur
                ? transformEventValue(e, transformOnBlur)
                : e;
              transformOnBlur && field.onChange(te);
              onBlur && onBlur(te);
              field.onBlur();
            }}
            error={!!error}
            // helperText={error?.message || props.helperText}
          />
        )}
      />
    );
  }
);

type DateInputProps = Omit<ControllerProps<any>, "render"> &
  DatePickerProps<Date> & {
    rules?: ControllerProps["rules"];
    onChange?: (value: Date | null) => void;
    onBlur?: (dateString: string) => void;
    slotProps?: DatePickerProps<Date>["slotProps"] & {
      field: {
        error?: boolean;
        helperText?: string;
        onBlur?: (date: Date | null) => void;
      };
      popper: {
        style?: React.CSSProperties;
      };
    };
  };

export const DateInput = ({
  name,
  control,
  onBlur,
  rules,
  onChange,
  ...props
}: DateInputProps) => (
  <Controller
    name={name}
    control={control}
    rules={rules}
    render={({ field: { ref: _, ...field }, fieldState: { error } }) => (
      <DatePicker
        minDate={utcDate("2016-01-01")}
        maxDate={utcDate("2031-01-01")}
        slotProps={{
          ...props.slotProps,
          field: {
            size: "small",
            ...props.slotProps?.field,
            onBlur: () => {
              props.slotProps?.field?.onBlur?.(field.value);
              onBlur?.(field.value);
            },
            error: !!error || props.slotProps?.field?.error,
            helperText: error?.message || props.slotProps?.field?.helperText,
          } as any,
        }}
        value={(field.value || null) && utcDate(field.value)}
        onAccept={(date) => {
          if (!date) return;
          const d = format(date, "yyyy-MM-dd");
          field.onChange(d);
          onBlur?.(d);
        }}
        onChange={(value, context) => {
          let date = value;
          if (value && value.getFullYear() > 20 && value.getFullYear() < 100) {
            date = addYears(value, 2000);
          }
          if (!context.validationError && date) {
            onChange?.(value);
            field.onChange(format(date, "yyyy-MM-dd"));
          }
          if (!date) {
            field.onChange(null);
            onChange?.(null);
          }
        }}
        {...props}
      />
    )}
  />
);

export type ControlledRadioGroupProps = Omit<ControllerProps<any>, "render"> &
  RadioGroupProps & {
    label?: ReactNode;
  };

export const ControlledRadioGroup = ({
  name,
  control,
  rules,
  label,
  children,
  className,
  ...props
}: ControlledRadioGroupProps) => (
  <Controller
    name={name}
    control={control}
    rules={rules}
    render={({ field }) => (
      <FormControl className={className}>
        <FormLabel>{label}</FormLabel>
        <RadioGroup
          {...field}
          {...props}
          onChange={(e, v) => {
            field.onChange(v);
            props.onChange?.(e, v);
          }}
          value={field.value ?? ""}
        >
          {children}
        </RadioGroup>
      </FormControl>
    )}
  />
);
