/** @jsxImportSource @emotion/react */
import "twin.macro";

import { Link } from "react-router-dom";

import { IconButton, Tooltip } from "@mui/material";

import { useHelpScoutBeacon } from "@features/helpScout";
import { FaIcon } from "@features/ui";

import AvatarButton from "./AvatarButton";

const UserSection = () => {
  // hook to display helpscout beacon
  useHelpScoutBeacon();

  const handleSupportClick = (e) => {
    e.stopPropagation();
    window.Beacon("toggle");
  };
  return (
    <div className="sidebar__user-section">
      <div tw="flex items-center justify-between gap-4 w-[calc(var(--sidebar-open-width) - 3rem)]">
        <Tooltip title={"Profile"} placement="top" arrow>
          <AvatarButton />
        </Tooltip>
        <Tooltip title="Support" placement="top" arrow>
          <IconButton onClick={handleSupportClick}>
            <FaIcon icon="headset" tw="text-xl" />
          </IconButton>
        </Tooltip>
        <Tooltip title="Log out" placement="top" arrow>
          <IconButton component={Link} to="/logout">
            <FaIcon icon="sign-out-alt" tw="text-xl" />
          </IconButton>
        </Tooltip>
      </div>
    </div>
  );
};

export default UserSection;
