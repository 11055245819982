/** @jsxImportSource @emotion/react */
import "twin.macro";

import { useState } from "react";
import { useDispatch } from "react-redux";

import { Button, ButtonProps, Tooltip } from "@mui/material";

import { FaIcon } from "@features/ui";

import { requestReport } from "./globalReportSlice";

type RequestReportButtonProps = ButtonProps & {
  reportName: string;
  title?: string;
};

const RequestReportButton = ({
  reportName,
  title = "Export Table as CSV",
  children,
  ...props
}: RequestReportButtonProps) => {
  const dispatch = useDispatch();
  const [loading, setLoading] = useState<boolean>(false);

  const handleClick = () => {
    dispatch(requestReport(reportName));
    setLoading(true);
    setTimeout(() => setLoading(false), 2000);
  };

  const icon = loading ? (
    <FaIcon icon="spinner" tw="animate-spin text-base!" />
  ) : (
    <FaIcon icon="arrow-down-to-line" variant="solid" tw="text-base!" />
  );

  return (
    <Tooltip title={title} arrow>
      <Button
        variant="contained"
        color="secondary"
        onClick={handleClick}
        tw="min-w-0 py-3"
        {...props}
        startIcon={children ? icon : undefined}
      >
        {!children && icon}
        {children}
      </Button>
    </Tooltip>
  );
};

export default RequestReportButton;
