import { useEffect } from "react";
import { useSelector } from "react-redux";

import FormControl from "@mui/material/FormControl";
import InputLabel from "@mui/material/InputLabel";
import ListSubheader from "@mui/material/ListSubheader";
import MenuItem from "@mui/material/MenuItem";
import Select from "@mui/material/Select";
import Typography from "@mui/material/Typography";

import { isBefore, startOfDay } from "date-fns";
import PropTypes from "prop-types";

import { useAllOcmsQuery } from "@features/orderCalendarMonths";

import { formatDate } from "../../../utility/utilityFunctions";

const now = new Date();

const splitOrderWindowOptions = (ocms) => {
  const [past, upcoming] = ocms.reduce(
    (grp, ocm) => {
      grp[+!isBefore(startOfDay(formatDate(ocm.inMarketStartDate)), now)].push(
        ocm
      );
      return grp;
    },
    [[], []]
  );

  const groupByType = upcoming.reduce(
    (grp, ocm) => {
      grp[ocm.orderWindowType].push(ocm);
      return grp;
    },
    { national: [], secondary: [] }
  );

  return [
    { label: "Upcoming National", header: true },
    ...groupByType.national,
    ...(groupByType.secondary.length > 0
      ? [{ label: "Upcoming Secondary", header: true }]
      : []),
    ...groupByType.secondary,
    { label: "Past Order Windows", header: true },
    ...past,
  ];
};

const orderTypes = [
  { id: "all", label: "All" },
  { id: "pre-order", label: "Quarterly Window" },
  { id: "on-demand", label: "On Demand" },
  { id: "to-inventory", label: "Restock" },
  { id: "from-inventory", label: "Inventory" },
  { id: "ad-hoc", label: "Ad Hoc" },
];

const OrderTypeSelector = ({ handleOrderType, orderType, setType }) => {
  const filterType = "history";

  const currentType = useSelector((state) => state.filters.type);
  const isGlobalLoading = useSelector((state) => state.globalLoad.isLoading);

  const { data: allOcms = [] } = useAllOcmsQuery();
  const ocmOptions = splitOrderWindowOptions(allOcms);

  const handleChangeSelect = (evt) => {
    setType(evt.target.value);
    handleOrderType(evt.target.value, "type", filterType);
  };

  useEffect(() => {
    if (orderType !== currentType) {
      setType(currentType);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [orderType, currentType]);

  return (
    <>
      <FormControl
        fullWidth
        size="small"
        variant="outlined"
        disabled={isGlobalLoading}
      >
        <InputLabel id="orderType-select">
          {filterType === "rollup" ? "Order Window Type" : "Order Type"}
        </InputLabel>
        <Select
          variant="outlined"
          label={filterType === "rollup" ? "Order Window Type" : "Order Type"}
          name="orderType"
          labelId="orderType-select"
          id="orderType"
          value={orderType || ""}
          onChange={handleChangeSelect}
          MenuProps={{
            anchorOrigin: {
              vertical: "bottom",
              horizontal: "left",
            },
            transformOrigin: {
              vertical: "top",
              horizontal: "left",
            },
          }}
        >
          {[
            ...orderTypes.map((orderType, index) => (
              <MenuItem value={orderType.id} key={orderType.id + index}>
                <Typography variant="body2">{orderType.label}</Typography>
              </MenuItem>
            )),
            ...ocmOptions.map((ow, i) =>
              ow.header ? (
                <ListSubheader key={i}>{ow.label}</ListSubheader>
              ) : (
                <MenuItem key={ow.id} value={ow.id}>
                  <Typography variant="body2">{ow.orderWindowName}</Typography>
                </MenuItem>
              )
            ),
          ]}
        </Select>
      </FormControl>
    </>
  );
};

OrderTypeSelector.propTypes = {
  handleOrderType: PropTypes.func.isRequired,
  orderType: PropTypes.string,
  setType: PropTypes.func.isRequired,
};

export default OrderTypeSelector;
