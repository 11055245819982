import { cloudinary as cld } from "./cloudinary";

export { default as CldImage } from "./CldImage";
export { default as CldVideo } from "./CldVideo";
export { default as imageExtensions } from "./imageExtensions";
export { getResourceUrl } from "./getResourceUrl";
export { uploadToCloudinary } from "./uploadToCloudinary";
export { default as imageSizeOptions } from "./imageSizeOptions";

export default cld;
