import { DateBuilderReturnType } from "@mui/x-date-pickers";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";

const getTimezoneOffset = (value: Date): number =>
  value.getTimezoneOffset() * 60000;

export const toUTC = (date: Date): Date => {
  const utcFromLocal = new Date(date.getTime() + getTimezoneOffset(date));
  return utcFromLocal;
};

class AdapterUTCDateFns extends AdapterDateFns {
  constructor(...args: any[]) {
    super(...args);
    // https://github.com/mui/material-ui-pickers/issues/329
    // https://github.com/dmtrKovalenko/date-io/blob/master/packages/date-fns/src/date-fns-utils.ts#L280
    this.date = <T extends string | null | undefined>(
      value?: T
    ): DateBuilderReturnType<T, Date> => {
      if (typeof value === "undefined") {
        return new Date() as DateBuilderReturnType<T, Date>;
      }

      if (value === null) {
        return null as DateBuilderReturnType<T, Date>;
      }
      if (typeof value === "string" && value.length === 10) {
        // MM/dd/yyyy
        return toUTC(new Date(value)) as DateBuilderReturnType<T, Date>;
      } else {
        return new Date(value) as DateBuilderReturnType<T, Date>;
      }
    };
  }
}
export default AdapterUTCDateFns;
