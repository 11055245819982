/** @jsxImportSource @emotion/react */
import "twin.macro";

import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";

import { Close } from "@mui/icons-material";
import { DialogActions } from "@mui/material";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import IconButton from "@mui/material/IconButton";
import Typography from "@mui/material/Typography";

import { clearError } from "src/redux/slices/errorSlice";

const ErrorModal = () => {
  const dispatch = useDispatch();

  const error = useSelector((state) => state.error.currentError);
  const source = useSelector((state) => state.error.errorSource);

  const handleErrorClose = () => {
    dispatch(clearError());
  };

  return (
    <Dialog open={!!error} onClose={handleErrorClose} fullWidth maxWidth="sm">
      <DialogTitle tw="flex justify-between items-center">
        The following error has occured:
        <IconButton edge="end" onClick={handleErrorClose}>
          <Close />
        </IconButton>
      </DialogTitle>
      <DialogContent>
        <div tw="space-y-4 max-w-prose">
          <Typography tw="text-base text-neutral-600 whitespace-pre-line">
            {error}
          </Typography>
          <Typography tw="text-base text-neutral-500">{`Source: ${source}`}</Typography>
        </div>
      </DialogContent>
      <DialogActions>
        <Button
          component={Link}
          onClick={handleErrorClose}
          to="/"
          color="secondary"
          variant="contained"
          tw="px-6 py-2 text-sm font-medium"
        >
          GO BACK HOME
        </Button>
        <Button
          onClick={handleErrorClose}
          variant="contained"
          tw="px-6 py-2 text-sm font-medium ml-3"
        >
          DISMISS ERROR
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default ErrorModal;
