/** @jsxImportSource @emotion/react */
import "twin.macro";

import { Program } from "@models/Program";

import makeResourceAutocompleteFilter from "../components/makeResourceAutocompleteFilter";

const filterProgramNameOrId = (options: Program[], { inputValue }) => {
  return options.filter(
    (o) =>
      o.id === inputValue ||
      o.name.toLowerCase().includes(inputValue.toLowerCase()) ||
      o.anaplanProgramCodes.includes(inputValue) // exact match required
  );
};

const filterPrograms = makeResourceAutocompleteFilter({
  name: "programs",
  resource: "programs",
  titleSingular: "Program",
  titlePlural: "Programs",
  resourceFilterName: "nameOrId",
  requestParams: { sort: "name" },
  filterOptions: filterProgramNameOrId,
});

export default filterPrograms;
