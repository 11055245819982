/** @jsxImportSource @emotion/react */
import "twin.macro";

import { format } from "date-fns";
import _, { capitalize, uniq } from "lodash";
import { formatMoneyString } from "src/utility/utilityFunctions";

import { getExtendedCost, orderTypeLabel } from "@features/orders";
import { Order } from "@models/Order";
import { CsvSchema } from "@utils/csv";

const orderHistoryOrderReportSchema: CsvSchema<Order> = [
  {
    id: "id",
    label: "Order #",
  },
  {
    id: "_anaplanSubCodes",
    label: "12 Digit Code(s)",
    f: (_v, order) =>
      _(order.orderVariants)
        .flatMap((v) => v.anaplanSubProgramCodes)
        .uniq()
        .join(", "),
  },
  {
    id: "_typeOrWindow",
    label: "Order Type / Window",
    f: (_, o) =>
      o.type === "pre-order"
        ? o.orderWindowName ?? orderTypeLabel(o.type)
        : orderTypeLabel(o.type),
  },
  { id: "address.name", label: "Address Name" },
  { id: "address.abn", label: "ABN" },
  { id: "address.state.code", label: "State" },

  { id: "territoryName", label: "Territory" },
  { id: "programNames", label: "Program" },
  {
    id: "_brands",
    label: "Brand",
    f: (_, order) =>
      uniq(
        order.orderVariants.flatMap((v) =>
          v.variant.item.brands.map((b) => b.name)
        )
      ).join(", "),
  },
  {
    id: "_brandBus",
    label: "BU",
    f: (_, order) =>
      uniq(
        order.orderVariants.flatMap((v) =>
          v.variant.item.brands.map((b) => b.businessUnit.name)
        )
      ).join(", "),
  },
  {
    id: "submittedAt",
    label: "Order Date",
    f: (date) => date && format(new Date(date), "MM/dd/yyyy"),
  },
  { id: "totalQuantity", label: "Total Items" },
  {
    id: "_totalActualCost",
    label: "Total Product Cost",
    f: (_, order) =>
      formatMoneyString(
        order.type === "from-inventory"
          ? +order.billableTotalActualCost || +order.billableTotalEstimatedCost
          : +order.totalActualCost || +order.totalEstimatedCost
      ),
  },
  {
    id: "_totalBeaconCost",
    label: "Total Beacon Cost",
    f: (_, order) =>
      formatMoneyString(
        order.type === "from-inventory"
          ? order.billableTotalBeaconCost
          : order.totalBeaconCost
      ),
  },
  {
    id: "_totalEstimatedShippingCost",
    label: "Est. Freight",
    f: (_, order) =>
      formatMoneyString(
        order.type === "from-inventory"
          ? order.billableTotalEstimatedShippingCost
          : order.totalEstimatedShippingCost
      ),
  },
  {
    id: "_totalEstimatedTax",
    label: "Est. Tax",
    f: (_, order) =>
      formatMoneyString(
        order.type === "from-inventory"
          ? order.billableTotalEstimatedTax
          : order.totalEstimatedTax
      ),
  },
  {
    id: "_totalExtendedEstCost",
    label: "Est. Total Cost",
    f: (_v, order) => formatMoneyString(getExtendedCost(order, order.type)),
  },
  {
    id: "status",
    label: "Status",
    f: (status) => capitalize(status === "submitted" ? "pending" : status),
  },
];

export default orderHistoryOrderReportSchema;
