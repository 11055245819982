import { useMemo } from "react";

import { UseQueryResult, useQuery } from "@tanstack/react-query";
import _ from "lodash";

import { Order } from "@models/Order";
import { OrderSet } from "@models/OrderSet";
import { OrderSetVariant } from "@models/OrderSetVariant";

import { orderSetsKeyFactory } from "../../queries/orderSetQueries";

export type ExtendedOrderSetVariant = OrderSetVariant & {
  totalQty?: number;
  totalEstimatedCost?: number;
  billableTotalEstimatedCost?: number;
  variablePricingMinRangeValue?: null | number;
  variablePricingMaxRangeValue?: null | number;
};
// insures both orderVariants and orderSetVariants are sorted by the same key
const variantSortKey = "variant.id";

// Declare the combine function outside of the hook
// so that it's reference is stable and doesn't cause re-renders
function combineOrderSetAndOrdersQuery(
  orderSetQuery: UseQueryResult<OrderSet, Error>,
  ordersQuery: UseQueryResult<Order[], Error>
) {
  if (orderSetQuery.isPending || ordersQuery.isPending) {
    return { isPending: true as const };
  }

  if (orderSetQuery.isLoading || ordersQuery.isLoading) {
    return { isLoading: true as const };
  }

  if (orderSetQuery.error || ordersQuery.error) {
    return { error: (orderSetQuery.error || ordersQuery.error) as Error };
  }

  const orders = ordersQuery.data!;
  const orderSet = orderSetQuery.data!;
  const sortedOrdersData = _(orders)
    .map((order) => ({
      ...order,
      orderVariants: _.sortBy(order.orderVariants, variantSortKey),
    }))
    .sortBy("address.state.id", "address.id", "territoryName", "subStateName")
    .value();

  const lookup = _(orders)
    .flatMap((o) => o.orderVariants)
    .groupBy("variant.id")
    .mapValues((v) =>
      v.reduce(
        (a, b) => {
          a.estimatedCost = b.estimatedCost;
          a.qty += b.qty;
          a.totalEstimatedCost += +b.totalEstimatedCost;
          a.billableTotalEstimatedCost += +b.billableTotalEstimatedCost;

          b.variablePricingMinRangeValue &&
            (a.variablePricingMinRangeValue = b.variablePricingMinRangeValue);
          b.variablePricingMaxRangeValue &&
            (a.variablePricingMaxRangeValue = b.variablePricingMaxRangeValue);
          return a;
        },
        {
          estimatedCost: "0",
          totalEstimatedCost: 0,
          billableTotalEstimatedCost: 0,
          qty: 0,
          variablePricingMinRangeValue: null as null | number,
          variablePricingMaxRangeValue: null as null | number,
        }
      )
    )
    .value();

  const orderSetVariants: ExtendedOrderSetVariant[] = _(
    orderSet.orderSetVariants
  )
    .sortBy(variantSortKey)
    .map((osv, i) => ({
      ...osv,
      totalQty: sortedOrdersData.reduce(
        (totalQty, order) => totalQty + (order.orderVariants[i]?.qty || 0),
        0
      ),
      estimatedCost: lookup[osv.variant.id]?.estimatedCost ?? osv.estimatedCost,
      variablePricingMinRangeValue:
        lookup[osv.variant.id]?.variablePricingMinRangeValue,
      variablePricingMaxRangeValue:
        lookup[osv.variant.id]?.variablePricingMaxRangeValue,
      totalEstimatedCost: lookup[osv.variant.id]?.totalEstimatedCost,
      billableTotalEstimatedCost:
        lookup[osv.variant.id]?.billableTotalEstimatedCost,
    }))
    .value();

  return {
    orderSet: orderSet,
    orders: sortedOrdersData,
    orderSetVariants,
    orderSetQuery,
    ordersQuery,
  };
}

export const useOrderSet = (orderSetId?: string | null) => {
  const orderSetQuery = useQuery({
    ...orderSetsKeyFactory.detail(orderSetId),
    enabled: !!orderSetId,
  });
  const ordersQuery = useQuery({
    ...orderSetsKeyFactory.detail(orderSetId)._ctx.orders,
    enabled: !!orderSetId,
  });
  return useMemo(
    () => combineOrderSetAndOrdersQuery(orderSetQuery, ordersQuery),
    [orderSetQuery, ordersQuery]
  );
};
