import { useQuery } from "@tanstack/react-query";

import client from "@services/api";

export default function useImperialPunchoutUrlQuery(programId?: string | null) {
  return useQuery({
    queryKey: ["imperial-coupon-url", programId],
    queryFn: () =>
      client
        .post(
          "/imperial/initiate-punchout",
          {
            post_checkout_path: "/",
            program_id: programId,
          },
          {
            serializeBody: false,
            deserializeOutput: false,
            headers: {
              "Content-Type": "application/vnd.api+json",
            },
          }
        )
        .then((res) => res.punch_out_start_page_url),
    enabled: !!programId,
    staleTime: Infinity,
  });
}
