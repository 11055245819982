/** @jsxImportSource @emotion/react */
import "twin.macro";

import { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";

import { TemplatedCouponModal } from "@features/imperialCoupons";

import { fetchItemPrograms } from "../../../redux/slices/planningTool/itemProgramSlice";
import { StyledButton } from "../../StyledComponents";

const TemplatedCouponNew = () => {
  const dispatch = useDispatch();
  const { isLoading } = useSelector((state) => state.itemPrograms);
  const { pid } = useParams();
  const [modalOpen, setModalOpen] = useState(false);

  const handleCloseAndRefresh = () => {
    setModalOpen(false);
    dispatch(fetchItemPrograms(pid));
  };

  return (
    <>
      <div tw="flex justify-between items-baseline mb-4 mt-8">
        <span tw="text-xl text-slate-700">Templated Coupons</span>

        <StyledButton
          cta
          loading={isLoading}
          onClick={() => setModalOpen(true)}
        >
          Create Templated Coupon
        </StyledButton>
        {modalOpen && (
          <TemplatedCouponModal
            handleClose={handleCloseAndRefresh}
            programId={pid}
          />
        )}
      </div>
    </>
  );
};

export default TemplatedCouponNew;
