/** @jsxImportSource @emotion/react */
import "twin.macro";

import { format } from "date-fns";
import _, { capitalize } from "lodash";
import {
  formatDateString,
  formatMoneyString,
  utcDate,
} from "src/utility/utilityFunctions";

import { OrderVariant } from "@models/OrderVariant";
import { CsvSchema } from "@utils/csv";

import {
  getExtendedCost,
  getVariantName,
  handleComplianceStatus,
  orderTypeLabel,
} from "../helpers";

const monthFromDateString = (dateString) =>
  dateString && format(utcDate(dateString), "MMMM");

const orderHistoryVariantReportSchema = (role): CsvSchema<OrderVariant> => [
  { id: "variant.variantSku", label: "Item #" },
  { id: "variant.item.comment", label: "Description" },
  { id: "variant", label: "Variant", f: (variant) => getVariantName(variant) },
  {
    id: "_typeOrWindow",
    label: "Order Type / Window",
    f: (__, ov) =>
      ov.orderType === "pre-order"
        ? ov.orderWindowName ?? orderTypeLabel(ov.orderType)
        : orderTypeLabel(ov.orderType),
  },
  {
    id: "order.id",
    label: "Order #",
  },
  {
    id: "anaplanSubProgramCodes",
    label: "12 Digit Code(s)",
    f: (codes) => codes.join(", "),
  },
  {
    id: "anaplanProgramTypes",
    label: "Anaplan Program Types",
    f: (types) => types.join(", "),
  },
  ...(([
    "super",
    "purchaser",
    "select-purchaser",
    "read-only",
    "compliance",
  ].includes(role)
    ? [{ id: "poId", label: "Purchase Order #" }]
    : []) as CsvSchema<OrderVariant>),
  { id: "orderUserName", label: "Ordered By" },
  { id: "territoryName", label: "Territory" },
  {
    id: "variant.item.brands",
    label: "Brand",
    f: (brands) => brands.map((b) => b.name).join(", "),
  },
  {
    id: "_businessUnitNames",
    label: "BU",
    f: (__, ov) =>
      _(ov.variant.item.brands).map("businessUnit.name").join(", "),
  },
  { id: "programNames", label: "Program" },
  {
    id: "itemTypeDescription",
    label: "Item Type",
  },
  {
    id: "addressName",
    label: "Distributor / Name",
  },
  { id: "addressAbn", label: "ABN" },
  { id: "streetAddress1", label: "Street Address 1" },
  { id: "streetAddress2", label: "Street Address 2" },
  { id: "city", label: "City" },
  { id: "state", label: "State" },
  { id: "zip", label: "Zip" },
  { id: "qty", label: "Qty" },
  {
    id: "estimatedCost",
    label: "Est. Cost/Unit",
    f: (v) => formatMoneyString(v),
  },
  {
    id: "_perUnitActualCost",
    label: "Actual Cost/Unit",
    f: (_, ov) => formatMoneyString(+ov.totalActualCost / (ov.qty || 1)),
  },
  {
    id: "_totalActualCost",
    label: "Total Product Cost",
    f: (_, ov) =>
      formatMoneyString(
        ov.orderType === "from-inventory"
          ? +ov.billableTotalActualCost || +ov.billableTotalEstimatedCost
          : +ov.totalActualCost || +ov.totalEstimatedCost
      ),
  },
  {
    id: "_totalEstimatedShippingCost",
    label: "Est. Freight",
    f: (_, ov) =>
      formatMoneyString(
        ov.orderType === "from-inventory"
          ? ov.billableTotalEstimatedShippingCost
          : ov.totalEstimatedShippingCost
      ),
  },
  {
    id: "_totalEstimatedTax",
    label: "Est. Tax",
    f: (_, ov) =>
      formatMoneyString(
        ov.orderType === "from-inventory"
          ? ov.billableTotalEstimatedTax
          : ov.totalEstimatedTax
      ),
  },
  {
    id: "_totalBeaconCost",
    label: "Total Beacon Cost",
    f: (_, ov) =>
      formatMoneyString(
        ov.orderType === "from-inventory"
          ? ov.billableTotalBeaconCost
          : ov.totalBeaconCost
      ),
  },
  {
    id: "_extendedTotal",
    label: "Est. Total Cost",
    f: (_, ov) => formatMoneyString(getExtendedCost(ov, ov.orderType)),
  },
  {
    id: "orderSubmittedAt",
    label: "Order Date",
    f: (date) => format(new Date(date), "MM/dd/yyyy"),
  },
  {
    id: "_inMarketMonth",
    label: "In Market Month",
    f: (_, ov) =>
      monthFromDateString(ov.inMarketDate) ??
      monthFromDateString(ov.poInMarketDate),
  },
  {
    id: "_shipDateOrInMarketDate",
    label: "In Market Date",
    f: (_, ov) => formatDateString(ov.poInMarketDate ?? ov.inMarketDate),
  },
  {
    id: "_actualShipDate",
    label: "Act. Ship Date",
    f: (_, ov) =>
      formatDateString(
        ov.shippingParameterVariant?.actualShipDate ?? ov.actualShipDate
      ),
  },
  {
    id: "trackingData.carrier",
    label: "Carrier",
  },
  {
    id: "trackingData.number",
    label: "Tracking",
    f: (tracking, ov) =>
      ov.isTransferred ? "Transferred qty's from other territories" : tracking,
  },
  {
    id: "status",
    label: "Status",
  },
  {
    id: "_compliance",
    label: "Compliance Status",
    f: (_, ov) => {
      const rules = [...ov.triggeredRules, ...ov.priorApprovalTriggeredRules];
      const complianceStatus = handleComplianceStatus(rules);
      const ruleTypes =
        complianceStatus.includes("Overridden") && rules.length > 0
          ? `(${rules
              .map((ruleObj) => capitalize(ruleObj.ruleType))
              .join(", ")}) `
          : "";
      return ruleTypes + complianceStatus;
    },
  },
  {
    id: "includeBeacon",
    label: "Beacon",
    f: (includeBeacon) => (includeBeacon ? "Yes" : "No"),
  },
  { id: "complianceLogging", label: "Approval Logs" },
];

export default orderHistoryVariantReportSchema;
