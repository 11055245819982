import { Order, OrderSet, OrderSetStatus, OrderType, Variant } from "@models";
import { StrippedTriggeredRule } from "@models/OrderVariant";

// fields shared by Order and OrderVariant
export type OrderCosts = Pick<
  Order,
  | "totalActualCost"
  | "totalActualShippingCost"
  | "totalBeaconCost"
  | "totalEstimatedCost"
  | "totalEstimatedShippingCost"
  | "totalEstimatedTax"
  | "billableTotalActualCost"
  | "billableTotalActualShippingCost"
  | "billableTotalBeaconCost"
  | "billableTotalEstimatedCost"
  | "billableTotalEstimatedShippingCost"
  | "billableTotalEstimatedTax"
>;

// Insures a number is returned
const n = (v: unknown) => (v ? +v : 0);

export const getExtendedCost = (e: OrderCosts, orderType: OrderType) => {
  if (orderType !== "from-inventory") {
    const shipping = n(
      +e.totalActualShippingCost || e.totalEstimatedShippingCost
    );
    const tax = n(e.totalEstimatedTax);
    const cost = +e.totalActualCost || +e.totalEstimatedCost;

    return cost + shipping + tax + +e.totalBeaconCost;
  }
  const shipping = n(
    +e.billableTotalActualShippingCost || e.billableTotalEstimatedShippingCost
  );
  const tax = n(e.billableTotalEstimatedTax);
  const cost = +e.billableTotalActualCost || +e.billableTotalEstimatedCost;

  return cost + shipping + tax + +e.billableTotalBeaconCost;
};

export const getVariantName = (variant: Variant) =>
  variant.selectedVariantOptions.map((option) => option.name).join(" / ");

export const displayOrderSetStatus = (
  status: OrderSetStatus,
  orderSet?: OrderSet
) => {
  if (orderSet?.isWorkComplete && status === "in-progress")
    return "Work Complete";

  const t: Record<OrderSetStatus, string> = {
    inactive: "Not Started",
    "in-progress": "In Progress",
    submitted: "Submitted",
    approved: "Approved",
    canceled: "Canceled",
    "partial-approval-error": "Partial Approval Error",
  };
  return t[status];
};

export const displayToInventoryAllocation = (order: Order) =>
  order.type === "to-inventory"
    ? order.subState
      ? order.subState.name
      : order.territory
      ? order.territoryName
      : "National"
    : null;

export const orderTypeLabel = (orderType: OrderType) => {
  const orderTypeMap = {
    "pre-order": "Pre-Order",
    "from-inventory": "Inventory",
    "on-demand": "On-Demand",
    "to-inventory": "Restock",
  };
  return orderTypeMap[orderType] ?? orderType;
};

export const handleComplianceStatus = (rules: StrippedTriggeredRule[]) => {
  if (rules.some((r) => r.status === "denied")) {
    return "Denied";
  }
  if (rules.some((r) => r.status !== "approved")) {
    return "Pending";
  }
  return "Approved";
};
