import { useEffect, useState } from "react";
import { useDispatch } from "react-redux";

import { useAuth0 } from "@auth0/auth0-react";
import axios from "axios";
import { LS_AUTH_TOKEN } from "src/constants/localStorage";
import { setError } from "src/redux/slices/errorSlice";

import client from "@services/api";

import handleSessionExpired from "./handleSessionExpired";

type AuthStatus = "unset" | "loading" | "ready" | "error";

type AuthPayload = {
  access_token: string;
  expires_at: number;
};

function getTokenFromLocalStorage(): AuthPayload | null {
  const tokenString = window.localStorage.getItem(LS_AUTH_TOKEN);
  if (!tokenString) return null;
  const token = JSON.parse(tokenString) as AuthPayload;
  if (token.expires_at * 1000 <= Date.now()) return null;
  return token;
}

function saveTokenToLocalStorage(accessToken: string, expiresAt: number): void {
  const payload: AuthPayload = {
    access_token: accessToken,
    expires_at: expiresAt,
  };
  window.localStorage.setItem(LS_AUTH_TOKEN, JSON.stringify(payload));
}

// Helper function to set auth token in headers
function setAuthHeaders(token: string) {
  const authToken = `Bearer ${token}`;
  axios.defaults.headers.common["Authorization"] = authToken;
  client.headers["Authorization"] = authToken;
}

const useAuthToken = () => {
  const dispatch = useDispatch();
  const [authStatus, setAuthStatus] = useState<AuthStatus>("unset");
  const {
    isAuthenticated,
    getAccessTokenSilently,
    getIdTokenClaims,
    isLoading,
  } = useAuth0();

  useEffect(() => {
    let timeout: NodeJS.Timeout | undefined;
    const setExpTimeout = (expiresAt: number) => {
      const timeToExpire = expiresAt * 1000 - Date.now();
      return setTimeout(handleSessionExpired, timeToExpire);
    };

    async function setTokens() {
      try {
        // First check if we have a valid token in localStorage
        const storedToken = getTokenFromLocalStorage();
        if (storedToken) {
          setAuthHeaders(storedToken.access_token);
          setAuthStatus("ready");
          timeout = setExpTimeout(storedToken.expires_at);
          return;
        }

        // If no valid token in localStorage, get a new one
        const jwt = await getAccessTokenSilently();
        const claims = await getIdTokenClaims();

        const { data } = await axios.post(`/auth/token`, {
          auth0_token: jwt,
        });

        setAuthHeaders(data.access_token);

        // Save the token to localStorage
        if (claims?.exp) {
          saveTokenToLocalStorage(data.access_token, claims.exp);
          timeout = setExpTimeout(claims.exp);
        }

        setAuthStatus("ready");
      } catch (error: any) {
        setAuthStatus("error");
        dispatch(setError({ error: error.toString(), source: "useAuthToken" }));
        console.error(error);
      }
    }
    if (isLoading) {
      setAuthStatus("loading");
    } else if (isAuthenticated) {
      setAuthStatus("loading");
      setTokens();
    } else {
      // Clear auth headers when not authenticated
      delete axios.defaults.headers.common["Authorization"];
      delete client.headers["Authorization"];
      setAuthStatus("unset");
    }
    return () => timeout && clearTimeout(timeout);
  }, [
    isLoading,
    isAuthenticated,
    getAccessTokenSilently,
    getIdTokenClaims,
    dispatch,
  ]);

  return authStatus;
};

export default useAuthToken;
