import { useEffect } from "react";
import { useDispatch } from "react-redux";

import { useAuth0 } from "@auth0/auth0-react";
import axios from "axios";
import { LS_AUTH_TOKEN } from "src/constants/localStorage";

import client from "@services/api";

const Logout = () => {
  const dispatch = useDispatch();
  const queryParams = new URLSearchParams(window.location.search);
  const returnTo = queryParams.get("returnTo");
  const { logout } = useAuth0();

  useEffect(() => {
    // Clear auth headers
    delete axios.defaults.headers.common["Authorization"];
    delete client.headers["Authorization"];

    // Clear cached token from localStorage
    window.localStorage.removeItem(LS_AUTH_TOKEN);

    let url = returnTo
      ? `${window.location.origin}?returnTo=${returnTo}`
      : `${window.location.origin}`;
    logout({ logoutParams: { returnTo: url } });
  }, [dispatch, returnTo, logout]);
  return <h1>Logging out...</h1>;
};

export default Logout;
