/** @jsxImportSource @emotion/react */
import "twin.macro";

import React, { useCallback, useEffect, useRef } from "react";
import { useBottomScrollListener } from "react-bottom-scroll-listener";
import { useDispatch, useSelector } from "react-redux";

import GetAppIcon from "@mui/icons-material/GetApp";
import Button from "@mui/material/Button";
import CircularProgress from "@mui/material/CircularProgress";
import Divider from "@mui/material/Divider";
import TextField from "@mui/material/TextField";
import Typography from "@mui/material/Typography";
import makeStyles from "@mui/styles/makeStyles";

import { Contained } from "@components/StyledComponents";
import { PageTitle } from "@features/ui";
import { CSVLink } from "@utils/csv";

import { useInput } from "../../hooks/InputHooks";
import {
  clearAllAddressList,
  fetchAddressReportByType,
  fetchAddresses,
  fetchAnyNextAddresses,
  setTriggerCSVFalse,
} from "../../redux/slices/addresses/addressSlice";
import AddressAttnTable from "./AddressAttnTable";
import FavoriteAddresses from "./FavoriteAddresses";

const csvHeaders = [
  { label: "Name", key: "name" },
  { label: "Phone Number", key: "phoneNumber" },
  { label: "Street Address One", key: "streetAddressOne" },
  { label: "Street Address Two", key: "streetAddressTwo" },
  { label: "City", key: "city" },
  { label: "State", key: "stateCode" },
  { label: "Zip", key: "zip" },
  { label: "Active", key: "isActive" },
];

const distHeaders = [{ label: "ABN", key: "distributorAbn" }, ...csvHeaders];

const addHeaders = [{ label: "ID", key: "addDisplayId" }, ...csvHeaders];

const useStyles = makeStyles((theme) => ({
  ...theme.global,
  inputField: {
    marginBottom: "15px",
    width: "48%",
    [theme.breakpoints.down("md")]: {
      width: "100%",
    },
  },
}));

const AddressOptions = () => {
  const classes = useStyles();
  const dispatch = useDispatch();

  const { value: search, bind: bindSearch } = useInput("");

  const territoryId = useSelector((state) => state.user.currentTerritory);
  const userStates = useSelector((state) => state.user.states);
  const currentUserRole = useSelector((state) => state.user.role);
  const {
    isAllAddressesLoading,
    isAttnLoading,
    allAddressList,
    editAttnList,
    triggerCustomCSVDownload,
    triggerDistributorCSVDownload,
    isNextLoading,
    nextLink,
  } = useSelector((state) => state.addresses);

  const handleBottomScroll = () => {
    if (nextLink && !isNextLoading) {
      if (scrollRef.current && scrollRef.current.scrollTop !== 0) {
        dispatch(fetchAnyNextAddresses(nextLink, true));
      }
    }
  };

  const scrollRef = useBottomScrollListener(handleBottomScroll, {
    offset: 500,
    debounceOptions: {
      leading: true,
      trailing: false,
    },
  });

  const debounce = useRef(null);

  const handleQuery = useCallback(() => {
    clearTimeout(debounce.current);

    debounce.current = setTimeout(() => {
      currentUserRole !== "super" &&
      currentUserRole !== "purchaser" &&
      currentUserRole !== "select-purchaser"
        ? dispatch(
            fetchAddresses(
              search,
              territoryId,
              userStates.map((state) => state.id).join(","),
              true,
              null
            )
          )
        : dispatch(fetchAddresses(search, territoryId, false, true, null));
    }, 250);
  }, [search, territoryId, userStates, currentUserRole, dispatch]);

  const handlefetchAllCustomAddresses = () => {
    dispatch(setTriggerCSVFalse());
    dispatch(clearAllAddressList());
    dispatch(fetchAddressReportByType("custom"));
  };

  const handleFetchAllDistributorAddresses = () => {
    dispatch(setTriggerCSVFalse());
    dispatch(clearAllAddressList());
    dispatch(fetchAddressReportByType("distributor"));
  };

  useEffect(() => {
    if (search.length >= 1) {
      handleQuery();
    }
  }, [search, handleQuery, dispatch]);

  useEffect(() => {
    dispatch(clearAllAddressList());
    dispatch(setTriggerCSVFalse());
    currentUserRole !== "super" &&
    currentUserRole !== "purchaser" &&
    currentUserRole !== "select-purchaser"
      ? dispatch(
          fetchAddresses(
            null,
            territoryId,
            userStates.map((state) => state.id).join(","),
            true,
            null
          )
        )
      : territoryId
      ? dispatch(fetchAddresses(null, territoryId, false, true, null))
      : dispatch(fetchAddresses(null, false, false, true, null));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Contained tw="space-y-6 mb-8">
      <div tw="flex justify-between items-center">
        <PageTitle title="Edit Attention Lines" />
        <div tw="flex items-center justify-end gap-4">
          {(!triggerCustomCSVDownload || triggerDistributorCSVDownload) && (
            <Button variant="contained" onClick={handlefetchAllCustomAddresses}>
              {isAllAddressesLoading ? (
                <CircularProgress size={28} />
              ) : (
                "GENERATE ADDRESS REPORT"
              )}
            </Button>
          )}
          {triggerCustomCSVDownload && allAddressList.length > 0 && (
            <CSVLink
              data={allAddressList}
              headers={addHeaders}
              filename="rta_addresses.csv"
            >
              <Button
                variant="contained"
                color="secondary"
                startIcon={<GetAppIcon />}
              >
                EXPORT ADDRESS REPORT
              </Button>
            </CSVLink>
          )}
          {(!triggerDistributorCSVDownload || triggerCustomCSVDownload) && (
            <Button
              variant="contained"
              onClick={handleFetchAllDistributorAddresses}
              style={{ minWidth: "293px" }}
            >
              {isAllAddressesLoading ? (
                <CircularProgress size={28} />
              ) : (
                "GENERATE DISTRIBUTOR REPORT"
              )}
            </Button>
          )}
          {triggerDistributorCSVDownload && allAddressList.length > 0 && (
            <CSVLink
              data={allAddressList}
              headers={distHeaders}
              filename="rta_distributors.csv"
            >
              <Button
                variant="contained"
                color="secondary"
                startIcon={<GetAppIcon />}
              >
                EXPORT DISTRIBUTOR REPORT
              </Button>
            </CSVLink>
          )}
        </div>
      </div>
      <TextField
        size="small"
        className={classes.inputField}
        label="Search Addresses"
        variant="outlined"
        value={search}
        {...bindSearch}
      />
      <AddressAttnTable
        addresses={editAttnList}
        isLoading={isAttnLoading}
        scrollRef={scrollRef}
      />

      <Divider />

      <Typography className={classes.titleText}>
        Favorite Distributors / Addresses
      </Typography>
      <div tw="space-y-2">{territoryId && <FavoriteAddresses />}</div>
      {!territoryId && (
        <>
          <Typography className={classes.headerText} tw="max-w-prose">
            Creation of favorite distributor / address lists is unavailable in
            while working in All Regions, please select a different Territory to
            create a new list.
          </Typography>
        </>
      )}
    </Contained>
  );
};

export default AddressOptions;
