/** @jsxImportSource @emotion/react */
import "twin.macro";

import { Warning } from "@mui/icons-material";

import { useQueryClient } from "@tanstack/react-query";
import { format } from "date-fns";
import _, { capitalize, uniq } from "lodash";
import { formatMoneyString } from "src/utility/utilityFunctions";

import { TableLink, showFirst } from "@components/SmartTable";
import SmartTable, {
  PaginatedResourceProps,
} from "@components/SmartTable/SmartTable";
import {
  getExtendedCost,
  orderTypeLabel,
  ordersKeyFactory,
} from "@features/orders";
import { PaperTooltip } from "@features/ui";
import { Order } from "@models";

import {
  BillableBeaconCost,
  BillableItemCost,
  BillableShippingCost,
  BillableTaxCost,
} from "../BillableCosts";

type OrderHistoryOrderTableProps = PaginatedResourceProps & {
  data: Order[];
};

// Incomplete remake of order history table
const OrderHistoryOrderTable = ({
  data,
  ...tableProps
}: OrderHistoryOrderTableProps) => {
  const queryClient = useQueryClient();
  return (
    <SmartTable
      columns={[
        {
          id: "id",
          label: "Order #",
          sort: "id",
          render: (id, order) => (
            <div tw="flex gap-2">
              {order.orderVariants.some((ov) =>
                ["canceled", "destroyed"].includes(ov.status)
              ) && (
                <PaperTooltip
                  placement="top-start"
                  title="Has Order Items that have been canceled or destroyed"
                >
                  <Warning fontSize="small" />
                </PaperTooltip>
              )}
              <TableLink
                to={`/orders/history/single-order/${id}`}
                onClick={() =>
                  queryClient.setQueryData<Order>(
                    ordersKeyFactory.detail(id).queryKey,
                    order
                  )
                }
              >
                {id}
              </TableLink>
            </div>
          ),
        },
        {
          id: "_anaplanSubCodes",
          label: "12 Digit Code(s)",
          render: (_v, order) =>
            _(order.orderVariants)
              .flatMap((v) => v.anaplanSubProgramCodes)
              .uniq()
              .join(", "),
        },
        {
          id: "_typeOrWindow",
          label: "Order Type / Window",
          render: (_, o) =>
            o.type === "pre-order"
              ? o.orderWindowName ?? orderTypeLabel(o.type)
              : orderTypeLabel(o.type),
        },
        { id: "address.name", label: "Address Name", sort: "address-name" },
        { id: "address.abn", label: "Address ID" },
        { id: "address.state.code", label: "Address ID" },

        { id: "territoryName", label: "Territory" },
        { id: "programNames", label: "Program", render: showFirst },
        {
          id: "_brands",
          label: "Brand",
          sort: "brand",
          render: (_, order) =>
            uniq(
              order.orderVariants.flatMap((v) =>
                v.variant.item.brands.map((b) => b.name)
              )
            ).join(", "),
        },
        {
          id: "_brandBus",
          label: "BU",
          render: (_, order) =>
            uniq(
              order.orderVariants.flatMap((v) =>
                v.variant.item.brands.map((b) => b.businessUnit.name)
              )
            ).join(", "),
        },
        {
          id: "submittedAt",
          label: "Order Date",
          sort: "submitted-at",
          render: (date) => date && format(new Date(date), "MM/dd/yyyy"),
        },
        { id: "totalQuantity", label: "Total Items" },
        {
          id: "totalActualCost",
          label: "Total Product Cost",
          align: "right",
          render: (_, order) => (
            <BillableItemCost costs={order} orderType={order.type} />
          ),
        },
        {
          id: "totalBeaconCost",
          label: "Total Beacon Cost",
          align: "right",
          render: (_, order) => (
            <BillableBeaconCost costs={order} orderType={order.type} />
          ),
        },
        {
          id: "totalEstimatedShippingCost",
          label: "Est. Freight",
          align: "right",
          render: (_, order) => (
            <BillableShippingCost costs={order} orderType={order.type} />
          ),
        },
        {
          id: "totalEstimatedTax",
          label: "Est. Tax",
          align: "right",
          render: (_, order) => (
            <BillableTaxCost costs={order} orderType={order.type} />
          ),
        },
        {
          id: "_totalExtendedEstCost",
          label: "Est. Total Cost",
          align: "right",
          render: (_, order) =>
            formatMoneyString(getExtendedCost(order, order.type)),
        },
        {
          id: "status",
          label: "Status",
          render: (status) =>
            capitalize(status === "submitted" ? "pending" : status),
        },
      ]}
      rows={data}
      {...tableProps}
    />
  );
};

export default OrderHistoryOrderTable;
