/** @jsxImportSource @emotion/react */
import "twin.macro";

import React from "react";
import { useDispatch } from "react-redux";

import { useQueryClient } from "@tanstack/react-query";
import { setItemPreview } from "src/redux/slices/items/itemSlice";

import { Image } from "@models/Image";
import { Item } from "@models/Item";
import { CldImage } from "@services/cloudinary";
import { ImageSize } from "@services/cloudinary/imageSizeOptions";

import { getItemPreviewImage } from "../helpers";
import { itemsKeyFactory } from "../queries";

type ItemProps =
  | { item: Item; partialItem?: never }
  | { partialItem: { images: Image[]; id: string }; item?: never };

type ItemPreviewImageButtonProps = ItemProps &
  React.ButtonHTMLAttributes<HTMLButtonElement> & {
    size?: ImageSize;
  };

const ItemPreviewImageButton = ({
  item,
  partialItem,
  size,
  ...props
}: ItemPreviewImageButtonProps) => {
  const dispatch = useDispatch();
  const queryClient = useQueryClient();

  const itemParts = item ?? partialItem;

  return (
    <button
      tw="flex-shrink-0 w-20 relative rounded-md cursor-pointer overflow-hidden
          after:(absolute inset-0 bg-neutral-900 opacity-0 transition-opacity)
          hover:after:opacity-20"
      onClick={(e) => {
        e.stopPropagation();
        e.preventDefault();
        if (item) {
          queryClient.setQueryData<Item>(
            itemsKeyFactory.detail(item.id).queryKey,
            item
          );
        }
        dispatch(setItemPreview({ id: itemParts.id }));
      }}
      {...props}
    >
      <CldImage
        tw="w-full aspect-square object-contain 
                  border-2 border-neutral-100
                bg-white rounded-md"
        image={getItemPreviewImage(itemParts)}
        size={size ?? "thumbnail"}
      />
    </button>
  );
};

export default ItemPreviewImageButton;
