import { format } from "date-fns";
import add from "date-fns/add";
import getMonth from "date-fns/getMonth";
import getYear from "date-fns/getYear";
import isAfter from "date-fns/isAfter";
import isBefore from "date-fns/isBefore";
import subMonths from "date-fns/subMonths";
import { saveAs } from "file-saver";
import _, { camelCase, kebabCase, mapKeys, round } from "lodash";

import { toUTC } from "./DateAdapter";

export const saveAsCsv = (csv, filename) => {
  const blob = new Blob([csv], {
    type: "text/csv;charset=utf-8",
  });
  saveAs(blob, filename);
};

export const displayId = (itemNumber, rn) =>
  rn > 1 ? itemNumber + "." + rn : itemNumber;

export const diff = (a, b) => {
  var r = {};
  _.each(a, function (v, k) {
    if (_.isEqual(b[k], v)) return;
    // but what if it returns an empty object? still attach?
    r[k] = [v, b[k]];
  });
  return r;
};

export const sorted = (arr) => {
  return _.sortBy(arr, (v) => v);
};

export const kebabCaseKeys = (obj) => mapKeys(obj, (_, k) => kebabCase(k));
export const camelCaseKeys = (obj) => mapKeys(obj, (_, k) => camelCase(k));

export const upCase = (string, splitter) => {
  return string
    .split(splitter || " ")
    .map((s) => s[0].toUpperCase() + s.slice(1))
    .join(" ");
};

//Handles filtering of programs for the Pre-Order Program View
export const filter = (array, filters) => {
  let filteredArray = [];
  if (filters.length !== 0) {
    array.forEach((item) => {
      let filtered = true;
      for (let i = 0; i < filters.length; i++) {
        if (
          filters[i].type === "brand" &&
          !item.brand.includes(filters[i].value.replace(/^\w+\s/, "")) // removes the item code from the value before comparing
        ) {
          filtered = false;
          break;
        }
        if (
          filters[i].type === "unit" &&
          !item.unit.includes(filters[i].value.name)
        ) {
          filtered = false;
          break;
        }
        if (
          item[filters[i].type] !== filters[i].value &&
          filters[i].type !== "brand" &&
          filters[i].type !== "unit"
        ) {
          filtered = false;
          break;
        }
      }
      if (filtered) {
        filteredArray.push(item);
      }
    });
    return filteredArray;
  } else return array;
};

export const earliestDate = (dateArray) => {
  let sortedDates = dateArray.sort((a, b) => {
    return isBefore(new Date(a["start-date"]), new Date(b["start-date"]))
      ? -1
      : !isBefore(new Date(a["start-date"]), new Date(b["start-date"]))
      ? 1
      : 0;
  });
  return sortedDates;
};

/*
Formats integers into $00.00 format for display purposes, all incoming data
representing money from the api is in cents
*/
const USDollar = new Intl.NumberFormat("en-US", {
  style: "currency",
  currency: "USD",
});

export const formatMoney = (value, ops) =>
  formatMoneyCents(isNaN(value) ? 0 : value, ops ? 4 : 2);

export const formatMoneyCents = (value, decimals = 2) =>
  `$${parseFloat(value / 100).toLocaleString("en-US", {
    minimumFractionDigits: decimals,
    maximumFractionDigits: decimals,
  })}`;

const maxFractionDigitFormatter = new Intl.NumberFormat("en-US", {
  style: "currency",
  currency: "USD",
  maximumFractionDigits: 10,
  minimumFractionDigits: 4,
});
export const formatMoneyUnlimitedDecimals = (value) => {
  return maxFractionDigitFormatter.format(parseFloat(value));
};

export const formatMoneyString = (value) => USDollar.format(value ?? 0);

//handles incoming money values that are strings
export const stringToCents = (numString) => {
  return parseFloat(numString) * 100;
};

//Used in order tables to ensure order numbers match pack size
export const roundUp = (value, rounder) => {
  if (rounder === 0) return String(value);
  return String(Math.ceil(value / rounder) * rounder);
};

//Used for filtering purposes, just a quick call to modify an array
export const separateByComma = (array, key) => {
  if (!array || !array.length) return null;
  if (key) {
    return array.map((index) => index[key]).join(",");
  } else {
    return array.join(",");
  }
};

//Builds a useable array in the query string
export const buildQueryArray = (filter, arg, key) => {
  if (typeof arg !== "object") {
    return `filter[${filter}]%5B%5D=${arg}`;
  } else {
    if (key && typeof arg[0] === "object") {
      return arg
        .map((index) => `filter[${filter}]%5B%5D=${index[key]}`)
        .join("&");
    } else {
      return arg.map((a) => `filter[${filter}]%5B%5D=${a}`).join("&");
    }
  }
};

export const utcDate = (dateString) => toUTC(new Date(dateString));
export const formatUtcDate = (dateString, formatString = "MM/dd/yyyy") =>
  format(utcDate(dateString), formatString);

//adds shifted time in pickers so utc timestamps show up correctly
export const formatDate = (dateString) => {
  let initialDate = new Date(dateString);
  // Super hacky!!!!
  return add(initialDate, { hours: 8 });
};

export const formatDateString = (date) => {
  if (!date) return date;
  let dateArray = date.split("-");
  return `${dateArray[1]}/${dateArray[2]}/${dateArray[0]}`;
};

export const monthMap = {
  1: "January",
  2: "February",
  3: "March",
  4: "April",
  5: "May",
  6: "June",
  7: "July",
  8: "August",
  9: "September",
  10: "October",
  11: "November",
  12: "December",
};

//generates order window selections for filters
export const getWindowSelections = (programType) => {
  let date = new Date();
  let currentMonth = getMonth(date);
  let currentYear = getYear(date);
  const futureMonthsToInclude = 5;
  let month = currentMonth + futureMonthsToInclude;
  if (month > 11) month -= 12;
  let year =
    currentMonth + futureMonthsToInclude > 11 ? currentYear + 1 : currentYear;

  let dateArray = [];
  // Generate options down to Jan of last year
  for (let i = 0; i < 13 + futureMonthsToInclude + currentMonth; i++) {
    dateArray.push({
      month: month + 1,
      year: year,
      programType: programType,
    });
    month -= 1;
    if (month === -1) {
      month = 11;
      year -= 1;
    }
  }
  return dateArray;
};

const parseNumber = (numStr, alt = "error") => {
  const parsed = numStr.replace("$", "");
  return isNaN(parsed) ? alt : Number(parsed);
};

//used to determine if a free type date is valid while uploading shipping information
const isValidDate = (date) => {
  return date instanceof Date && !isNaN(date);
};

//formats and checks if valid dates are later than the past year
const formatCSVDate = (date) => {
  if (!date || date.length === 0) return null;
  let dateObject = new Date(date);
  if (isValidDate(dateObject)) {
    let aYearAgo = subMonths(new Date(), 12);

    if (isAfter(dateObject, aYearAgo)) {
      return dateObject;
    } else return "error";
  } else return "error";
};

/*
Handles the purchase order shipping upload. Users upload a csv of all the shipping information,
and this function checks each field to make sure it is valid, removes any white space, and gets
the information ready to be uploaded to the api.
*/
export const mapShippingCSV = (data) => {
  const mappedData = data
    .filter((dataPoint) => dataPoint.errors.length === 0)
    .map((dataPoint) => ({
      id: dataPoint.data["Param Item Id"],
      carrier:
        dataPoint.data.Carrier.length > 0
          ? dataPoint.data.Carrier.trim().toLowerCase()
          : null,
      method:
        dataPoint.data["Ship Method"].length > 0
          ? dataPoint.data["Ship Method"]
          : null,
      "actual-ship-date": formatCSVDate(dataPoint.data["Actual Ship Date"]),
      "shipped-qty": parseNumber(dataPoint.data["Shipped Quantity"], null),
      "package-count": parseNumber(dataPoint.data["Package Count"], null),
      "tracking-number":
        dataPoint.data["Tracking Number"].length > 0
          ? dataPoint.data["Tracking Number"].trim()
          : null,
      tax: parseNumber(dataPoint.data["Tax"]),
    }));

  return mappedData;
};

// Shifts an object by a value for a key to the front of an array
export const shiftToFront = (key, value, arr) => {
  const isEqualToValue = (x) => x[key] === value;
  const idx = arr.findIndex(isEqualToValue);
  if (idx !== -1) {
    const a = arr.splice(idx, 1);
    arr.unshift(a[0]);
  }
};
