import { createContext, useContext, useEffect, useState } from "react";
import { shallowEqual, useDispatch, useSelector } from "react-redux";

import * as Sentry from "@sentry/react";
import { useAuth0 } from "@auth0/auth0-react";
import { useQuery } from "@tanstack/react-query";
import { dataFormatter } from "src/api/axiosCalls";
import {
  LS_CHANNEL,
  LS_ITEM_VIEW,
  LS_TERRITORY,
} from "src/constants/localStorage";
import { ALL_REGION_ROLES } from "src/constants/permissions";
import ErrorPage from "src/pages/ErrorPage";
import Landing from "src/pages/Landing";
import {
  setChannel,
  setProductView,
  setTerritory,
} from "src/redux/slices/appSlice";
import { mapCurrentUser } from "src/redux/slices/users/maps";
import {
  getUserSuccess,
  updateCurrentChannel,
  updateCurrentTerritory,
} from "src/redux/slices/users/userSlice";

import Loading from "@components/Utility/Loading";
import { AuthUser } from "@models/AuthUser";
import client from "@services/api";

import { currentUserKeyFactory } from "./data";
import useAuthToken from "./useAuthToken";

const AuthUserProviderContext = createContext<AuthUser | null>(null);

const AuthUserProvider = ({ children }) => {
  const dispatch = useDispatch();
  const authStatus = useAuthToken();
  const [appStateInit, setAppStateInit] = useState(false);

  const { error: authError } = useAuth0();

  const { data, error: userError } = useQuery({
    queryKey: currentUserKeyFactory.detail.queryKey,
    queryFn: () =>
      client.get("current-user", { deserializeOutput: false }).then((res) => {
        // Until we get the entire app state to use react-query user state, we must also keep the redux user state up-to-date
        // For simplicty and backwards compatibility, we deserialize the user data differently for redux
        const reduxUserPayload = mapCurrentUser(dataFormatter.deserialize(res));
        dispatch(getUserSuccess({ user: reduxUserPayload }));

        const deserialized = client.deserialize(res);

        return deserialized.data as AuthUser;
      }),
    enabled: authStatus === "ready",
  });

  useEffect(() => {
    if (appStateInit || !data) return;

    Sentry.setUser({ id: data.id, email: data.email });
    Sentry.setTag("user_role", data.role);

    // Set territory based on default and last territory
    let lastTerr = localStorage.getItem(LS_TERRITORY) ?? null;
    // insure lastTerr is still valid
    if (lastTerr && data.territories.every((terr) => terr.id !== lastTerr)) {
      lastTerr = null;
    }
    const territory = ALL_REGION_ROLES.includes(data.role)
      ? lastTerr
      : lastTerr ?? data.territories[0]?.id;
    dispatch(setTerritory(territory));

    // Set channel based on default and last channel
    const lastChannel: any = localStorage.getItem(LS_CHANNEL) ?? null;
    let channel: "retail" | "on_premise";
    if (!lastChannel) channel = data.isRetail ? "retail" : "on_premise";
    else if (lastChannel === "retail" && !data.isRetail) channel = "on_premise";
    else if (lastChannel === "on_premise" && !data.isOnPremise)
      channel = "retail";
    else channel = lastChannel;

    dispatch(setChannel(channel));

    // update old redux state
    dispatch(updateCurrentChannel({ channel }));
    dispatch(updateCurrentTerritory({ territory }));

    // item view preference
    const itemView = localStorage.getItem(LS_ITEM_VIEW);
    if (itemView) dispatch(setProductView(itemView));

    setAppStateInit(true);
  }, [data, dispatch, appStateInit]);

  const error = authError || userError;

  if (error || authStatus === "error") {
    return <ErrorPage error={error} />;
  }

  if (authStatus === "unset") {
    return <Landing />;
  }

  if (!data) {
    return <Loading />;
  }

  // Only render the children if the user is authenticated
  return (
    <AuthUserProviderContext.Provider value={data!}>
      {children}
    </AuthUserProviderContext.Provider>
  );
};

export default AuthUserProvider;

export const useAuthUser = () => {
  const { channel, territory } = useSelector(
    (state: any) => ({
      channel: state.app.channel as string,
      territory: state.app.territory as string,
    }),
    shallowEqual
  );
  const context = useContext(AuthUserProviderContext);
  if (!context) {
    throw new Error("useAuthUser must be used within a AuthUserProvider");
  }
  return { ...context, channel, territory };
};
