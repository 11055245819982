/** @jsxImportSource @emotion/react */
import "twin.macro";

import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";

import GetAppIcon from "@mui/icons-material/GetApp";
import Button from "@mui/material/Button";
import CircularProgress from "@mui/material/CircularProgress";

import { useNoFetch } from "src/hooks/UtilityHooks";
import { resetFilters, setFiltersOpen } from "src/redux/slices/filterSlice";

import { Contained } from "@components/StyledComponents";
import { PageTitle } from "@features/ui";
import { CSVLink } from "@utils/csv";

import { useReportCsv } from "../../hooks/Reports/useReportCsv";
import { clearReports } from "../../redux/slices/reports/reportSlice";
import ReportTable from "./ReportTable";

const ReduxReportGenerator = ({
  reportTitle,
  fileName,
  reportGetFunc,
  reportResetFunc,
  children,
  isGenerateDisabled = false,
}) => {
  const dispatch = useDispatch();

  const [hasGenerated, setHasGenerated] = useState(false);

  const isLoading = useSelector((state) => state.reports.isLoading);
  const report = useSelector((state) => state.reports.reportData);
  const reportType = useSelector((state) => state.reports.reportType);

  const { setCsvData, data, headers } = useReportCsv(
    reportType,
    hasGenerated,
    setHasGenerated,
    isLoading,
    report
  );

  const handleReset = () => {
    dispatch(resetFilters());
    dispatch(clearReports());
    setCsvData({ data: [], headers: [] });
    reportResetFunc();
  };

  const handleGetReport = () => {
    reportGetFunc();
    setHasGenerated(true);
  };

  useEffect(() => {
    dispatch(setFiltersOpen({ open: false }));
    dispatch(clearReports());
    dispatch(resetFilters());
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useNoFetch(dispatch);

  return (
    <>
      <Contained tw="space-y-6">
        <div tw="flex justify-between items-center mb-12">
          <PageTitle title={reportTitle} fallbackUrl="/reports" />

          <CSVLink data={data} headers={headers} filename={fileName}>
            <Button
              variant="contained"
              color="secondary"
              startIcon={<GetAppIcon />}
              disabled={data.length === 0}
            >
              EXPORT REPORT
            </Button>
          </CSVLink>
        </div>
        <div>{children}</div>
        <div tw="flex justify-center gap-4 mb-8">
          <Button
            variant="contained"
            color="secondary"
            onClick={() => handleGetReport()}
            disabled={isGenerateDisabled}
          >
            GENERATE REPORT
          </Button>
          <Button variant="contained" color="secondary" onClick={handleReset}>
            CLEAR FILTERS
          </Button>
        </div>
        {isLoading && (
          <div tw="flex justify-center">
            <CircularProgress size={100} tw="mt-16" />
          </div>
        )}
        {!isLoading && report.length > 0 && reportType && (
          <ReportTable report={report} type={reportType} />
        )}
      </Contained>
    </>
  );
};

export default ReduxReportGenerator;
