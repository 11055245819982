import { query } from "@services/api";

export const buildItemQuery = ({ sortString, ...filterObject }) => {
  return `/api/items?${query({ filter: filterObject, sort: sortString })}`;
};

export const buildItemProgramQuery = ({ sortString, ...filterObject }) => {
  return `/api/item-programs?${query({
    filter: filterObject,
    sort: sortString,
  })}`;
};

export const buildBrand = (productFamilyId) => {
  return {
    data: {
      type: "brand",
      relationships: {
        "product-family": {
          data: {
            type: "product-family",
            id: productFamilyId,
          },
        },
      },
    },
  };
};
