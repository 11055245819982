import axios from "axios";
import saveAs from "file-saver";
import _ from "lodash";
import { separateByComma } from "src/utility/utilityFunctions";

import { createSlice } from "@reduxjs/toolkit";
import { query } from "@services/api";

import { buildFilters, handleErrors } from "../../../api/apiFunctions";
import { axiosGetNoFormat, axiosGetWithNext } from "../../../api/axiosCalls";
import { setError } from "../errorSlice";
import {
  resetStepperValue,
  setIsStepper,
  startGlobalLoad,
  stopGlobalLoad,
  updateStepperValue,
} from "../globalLoadSlice";
import { mapItems } from "../items/maps";
import {
  buildInvoicingReportQuery,
  buildOrderVariantSummaryQuery,
  buildReportByIdQuery,
  buildRoadmapForecastQuery,
  buildVariantAllocationQuery,
} from "./helpers";
import {
  mapAvailableInventoryItems,
  mapCDCShipListReport,
  mapCDCSummaryReport,
  mapCDCTransferAdviceReport,
  mapCustomCouponReport,
  mapFinanceBillingReport,
  mapFinanceJournalReport,
  mapFocusPresentation,
  mapInventoryItems,
  mapInvoiceDetailReport,
  mapInvoiceDoubleCheckReport,
  mapInvoicingReport,
  mapOpenPurchaseOrderReport,
  mapOrderDetailReport,
  mapOrderVariantSummary,
  mapOrderWindowDetail,
  mapOrderWindowItems,
  mapOrderWindowSummary,
  mapOrderedItemsReport,
  mapPOComplianceItems,
  mapPreOrderNonParticipantReport,
  mapPrintInvoiceReport,
  mapPurchaseOrderReport,
  mapRFPSummary,
  mapRoadmapForecastItems,
  mapSupplierSpendDetailReport,
  mapSupplierSpendSummaryReport,
  mapVariantAllocationReport,
} from "./maps";

let initialState = {
  isLoading: false,
  reportType: null,
  reportData: [],
  error: null,
};

const loadingFailed = (state, action) => {
  const { error } = action.payload;
  state.isLoading = false;
  state.error = error;
};

const reportSlice = createSlice({
  name: "reports",
  initialState,
  reducers: {
    setIsLoading(state) {
      state.isLoading = true;
    },
    getReportsSuccess(state, action) {
      const { type, reportData } = action.payload;
      state.reportType = type;
      state.reportData = reportData;
      state.error = null;
      state.isLoading = false;
    },
    clearReports(state) {
      state.isLoading = false;
      state.reportType = null;
      state.reportData = [];
      state.error = null;
    },
    setFailure: loadingFailed,
  },
});

export const { setIsLoading, getReportsSuccess, clearReports, setFailure } =
  reportSlice.actions;

export default reportSlice.reducer;

export const getInventoryItemReport =
  (filterObject, isAvailableInventory = false) =>
  async (dispatch) => {
    try {
      dispatch(setIsLoading());
      dispatch(startGlobalLoad());
      const queryString = `/api/inventory-report?${query(filterObject)}`;
      const response = await axiosGetNoFormat(queryString);
      if (response.error) throw response.error;
      const mappedData = isAvailableInventory
        ? mapAvailableInventoryItems(response.data)
        : mapInventoryItems(response.data);
      dispatch(
        getReportsSuccess({
          type: isAvailableInventory ? "available-inventory" : "inventory",
          reportData: mappedData,
        })
      );
      dispatch(stopGlobalLoad());
    } catch (err) {
      dispatch(setFailure({ error: err.toString() }));
      dispatch(setError({ error: err.toString(), source: "Reports" }));
      dispatch(stopGlobalLoad());
    }
  };

export const getOrderVariantSummaryReport =
  (filterObject) => async (dispatch) => {
    try {
      dispatch(setIsLoading());
      dispatch(
        setIsStepper({
          stepBool: true,
          stepTitle: "Generating Finance Tracker Report",
        })
      );
      let ovArray = [];
      let stepValue = 15;

      let queryString = buildOrderVariantSummaryQuery(filterObject);
      let initialResponse = await axiosGetWithNext(queryString);
      if (initialResponse.error) {
        throw initialResponse.error;
      }
      let initialMappedData = mapOrderVariantSummary(initialResponse.data.data);
      ovArray = ovArray.concat(initialMappedData);

      let nextLink = initialResponse.data.nextLink;
      dispatch(updateStepperValue({ value: stepValue }));

      if (nextLink) {
        let fetchCount = Math.ceil(
          (initialResponse.data.totalEntries - 100) / 100
        );
        stepValue = parseFloat((85 / fetchCount).toFixed(2));

        for (let i = 0; i < fetchCount; i++) {
          let response = await axiosGetWithNext(nextLink);
          if (response.error) throw response.error;
          nextLink = response.data.nextLink;
          let mappedData = mapOrderVariantSummary(response.data.data);
          ovArray = ovArray.concat(mappedData);
          dispatch(updateStepperValue({ value: stepValue }));
        }
      } else {
        dispatch(updateStepperValue({ value: 75 }));
      }
      dispatch(
        getReportsSuccess({ type: "finance-tracker", reportData: ovArray })
      );
      dispatch(resetStepperValue());
    } catch (err) {
      dispatch(setFailure({ error: err.toString() }));
      dispatch(setError({ error: err.toString(), source: "Reports" }));
      dispatch(resetStepperValue());
    }
  };

export const getPOComplianceReport = (filterObject) => async (dispatch) => {
  try {
    dispatch(setIsLoading());
    dispatch(startGlobalLoad());
    const queryString = buildFilters(
      filterObject,
      "",
      "",
      "/api/po-compliance-report",
      "reports"
    );
    const response = await axiosGetNoFormat(queryString);
    if (response.error) throw response.error;
    let mappedData = mapPOComplianceItems(response.data);
    dispatch(
      getReportsSuccess({
        type: "po-compliance",
        reportData: mappedData,
      })
    );
    dispatch(stopGlobalLoad());
  } catch (err) {
    dispatch(setFailure({ error: err.toString() }));
    dispatch(setError({ error: err.toString(), source: "Reports" }));
    dispatch(stopGlobalLoad());
  }
};

export const getRoadmapForecastReport = (filterObject) => async (dispatch) => {
  try {
    dispatch(setIsLoading());
    dispatch(startGlobalLoad());
    const queryString = buildRoadmapForecastQuery(filterObject);
    const response = await axiosGetNoFormat(queryString);
    if (response.error) throw response.error;
    let mappedData = mapRoadmapForecastItems(response.data);
    dispatch(
      getReportsSuccess({
        type: "roadmap-forecast",
        reportData: mappedData,
      })
    );
    dispatch(stopGlobalLoad());
  } catch (err) {
    dispatch(setFailure({ error: err.toString() }));
    dispatch(setError({ error: err.toString(), source: "Reports" }));
    dispatch(stopGlobalLoad());
  }
};

export const getCustomCouponReport = (filterObject) => async (dispatch) => {
  try {
    dispatch(setIsLoading());
    dispatch(startGlobalLoad());
    const queryString = `/api/custom-coupon-report?filter[approved-at-range]=${filterObject.dateRange}&filter[channel]=${filterObject.channel}`;
    const response = await axiosGetNoFormat(queryString);
    if (response.error) throw response.error;
    let mappedData = mapCustomCouponReport(response.data);
    dispatch(
      getReportsSuccess({
        type: "coupon",
        reportData: mappedData,
      })
    );
    dispatch(stopGlobalLoad());
  } catch (err) {
    dispatch(setFailure({ error: err.toString() }));
    dispatch(setError({ error: err.toString(), source: "Reports" }));
    dispatch(stopGlobalLoad());
  }
};

export const getCouponProductionReport =
  (freeTypeFields, callback) => async (dispatch, getState) => {
    const filters = getState().filters;
    const channel = getState().user.currentChannel;
    const orderTypes = [
      "on-demand",
      "from-inventory",
      "to-inventory",
      "pre-order",
    ];

    try {
      const orderTypeFilter = orderTypes.includes(filters.type)
        ? { orderType: filters.type }
        : { orderCalendarMonthIds: filters.type };

      const params = {
        filter: {
          ..._.mapValues(freeTypeFields, (val) => val || null),
          ...orderTypeFilter,
          orderDateRange: `${filters.fromDate} - ${filters.toDate}`,
          channel: channel,
          anaplanProgramType: filters.anaplanProgramType,
          userIds: separateByComma(filters.user, "id"),
          brandIds: separateByComma(filters.brand, "id"),
          addressIds: separateByComma(filters.address, "id"),
          programIds: separateByComma(filters.program, "id"),
          itemTypeIds: separateByComma(filters.itemType, "id"),
          businessUnitNames: separateByComma(filters.bu, "name"),
          stateIds: separateByComma(filters.stateIds, "id"),
          territoryIds: separateByComma(filters.territory, "id"),
          supplierIds: separateByComma(filters.supplier, "id"),
        },
      };
      dispatch(setIsLoading());
      dispatch(startGlobalLoad());
      const queryString = `/api/coupon-production-report?${query(params)}`;
      const response = await axiosGetNoFormat(queryString);
      if (response.error) throw response.error;
      if (response.data.length === 0)
        throw new Error("No data found for your search.");

      const blob = new Blob([response.data], {
        type: "text/csv;charset=utf-8",
      });
      saveAs(blob, `compliance_audit_report.csv`);
      dispatch(stopGlobalLoad());
    } catch (err) {
      dispatch(setFailure({ error: err.toString() }));
      dispatch(setError({ error: err.toString(), source: "Reports" }));
      dispatch(stopGlobalLoad());
    } finally {
      callback?.();
    }
  };

export const getReportById = (id, filterObject) => async (dispatch) => {
  const reportMapReference = {
    "focus-presentation": mapFocusPresentation,
    "order-window-summary": mapOrderWindowSummary,
    "order-window-detail": mapOrderWindowDetail,
    "rfp-summary": mapRFPSummary,
  };

  const formatId = (oldId) => {
    if (oldId !== "order-window-summary" && oldId !== "order-window-detail") {
      return oldId;
    } else {
      if (oldId === "order-window-summary") {
        return "focus-summary";
      } else {
        return "focus-detail";
      }
    }
  };

  try {
    dispatch(setIsLoading());
    dispatch(startGlobalLoad());
    const queryString = buildReportByIdQuery(formatId(id), filterObject);
    const response = await axiosGetNoFormat(queryString);
    if (response.error) throw response.error;
    const mappedData = reportMapReference[id](
      response.data,
      filterObject.isNational ? "national" : "territory"
    );
    dispatch(getReportsSuccess({ type: id, reportData: mappedData }));
    dispatch(stopGlobalLoad());
  } catch (err) {
    console.log(err);
    dispatch(setFailure({ error: err.toString() }));
    dispatch(setError({ error: err.toString(), source: "Reports" }));
    dispatch(stopGlobalLoad());
  }
};

export const getCDCReportById = (id, filterObject) => async (dispatch) => {
  const reportMapReference = {
    "inbound-item-summary": mapCDCSummaryReport,
    "ship-list": mapCDCShipListReport,
    "transfer-advice": mapCDCTransferAdviceReport,
  };

  try {
    dispatch(setIsLoading());
    dispatch(startGlobalLoad());
    const queryString = buildFilters(
      filterObject,
      "",
      "",
      `/api/cdc-reports/${id}`,
      "reports"
    );
    const response = await axiosGetNoFormat(queryString);
    if (response.error) throw response.error;
    const mappedData = reportMapReference[id](response.data);
    dispatch(getReportsSuccess({ type: id, reportData: mappedData }));
    dispatch(stopGlobalLoad());
  } catch (err) {
    dispatch(setFailure({ error: err.toString() }));
    dispatch(setError({ error: err.toString(), source: "Reports" }));
    dispatch(stopGlobalLoad());
  }
};

export const getShipListReport = (filterObject) => async (dispatch) => {
  try {
    dispatch(setIsLoading());
    dispatch(startGlobalLoad());
    const queryString = `/api/supplier-shipping-report?filter[purchase-order-ids]=${filterObject.poIds}`;
    const response = await axiosGetNoFormat(queryString);
    if (response.error) throw response.error;
    const mappedData = mapCDCShipListReport(response.data);
    dispatch(getReportsSuccess({ type: "ship-list", reportData: mappedData }));
    dispatch(stopGlobalLoad());
  } catch (err) {
    dispatch(setFailure({ error: err.toString() }));
    dispatch(setError({ error: err.toString(), source: "Reports" }));
    dispatch(stopGlobalLoad());
  }
};

export const getSupplierSpendReportById =
  (id, filterObject) => async (dispatch) => {
    const reportMapReference = {
      "supplier-summary": mapSupplierSpendSummaryReport,
      "supplier-detail": mapSupplierSpendDetailReport,
    };

    try {
      dispatch(setIsLoading());
      dispatch(startGlobalLoad());
      const rangeName =
        filterObject.dateRangeType === "po_submitted"
          ? "submitted-at-range"
          : "in-market-range";
      const queryString = buildFilters(
        filterObject,
        `filter[${rangeName}]=${filterObject.dateRange}`,
        "",
        `/api/supplier-spend-reports/${id.split("-")[1]}`,
        "reports"
      );
      const response = await axiosGetNoFormat(queryString);
      if (response.error) throw response.error;
      const mappedData = reportMapReference[id](response.data);
      dispatch(getReportsSuccess({ type: id, reportData: mappedData }));
      dispatch(stopGlobalLoad());
    } catch (err) {
      dispatch(setFailure({ error: err.toString() }));
      dispatch(setError({ error: err.toString(), source: "Reports" }));
      dispatch(stopGlobalLoad());
    }
  };

export const getFinanceReportByType =
  (filterObject, type) => async (dispatch) => {
    try {
      dispatch(setIsLoading());
      dispatch(startGlobalLoad());
      let queryString = `/api/billing-report?filter[actual-ship-date]=${filterObject.dateRange}&filter[channel]=${filterObject.channel}`;
      if (filterObject.role !== "all") {
        queryString += `&filter[is-purchaser-select]=${
          filterObject.role === "select" ? "true" : "false"
        }`;
      }
      const response = await axiosGetNoFormat(queryString);
      if (response.error) throw response.error;
      const mappedData =
        type === "finance-billing"
          ? mapFinanceBillingReport(response.data)
          : mapFinanceJournalReport(response.data);
      dispatch(getReportsSuccess({ type: type, reportData: mappedData }));
      dispatch(stopGlobalLoad());
    } catch (err) {
      dispatch(setFailure({ error: err.toString() }));
      dispatch(setError({ error: err.toString(), source: "Reports" }));
      dispatch(stopGlobalLoad());
    }
  };

export const getOrderedItemsReport = (filterObject) => async (dispatch) => {
  try {
    dispatch(setIsLoading());
    dispatch(startGlobalLoad());
    const queryString = buildFilters(
      filterObject,
      "",
      "",
      "/api/ordered-items-report",
      "reports"
    );
    const response = await axiosGetNoFormat(queryString);
    if (response.error) throw response.error;
    const mappedData = mapOrderedItemsReport(response.data);
    dispatch(
      getReportsSuccess({ type: "ordered-items", reportData: mappedData })
    );
    dispatch(stopGlobalLoad());
  } catch (err) {
    dispatch(setFailure({ error: err.toString() }));
    dispatch(setError({ error: err.toString(), source: "Reports" }));
    dispatch(stopGlobalLoad());
  }
};

export const getOrderWindowItemsReport = (filterObject) => async (dispatch) => {
  try {
    dispatch(setIsLoading());
    dispatch(startGlobalLoad());
    const queryString = buildFilters(
      filterObject,
      "",
      "",
      "/api/order-window-items-report",
      "reports"
    );
    const response = await axiosGetNoFormat(queryString);
    if (response.error) throw response.error;
    const mappedData = mapOrderWindowItems(response.data);

    dispatch(
      getReportsSuccess({
        type: "order-window-items",
        reportData: mappedData,
      })
    );
    dispatch(stopGlobalLoad());
  } catch (err) {
    dispatch(setFailure({ error: err.toString() }));
    dispatch(setError({ error: err.toString(), source: "Reports" }));
    dispatch(stopGlobalLoad());
  }
};

export const getInvoicingReport = (filterObject) => async (dispatch) => {
  try {
    dispatch(setIsLoading());
    dispatch(startGlobalLoad());
    const queryString = buildInvoicingReportQuery(filterObject);
    const response = await axiosGetNoFormat(queryString);
    if (response.error) throw response.error;
    const mappedData = mapInvoicingReport(response.data);
    dispatch(getReportsSuccess({ type: "invoicing", reportData: mappedData }));
    dispatch(stopGlobalLoad());
  } catch (err) {
    dispatch(setFailure({ error: err.toString() }));
    dispatch(setError({ error: err.toString(), source: "Reports" }));
    dispatch(stopGlobalLoad());
  }
};

export const getPrintInvoiceReport = (invoiceNumber) => async (dispatch) => {
  try {
    dispatch(setIsLoading());
    dispatch(startGlobalLoad());
    const response = await axiosGetNoFormat(
      `/api/print-invoice-report?invoice-number=${invoiceNumber}`
    );
    if (response.error) throw new Error(response.error);
    if (response.data.length === 5)
      throw new Error(`No results found for invoice # ${invoiceNumber}`);
    const mappedData = mapPrintInvoiceReport(response.data);
    dispatch(
      getReportsSuccess({ type: "print-invoice", reportData: mappedData })
    );
    dispatch(stopGlobalLoad());
  } catch (err) {
    dispatch(setFailure({ error: err.toString() }));
    dispatch(setError({ error: err.toString(), source: "Reports" }));
    dispatch(stopGlobalLoad());
  }
};

export const getInvoiceDoubleCheckReport =
  (invoiceNumber) => async (dispatch) => {
    try {
      dispatch(setIsLoading());
      dispatch(startGlobalLoad());
      const response = await axiosGetNoFormat(
        `/api/invoice-double-check-report?invoice-number=${invoiceNumber}`
      );
      if (response.error) throw response.error;
      const mappedData = mapInvoiceDoubleCheckReport(response.data);
      dispatch(
        getReportsSuccess({
          type: "invoice-double-check",
          reportData: mappedData,
        })
      );
      dispatch(stopGlobalLoad());
    } catch (err) {
      dispatch(setFailure({ error: err.toString() }));
      dispatch(setError({ error: err.toString(), source: "Reports" }));
      dispatch(stopGlobalLoad());
    }
  };

export const getOrderDetailReport = (filterObject) => async (dispatch) => {
  try {
    dispatch(setIsLoading());
    dispatch(startGlobalLoad());

    let response = await axiosGetNoFormat(
      `/api/order-detail-report?${query(filterObject)}`,
      { timeout: 120_000 }
    );

    if (response.error) throw response.error;
    const mappedData = mapOrderDetailReport(response.data);
    dispatch(
      getReportsSuccess({
        type: "order-detail",
        reportData: mappedData,
      })
    );
    dispatch(stopGlobalLoad());
  } catch (err) {
    dispatch(setFailure({ error: err.toString() }));
    const message = err.message.startsWith("The request took too long.")
      ? "The request took too long.\nTry narrowing your search filters for more accurate results"
      : err.message;
    dispatch(setError({ error: message, source: "Reports" }));
    dispatch(stopGlobalLoad());
  }
};

export const getOpenPurchaseOrdersReport =
  (filterObject) => async (dispatch) => {
    try {
      dispatch(setIsLoading());
      dispatch(startGlobalLoad());
      const queryString = buildFilters(
        filterObject,
        "",
        "",
        "/api/open-purchase-order-report",
        "reports"
      );
      const response = await axiosGetNoFormat(queryString);
      if (response.error) throw response.error;
      const mappedData = mapOpenPurchaseOrderReport(response.data);
      dispatch(
        getReportsSuccess({
          type: "open-purchase-orders",
          reportData: mappedData,
        })
      );
      dispatch(stopGlobalLoad());
    } catch (err) {
      dispatch(setFailure({ error: err.toString() }));
      dispatch(setError({ error: err.toString(), source: "Reports" }));
      dispatch(stopGlobalLoad());
    }
  };

export const getInvoiceDetailReport = (invoiceNumber) => async (dispatch) => {
  try {
    dispatch(setIsLoading());
    dispatch(startGlobalLoad());
    const response = await axiosGetNoFormat(
      `/api/invoice-detail-report?invoice-number=${invoiceNumber}`
    );
    if (response.error) throw response.error;
    const mappedData = mapInvoiceDetailReport(response.data);
    dispatch(
      getReportsSuccess({
        type: "invoice-detail",
        reportData: mappedData,
      })
    );
    dispatch(stopGlobalLoad());
  } catch (err) {
    dispatch(setFailure({ error: err.toString() }));
    dispatch(setError({ error: err.toString(), source: "Reports" }));
    dispatch(stopGlobalLoad());
  }
};

export const getVariantAllocationReport =
  (filterObject) => async (dispatch) => {
    try {
      dispatch(setIsLoading());
      dispatch(startGlobalLoad());
      const queryString = buildVariantAllocationQuery(filterObject);
      const response = await axiosGetNoFormat(queryString);
      if (response.error) throw response.error;
      const mappedData = mapVariantAllocationReport(response.data);
      dispatch(
        getReportsSuccess({
          type: "variant-allocation",
          reportData: mappedData,
        })
      );
      dispatch(stopGlobalLoad());
    } catch (err) {
      dispatch(setFailure({ error: err.toString() }));
      dispatch(setError({ error: err.toString(), source: "Reports" }));
      dispatch(stopGlobalLoad());
    }
  };

export const getPurchaseOrderReport = (filterObject) => async (dispatch) => {
  try {
    dispatch(setIsLoading());
    dispatch(startGlobalLoad());
    const queryString = buildFilters(
      filterObject,
      "",
      "",
      "/api/purchase-order-report",
      "purchase-order-report"
    );
    const response = await axiosGetNoFormat(queryString);
    if (response.error) throw response.error;
    const mappedData = mapPurchaseOrderReport(response.data);
    dispatch(
      getReportsSuccess({
        type: "purchase-order-report",
        reportData: mappedData,
      })
    );
    dispatch(stopGlobalLoad());
  } catch (err) {
    dispatch(setFailure({ error: err.toString() }));
    dispatch(setError({ error: err.toString(), source: "Reports" }));
    dispatch(stopGlobalLoad());
  }
};

export const getSingleItemInventoryReport =
  (itemNumber) => async (dispatch) => {
    try {
      dispatch(setIsLoading());
      dispatch(startGlobalLoad());
      const itemResponse = await axiosGetWithNext(
        `/api/items?filter[item-number]=${itemNumber}`
      );
      if (itemResponse.error) throw itemResponse.error;
      const getWarehouse = (itemNumber) => {
        let baseItemNumber = itemNumber.split("-")[0];
        let currentItem = itemResponse.data.data.find(
          (i) => i["item-number"] === baseItemNumber
        );
        if (currentItem && currentItem.warehouse) {
          return (
            currentItem.warehouse[0].toUpperCase() +
            currentItem.warehouse.slice(1)
          );
        } else return "No Warehouse Assignment";
      };
      let mappedData = mapItems(itemResponse.data.data)
        .reduce((variantArray, item) => variantArray.concat(item.variants), [])
        .map((v) => ({
          id: v.id,
          name: v.name,
          warehouse: getWarehouse(v.sku),
          itemNumber: v.sku,
          availableQty: v.qty,
          warehouseQty: v.warehouseQty,
          territoryQtys: v.territoryQtys,
          pendingQty: 0,
        }));

      let nextLink = "initial";
      let variantQtys = [];

      while (nextLink) {
        let draftResponse = await axiosGetWithNext(
          nextLink && nextLink !== "initial"
            ? nextLink
            : `/api/order-variants?filter[item-number]=${itemNumber}&filter[order-status]=draft`
        );
        if (draftResponse.error) throw draftResponse.error;
        draftResponse.data.data.forEach((v) =>
          variantQtys.push({ itemNumber: v["item-number"], qty: v.qty })
        );
        nextLink = draftResponse.data.nextLink ?? null;
      }
      nextLink = "initial";
      while (nextLink) {
        let submittedResponse = await axiosGetWithNext(
          nextLink && nextLink !== "initial"
            ? nextLink
            : `/api/order-variants?filter[item-number]=${itemNumber}&filter[order-status]=submitted`
        );

        if (submittedResponse.error) throw submittedResponse.error;
        submittedResponse.data.data.forEach((v) =>
          variantQtys.push({ itemNumber: v["item-number"], qty: v.qty })
        );
        nextLink = submittedResponse.data.nextLink ?? null;
      }

      variantQtys.forEach((qty) => {
        mappedData = mappedData.map((v) => {
          if (v.itemNumber === qty.itemNumber) {
            return {
              ...v,
              pendingQty: v.pendingQty + qty.qty,
            };
          } else return v;
        });
      });
      dispatch(
        getReportsSuccess({
          type: "single-item-inventory",
          reportData: mappedData,
        })
      );
      dispatch(stopGlobalLoad());
    } catch (err) {
      dispatch(setFailure({ error: err.toString() }));
      dispatch(setError({ error: err.toString(), source: "Reports" }));
      dispatch(stopGlobalLoad());
    }
  };

export const getAuditReport = (filterObject) => async (dispatch) => {
  try {
    dispatch(setIsLoading());
    dispatch(startGlobalLoad());
    const queryString = buildFilters(
      filterObject,
      "",
      "",
      "/api/audit-report",
      "reports"
    );
    const response = await axios.get(queryString);
    if (response.error) throw response.error;
    dispatch(
      getReportsSuccess({
        type: "audit-report",
        reportData: response.data,
      })
    );
    dispatch(stopGlobalLoad());
  } catch (err) {
    const error = handleErrors(err);
    dispatch(setFailure({ error }));
    dispatch(setError({ error, source: "Audit Report" }));
    dispatch(stopGlobalLoad());
  }
};

export const getCalendarReport = (filterObject) => async (dispatch) => {
  try {
    dispatch(setIsLoading());
    dispatch(startGlobalLoad());
    const queryString = buildFilters(
      filterObject,
      "",
      "",
      "/api/calendar-report",
      "reports"
    );
    const response = await axios.get(queryString);
    if (response.error) throw response.error;
    dispatch(
      getReportsSuccess({
        type: "calendar-report",
        reportData: response.data,
      })
    );
    dispatch(stopGlobalLoad());
  } catch (err) {
    console.log(err);
    dispatch(setFailure({ error: err.toString() }));
    dispatch(setError({ error: err.toString(), source: "Audit Report" }));
    dispatch(stopGlobalLoad());
  }
};

export const getPreOrderNonParticipantReport =
  (filterObject) => async (dispatch) => {
    try {
      dispatch(setIsLoading());
      dispatch(startGlobalLoad());
      const queryString = `/api/pre-order-non-participant-report?${query(
        filterObject
      )}`;
      const response = await axiosGetNoFormat(queryString);
      if (response.error) throw response.error;
      const mappedData = mapPreOrderNonParticipantReport(response.data);
      dispatch(
        getReportsSuccess({
          type: "pre-order-non-participant",
          reportData: mappedData,
        })
      );
      dispatch(stopGlobalLoad());
    } catch (err) {
      dispatch(setFailure({ error: err.toString() }));
      dispatch(setError({ error: err.toString(), source: "Reports" }));
      dispatch(stopGlobalLoad());
    }
  };
