/** @jsxImportSource @emotion/react */
import "twin.macro";

import { useState } from "react";
import { useDispatch } from "react-redux";

import { FormControl, InputLabel, MenuItem, Select } from "@mui/material";

import { Territory } from "@models/Territory";
import { ResourceAutocomplete } from "@utils/forms";

import ReduxReportGenerator from "../components/Reporting/ReduxReportGenerator";
import { getInventoryItemReport } from "../redux/slices/reports/reportSlice";

const ReportAvailableInventory = () => {
  const dispatch = useDispatch();

  const [territory, setTerritory] = useState<Territory | null>(null);
  const [channel, setChannel] = useState<string>("on_premise");

  const handleReset = () => {
    setTerritory(null);
  };

  const handleGetReport = () => {
    const params = {
      filter: {
        available_inventory_only: true,
        available_to_order_only: true,
        ...(territory && { territoryId: territory?.id }),
        channel: channel,
      },
    };
    dispatch(getInventoryItemReport(params, true));
  };

  return (
    <>
      <ReduxReportGenerator
        reportTitle={"Available Inventory Report"}
        fileName={"available-inventory.csv"}
        reportResetFunc={handleReset}
        reportGetFunc={handleGetReport}
      >
        <div tw="w-4/5 min-w-min flex gap-4 justify-center items-center mx-auto">
          <ResourceAutocomplete
            tw="w-48"
            label="Territory"
            resource="territories"
            requestParams={{
              filter: {
                isActive: true,
                isAwaitingCode: false,
              },
            }}
            value={territory}
            onChange={(_, value) => setTerritory(value)}
          />
          <FormControl tw="w-48">
            <InputLabel size="small">Channel</InputLabel>
            <Select
              value={channel}
              onChange={(e) => setChannel(e.target.value)}
              size="small"
            >
              <MenuItem value="on_premise">On Premise</MenuItem>
              <MenuItem value="retail">Retail</MenuItem>
            </Select>
          </FormControl>
        </div>
      </ReduxReportGenerator>
    </>
  );
};

export default ReportAvailableInventory;
