/** @jsxImportSource @emotion/react */
import tw from "twin.macro";

import { useDispatch } from "react-redux";

import { TextField, Typography } from "@mui/material";

import { useQuery } from "@tanstack/react-query";
import _, { without } from "lodash";

import client from "@services/api";

import {
  parseWebsiteDescription,
  websiteDescSpecs,
} from "../../../../redux/mapHelpers";
import { updateSpecification } from "../../../../redux/slices/planningTool/itemProgramSlice";
import {
  useCustomForm,
  useCustomRegister,
} from "../../../../utility/inputHelpers";
import { AutocompleteInput } from "../../../Forms/ControlledInputs";

const useStandardSpecOptions = () => {
  return useQuery({
    queryKey: ["standard-specs"],
    queryFn: () => client.get(`standard-specs`).then((res) => res.data),
    select: (data) =>
      _(data)
        .uniqBy("Reference Number")
        // mapping for the AutocompleteInput
        .map((spec) => ({
          ...spec,
          id: spec["Reference Number"],
          label: spec["Reference Number"],
        }))
        .value(),
  });
};

/**
 * specType is either "spec" or "description"
 * "spec" is used for paper items
 * "description" is used for non-paper items and uses the "Website Description" field
 */

const specLabels = {
  // non-paper specs
  description: websiteDescSpecs,
  // paper specs
  spec: [
    "Standard Spec Code",
    "Back 4-Color",
    "Back Finish",
    "Dieline",
    "Embossing",
    "Finishing Type",
    "Flat Size",
    "Front 4-Color",
    "Front Finish",
    "Hot Stamp",
    "Pack Out",
    "Perf",
    "Reference Number",
    "Score",
    "Stock",
    "Supplier Instructions",
    "Other Notes",
  ],
};

const shouldNotDisplay = ["Standard Spec Code", "MOQ"];

const encodeWebsiteDescription = (data) => ({
  "Website Description": specLabels.description
    .map((key) => `${key}: ${data[key] ?? " "}`)
    .join("\r\n"),
});

const SpecAccordion = ({
  itemProgramId,
  itemId,
  specs,
  specType,
  couponSpecCode,
}) => {
  const dispatch = useDispatch();

  const { data: standardSpecs = [] } = useStandardSpecOptions();

  const parsed =
    specType === "description"
      ? parseWebsiteDescription(specs["Website Description"])
      : specs;

  const {
    register,
    formState: errors,
    getValues,
    reset,
    control,
  } = useCustomForm({
    defaultValues: {
      ...parsed,
    },
  });

  const fieldsToShow = without(specLabels[specType] ?? [], ...shouldNotDisplay);

  const handleSave = () => {
    const values = getValues();
    const data =
      specType === "description" ? encodeWebsiteDescription(values) : values;
    dispatch(updateSpecification(itemProgramId, itemId, data));
  };

  const reg = useCustomRegister(register, errors, {
    onBlur: handleSave,
  });

  const fillFormFields = (standardSpec) => {
    if (!standardSpec) return;
    reset({
      ...standardSpec,
      "Standard Spec Code": standardSpec.id,
    });
  };

  return (
    <div>
      {couponSpecCode && (
        <Typography tw="mb-4">
          Gallo Coupon Standard Spec Code: {couponSpecCode}
        </Typography>
      )}
      {specType === "spec" && (
        <AutocompleteInput
          options={standardSpecs}
          tw="mb-4 max-w-full w-72"
          onChange={(data) => fillFormFields(data)}
          onBlur={() => {
            handleSave();
          }}
          control={control}
          name={"Standard Spec Code"}
          renderInput={(params) => <TextField {...params} label="Spec Code" />}
        />
      )}
      <div tw="columns-sm w-full">
        {fieldsToShow.map((label) => (
          <div
            tw="p-2 flex items-start rounded transition border border-solid border-transparent hover:bg-slate-100 hover:border-slate-200"
            key={label}
          >
            <label htmlFor={label} tw="w-40 mr-4 cursor-pointer">
              {label}:
            </label>
            <TextField
              id={label}
              size="small"
              tw="flex-1"
              multiline
              css={{
                ".MuiInputBase-input": tw`px-2 py-1 text-xs bg-white`,
                ".MuiInputBase-root": tw`p-0`,
              }}
              {...reg(label)}
            />
          </div>
        ))}
      </div>
    </div>
  );
};

export default SpecAccordion;
