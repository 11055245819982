/** @jsxImportSource @emotion/react */
import tw from "twin.macro";

import React, { useState } from "react";
import { useSelector } from "react-redux";

import { Close } from "@mui/icons-material";
import {
  Button,
  Checkbox,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  IconButton,
  List,
  ListItemButton,
  ListItemIcon,
  ListItemText,
} from "@mui/material";

import _ from "lodash";
import { StyledButton } from "src/components/StyledComponents";
import { ORDER_TO_NATIONAL_INVENTORY } from "src/constants/permissions";

import { ResourceError } from "@features/errors";
import { useTerritories } from "@features/territories";
import { Territory } from "@models/Territory";

import { useCurrentOrderSet } from "../../data/CurrentOrderSetContext";
import useAddRestockOrdersMutation from "../../data/mutations/useAddRestockOrders";
import SubStateSelection from "./SubStateSelection";

const NATIONAL = "national";
const NATIONAL_BRANDFUNDED = "national-brandfunded";

type RestockAllocationSelectionModalProps = {
  onClose: () => void;
};

const RestockAllocationSelectionModal: React.FC<
  RestockAllocationSelectionModalProps
> = ({ onClose }) => {
  const { orderSet } = useCurrentOrderSet();
  const addRestockOrders = useAddRestockOrdersMutation();
  const { role } = useSelector((state: any) => state.user);
  const [selectedTerritoryIds, setSelectedTerritoryIds] = useState<string[]>(
    []
  );
  const [selectedSubStateIds, setSelectedSubStateIds] = useState<string[]>([]);
  const orderSetTerritoryId = orderSet.territory?.id ?? "";
  const { data: territoriesData = [], error } = useTerritories({
    isActive: true,
    isAwaitingCode: false,
  });

  const territories = _.sortBy(territoriesData, "name");
  const { Regional: regional, Customer: customer } = Object.assign(
    {
      Regional: [] as Territory[],
      Customer: [] as Territory[],
    },
    _.groupBy(territories, "type")
  );

  const orderToNationalOptions = ORDER_TO_NATIONAL_INVENTORY.includes(role)
    ? [
        { id: NATIONAL, name: "National (no allocation)" } as Territory,
        {
          id: NATIONAL_BRANDFUNDED,
          name: "National, Brand Funded",
        } as Territory,
      ]
    : [];

  const customerOptions = _.sortBy(
    [...orderToNationalOptions, ...customer],
    (terr) => terr.id !== orderSetTerritoryId
  );

  const orderCount = selectedTerritoryIds.length + selectedSubStateIds.length;

  const handleToggleTerritory = (territory: Territory) => {
    setSelectedTerritoryIds((terrIds) => _.xor(terrIds, [territory.id]));
  };

  const handleCompleteAndClose = () => {
    const orderIds: {
      territoryId?: number;
      subStateId?: number;
      isBrandFunded?: boolean;
    }[] = selectedTerritoryIds.map((id) =>
      id.startsWith(NATIONAL)
        ? {
            isBrandFunded: id === NATIONAL_BRANDFUNDED,
          }
        : { territoryId: +id }
    );

    orderIds.push(...selectedSubStateIds.map((id) => ({ subStateId: +id })));

    addRestockOrders.mutate(orderIds, {
      onSuccess: () => onClose(),
      onError: (e) => console.error(e),
    });
  };

  if (error) {
    return <ResourceError error={error} />;
  }

  return (
    <Dialog open onClose={onClose} fullWidth maxWidth="lg">
      <DialogTitle tw="flex items-center justify-between">
        Select Allocations to Restock.
        <IconButton edge="end" onClick={onClose}>
          <Close />
        </IconButton>
      </DialogTitle>

      <DialogContent>
        <div tw="grid grid-cols-2">
          <h2 tw="text-lg mx-4 mb-2 text-neutral-600">
            Key Account Allocations
          </h2>
          <h2 tw="text-lg mx-4 mb-2 text-neutral-600">Regional Allocations</h2>
        </div>
        <div tw="grid grid-cols-2 divide-x">
          <div tw="max-h-[60vh] overflow-y-auto">
            <List>
              {customerOptions.map((territory) => (
                <ListItemButton
                  dense
                  key={territory.id}
                  onClick={() => handleToggleTerritory(territory)}
                  selected={selectedTerritoryIds.includes(territory.id)}
                >
                  <ListItemIcon>
                    <Checkbox
                      tw="-my-2"
                      checked={selectedTerritoryIds.includes(territory.id)}
                    />
                  </ListItemIcon>
                  <ListItemText
                    primary={
                      <span
                        css={[
                          ((!orderSetTerritoryId &&
                            territory.id.startsWith("national")) ||
                            territory.id === orderSetTerritoryId) &&
                            tw`font-medium text-blue-900`,
                        ]}
                      >
                        {territory.name}
                      </span>
                    }
                  />
                </ListItemButton>
              ))}
            </List>
          </div>

          <div tw="max-h-[60vh] overflow-y-auto relative">
            <SubStateSelection
              selection={selectedSubStateIds}
              setSelection={setSelectedSubStateIds}
              regionalTerritories={regional}
            />
          </div>
        </div>
      </DialogContent>
      <DialogActions tw="justify-between">
        <Button
          tw="ml-4 normal-case font-normal"
          color="secondary"
          onClick={() => {
            setSelectedTerritoryIds([]);
            setSelectedSubStateIds([]);
          }}
        >
          Reset Selection
        </Button>
        <div tw="flex gap-2">
          <StyledButton outlined onClick={onClose}>
            Cancel
          </StyledButton>
          <StyledButton
            cta
            onClick={handleCompleteAndClose}
            disabled={orderCount === 0}
            loading={addRestockOrders.isPending}
          >
            Create {orderCount || ""} Order{orderCount > 1 && "s"}
          </StyledButton>
        </div>
      </DialogActions>
    </Dialog>
  );
};

export default RestockAllocationSelectionModal;
