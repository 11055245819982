/** @jsxImportSource @emotion/react */
import "twin.macro";

import { useForm } from "react-hook-form";

import { Close } from "@mui/icons-material";
import { Dialog, DialogContent, DialogTitle, IconButton } from "@mui/material";

import {
  CheckboxInput,
  SelectInput,
  TextInput,
} from "src/components/Forms/ControlledInputs";
import { StyledButton } from "src/components/StyledComponents";
import { defaultFormValues, intValidation } from "src/utility/inputHelpers";

import { ItemType } from "@models/ItemType";

import { useUpdateItemTypeMutation } from "../data";

const categoryCodeOptions = [
  { label: "ADV", id: "ADV" },
  { label: "CAS", id: "CAS" },
  { label: "COU", id: "COU" },
  { label: "DSS", id: "DSS" },
  { label: "NEC", id: "NEC" },
  { label: "RAS", id: "RAS" },
  { label: "SDS", id: "SDS" },
  { label: "UNK", id: "UNK" },
];

const specTypeOptions = [
  { label: "None", id: "" },
  { label: "Description", id: "Description" },
  { label: "Spec", id: "Spec" },
];

const posTypeOptions = [
  { label: "Coupon", id: "Coupon" },
  { label: "Digital", id: "Digital" },
  { label: "Structure", id: "Structure" },
  { label: "Mass/Pallet Display", id: "Mass/Pallet Display" },
  { label: "Wearables", id: "Wearables" },
  { label: "On Premise", id: "On Premise" },
  { label: "Merch Vehicle", id: "Merch Vehicle" },
  { label: "Paper", id: "Paper" },
  { label: "MISC", id: "MISC" },
  { label: "Sign", id: "Sign" },
  { label: "Gift Box/Gift Bag", id: "Gift Box/Gift Bag" },
];

const formValuesFromItemType = (
  itemType: ItemType
): Record<keyof ItemType, string> =>
  defaultFormValues(itemType) as Record<keyof ItemType, string>;

const ItemTypeModal = ({
  handleClose,
  itemType,
}: {
  handleClose: () => void;
  itemType: ItemType;
}) => {
  const updateItemTypeMutation = useUpdateItemTypeMutation();

  const controlled = (name: keyof ItemType, rules?: any) => ({
    name,
    rules,
    control,
  });

  const { handleSubmit, control } = useForm({
    defaultValues: formValuesFromItemType(itemType),
  });

  const onSubmit = (data) => {
    updateItemTypeMutation.mutate(data, { onSuccess: () => handleClose() });
  };

  return (
    <Dialog open onClose={handleClose} fullWidth maxWidth="sm">
      <DialogTitle tw="flex items-center justify-between">
        {itemType ? `Editing ${itemType.description}` : "New Item Type"}
        <IconButton edge="end" onClick={handleClose}>
          <Close />
        </IconButton>
      </DialogTitle>

      <DialogContent>
        <form onSubmit={handleSubmit(onSubmit)} tw="pt-2 space-y-4">
          <TextInput
            label="Description"
            fullWidth
            {...controlled("description", { required: true })}
          />
          <TextInput
            label="Item Type Code"
            fullWidth
            {...controlled("externalId", { required: true })}
          />
          <TextInput
            label="Qty per Pack"
            fullWidth
            {...controlled("qtyPerPack", {
              required: true,
              ...intValidation,
            })}
          />
          <SelectInput
            label="Category Code"
            options={categoryCodeOptions as any}
            fullWidth
            {...controlled("categoryCode")}
          />
          <SelectInput
            label="Spec Type"
            options={specTypeOptions as any}
            fullWidth
            {...controlled("specType")}
          />
          <SelectInput
            label="POS Type"
            options={posTypeOptions as any}
            fullWidth
            {...controlled("posType")}
          />
          <TextInput
            label="Strategic Location"
            {...controlled("strategicLocation")}
            fullWidth
          />
          <TextInput
            label="Purpose for Item"
            {...controlled("purposeForItem")}
            fullWidth
          />
          <CheckboxInput
            label="RTA Deployment?"
            {...controlled("rtaDeployment")}
          />
          <CheckboxInput
            label="Send to Content Hub?"
            {...controlled("sendToContentHub")}
          />
          <StyledButton
            cta
            fullWidth
            onClick={handleSubmit(onSubmit)}
            loading={updateItemTypeMutation.isPending}
          >
            Save
          </StyledButton>
        </form>
      </DialogContent>
    </Dialog>
  );
};

export default ItemTypeModal;
