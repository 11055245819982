import { useDispatch } from "react-redux";

import { useMutation, useQueryClient } from "@tanstack/react-query";
import _ from "lodash";
import { setError } from "src/redux/slices/errorSlice";

import { Order } from "@models/Order";
import client from "@services/api";
import asyncPool from "@utils/asyncPool";

import { orderSetsKeyFactory } from "../../../queries/orderSetQueries";
import { useCurrentOrderSet } from "../CurrentOrderSetContext";

export default function useAddRestockOrders() {
  const dispatch = useDispatch();
  const queryClient = useQueryClient();
  const { orderSetId } = useCurrentOrderSet();

  return useMutation({
    mutationFn: (
      orderIds: {
        territoryId?: number;
        subStateId?: number;
        isBrandFunded?: boolean;
      }[]
    ) => {
      return asyncPool(
        5,
        orderIds,
        ({ territoryId, subStateId, isBrandFunded }) =>
          client
            .post<Order>("orders", {
              __type: "order",
              type: "to-inventory",
              ...(isBrandFunded && {
                isBrandFunded: true,
              }),
              orderSet: { type: "order-set", id: orderSetId },
              ...(territoryId && {
                territory: { type: "territory", id: territoryId },
              }),
              ...(subStateId && {
                subState: { type: "sub-state", id: subStateId },
              }),
              relationshipNames: [
                "address",
                "orderSet",
                "territory",
                "subState",
              ],
            })
            .then((res) => res.data)
      )
        .then((res) => {
          if (res.errors) throw res.errors[0];
          return res.results;
        })
        .then((res) => ({ newOrders: res }));
    },
    onSuccess: ({ newOrders }) => {
      queryClient.setQueryData(
        orderSetsKeyFactory.detail(orderSetId)._ctx.orders.queryKey,
        (orders: Order[]) => [...orders, ...newOrders]
      );
    },
    onError: (error) => {
      dispatch(
        setError({
          error: error.message,
          source: "useAddRestockOrders",
        })
      );
      queryClient.invalidateQueries({
        queryKey: orderSetsKeyFactory.detail(orderSetId)._ctx.orders.queryKey,
      });
    },
  });
}
