import { useCallback, useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";

import Autocomplete from "@mui/material/Autocomplete";
import CircularProgress from "@mui/material/CircularProgress";
import TextField from "@mui/material/TextField";

import PropTypes from "prop-types";

import { fetchAddresses } from "../../../redux/slices/addresses/addressSlice";

const AddressAutoComplete = ({
  classes,
  handleChange,
  reset,
  setReset,
  filterType,
}) => {
  const dispatch = useDispatch();
  const [address, setAddress] = useState("");
  const [currentAddresses, setCurrentAddresses] = useState([]);

  const isLoading = useSelector((state) => state.addresses.isLoading);
  const options = useSelector((state) => state.addresses.addressList);
  const territoryId = useSelector((state) => state.user.currentTerritory);
  const userStates = useSelector((state) => state.user.states);
  const currentFiltersAddress = useSelector((state) => state.filters.address);
  const isGlobalLoading = useSelector((state) => state.globalLoad.isLoading);

  const debounce = useRef(null);

  const handleQuery = useCallback(() => {
    clearTimeout(debounce.current);

    debounce.current = setTimeout(() => {
      dispatch(
        fetchAddresses(
          address,
          territoryId,
          userStates.map((state) => state.id).join(",")
        )
      );
    }, 250);
  }, [address, territoryId, userStates, dispatch]);

  const handleAddresses = (value) => {
    setCurrentAddresses(value);
  };

  useEffect(() => {
    if (address.length >= 1) {
      handleQuery();
    }
  }, [address, handleQuery, dispatch]);

  useEffect(() => {
    if (currentFiltersAddress.length !== currentAddresses.length) {
      setCurrentAddresses(currentFiltersAddress);
    }
  }, [currentFiltersAddress, currentAddresses.length]);

  useEffect(() => {
    if (reset && setReset) {
      if (reset) {
        setAddress("");
        setCurrentAddresses([]);
        setReset(false);
      }
    }
  }, [reset, setAddress, setReset]);

  return (
    <>
      <Autocomplete
        multiple
        autoHighlight
        renderTags={() => null}
        fullWidth
        className={classes.queryField}
        id="address-auto-complete"
        inputValue={address}
        onInputChange={(_evt, value) => setAddress(value)}
        onChange={(_evt, value) => {
          handleChange(value, "address", filterType);
          handleAddresses(value);
        }}
        isOptionEqualToValue={(option, value) => option.id === value.id}
        getOptionLabel={(option) =>
          `${
            option["distributorAbn"] && option["distributorAbn"] !== "---"
              ? option["distributorAbn"] + " - "
              : ""
          }${option.name}`
        }
        options={options}
        filterOptions={(options) => options}
        loading={isLoading}
        value={currentAddresses}
        disabled={isGlobalLoading}
        renderInput={(params) => (
          <TextField
            {...params}
            label="Name / ABN"
            variant="outlined"
            size="small"
            InputProps={{
              ...params.InputProps,
              endAdornment: (
                <>
                  {isLoading ? (
                    <CircularProgress color="inherit" size={15} />
                  ) : null}
                  {params.InputProps.endAdornment}
                </>
              ),
            }}
          />
        )}
      />
    </>
  );
};

AddressAutoComplete.propTypes = {
  classes: PropTypes.object.isRequired,
  handleChange: PropTypes.func.isRequired,
  reset: PropTypes.bool.isRequired,
  setReset: PropTypes.func.isRequired,
  filterType: PropTypes.string.isRequired,
};

export default AddressAutoComplete;
