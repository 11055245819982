import { useQuery } from "@tanstack/react-query";

import client from ".";
import { splitOptions } from "./helperFunctions";
import Routes from "./routeResourceTypes";
import { EntityType, Falsy, HookOptions } from "./types";

type metaType = {
  totalEntries: number;
};

// Can be used when data will not be mutated
const useApiResource = <R extends keyof Routes, T extends HookOptions>(
  resource: R | Falsy,
  options: T = {} as T
) => {
  const { requestOptions, queryOptions } = splitOptions(options);

  const url = options.id ? `${resource}/${options.id}` : resource;
  const { data, ...query } = useQuery({
    queryKey: [resource, options.id ?? "list", requestOptions],
    queryFn: () =>
      client.get<EntityType<Routes[R], T>>(url as string, requestOptions),
    enabled: !!url,
    ...queryOptions,
  });

  return {
    data: data?.data,
    ...query,
    isValidating: query.isFetching,
    meta: data?.meta as metaType,
  };
};

export default useApiResource;
