/** @jsxImportSource @emotion/react */
import "twin.macro";

import React, { useMemo } from "react";

import _ from "lodash";
import { SmartTable } from "src/components/SmartTable";

import { useSubStates } from "@features/states";
import { useTerritories } from "@features/territories";
import { VariantAllocation } from "@models/VariantAllocation";

import { allocationName } from "../helpers";

const parentTerritories = _.mapValues(
  {
    Atlantic: "DC NC PA SC VA",
    California: "CA",
    Control: "C_AL C_IA C_ID C_ME C_MT C_NC C_NH C_OH C_OR C_UT C_VA C_VT C_WV",
    International: "HK",
    "International - Canada": "AB BC MB NB NL NS NT NU ON PE QC SK YT",
    Lakes: "IL IN MI MN OH WI",
    Military: "AA AE AP",
    "Mega Central": "AL AR MS NM OK",
    "Mega East": "DE KY MD ME NH RI VT WV",
    "Mega West": "AK HI IA ID KS MO MT ND NE SD WY",
    Northeast: "CT MA NJ NY",
    Southeast: "FL GA TN",
    South: "AZ CO LA TX",
    West: "NV OR WA",
  },
  (v) => v.split(" ")
);

const VariantAllocationTable = ({
  variantAllocations,
  setSelectedAllocation,
  isFetching,
}: {
  variantAllocations: VariantAllocation[];
  setSelectedAllocation: (allocation: VariantAllocation) => void;
  isFetching: boolean;
}) => {
  const { data: subStates = [] } = useSubStates();
  const { data: territories = [] } = useTerritories({
    isAwaitingCode: false,
  });

  const territoriesById = useMemo(
    () => _.keyBy(territories, "id"),
    [territories]
  );

  const allocationsBySubStateId = useMemo(
    () => _.keyBy(variantAllocations, "subState.id"),
    [variantAllocations]
  );

  const allocationsBySubStateParent = _(parentTerritories)
    .mapValues((stateCodes) =>
      _.flatMap(stateCodes, (code) =>
        subStates
          .filter((subState) => subState.code.startsWith(code))
          .map((subState) => allocationsBySubStateId[subState.id])
          .filter(Boolean)
      )
    )
    .pickBy((allocs) => allocs.length > 0)
    .value();

  // Catch any allocations that don't fit into a subState grouping
  const otherSubStateAllocations = _(variantAllocations)
    .filter("subState")
    .xorBy(Object.values(allocationsBySubStateParent).flat(), "id")
    .value();

  if (otherSubStateAllocations.length > 0) {
    allocationsBySubStateParent["Other"] = otherSubStateAllocations;
  }
  // Merge territory allocations and subState groupings
  const rows = _([
    ...variantAllocations
      .filter((va) => va.territory) // territory allocations
      .map((va) => ({
        name: territoriesById[va.territory!.id]?.name,
        allocation: va,
        isSubState: false,
        children: [],
      })),
    ..._(allocationsBySubStateParent)
      .entries()
      .map(([name, allocations]) => ({
        name,
        allocation: null,
        isSubState: false,
        children: allocations.map((alloc) => ({
          name: allocationName(alloc),
          allocation: alloc,
          isSubState: true,
        })),
      }))
      .sortBy("name") // sort children separately
      .value(),
  ])
    .sortBy("name")
    .flatMap(({ children, ...row }) => [row, ...children])
    .value();

  return (
    <SmartTable
      rows={rows}
      noResultsText="There are currently no shelf inventory entries for the selected Sequence #."
      columns={[
        {
          id: "name",
          label: "Allocation",
          render: (name, row) =>
            row.isSubState ? (
              <span tw="pl-4">{name}</span>
            ) : (
              <span tw="font-bold">{name}</span>
            ),
        },
        { id: "allocation.qty", label: "Allocated Qty", align: "right" },
        {
          id: "allocation.availableToOrderQty",
          label: "Available Qty",
          align: "right",
        },
      ]}
      onRowClick={(row) =>
        row.allocation && setSelectedAllocation(row.allocation)
      }
      isPlaceholderData={isFetching}
      isFetching={isFetching}
    />
  );
};

export default VariantAllocationTable;
