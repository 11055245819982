import format from "date-fns/format";

import { mapItems } from "../items/maps";

const typeMap = {
  "prior-approval": "Prior Approval",
  "coupon-prior-approval": "Coupon Prior Approval",
  "internal-approval": "Internal Approval",
  "coupon-internal-approval": "Coupon Internal Approval",
  price: "Price",
  "item-type": "Item Type",
  material: "Material",
  "metal-wood": "Metal / Wood",
  "coupon-offer-type": "Coupon Offer Type",
  "coupon-item-type": "Coupon Item Type",
  "coupon-face-value": "Coupon Face Value",
};

export const mapOverrideRules = (item, overrides, rules) => {
  let itemData = mapItems([item])[0];

  let ruleData = rules.reduce((acc, rule) => {
    let ruleStates = [];
    rule.states.forEach((s) => {
      let row = {
        item: { ...itemData },
        itemId: itemData.id,
        ruleId: rule.id,
        ruleType: typeMap[rule.type],
        desc: rule.description,
        stateId: s.id,
        stateCode: s.code,
        overrideId: null,
        identifier: `${itemData.id}-${rule.id}-${s.id}`,
      };
      ruleStates.push(row);
    });

    return acc.concat(ruleStates);
  }, []);

  let overrideData = ruleData.map((r) => {
    let currentOverride = overrides.find(
      (o) =>
        +o["item-id"] === +item.id &&
        +o["rule-id"] === +r.ruleId &&
        +o["state-id"] === +r.stateId
    );

    if (currentOverride) {
      return {
        ...r,
        overrideId: currentOverride.id,
        overriddenBy: currentOverride["user-name"],
        overriddenAt: format(
          new Date(currentOverride["inserted-at"]),
          "MM/dd/yyyy"
        ),
        note: currentOverride.note ?? "---",
      };
    } else return r;
  });

  return overrideData;
};
