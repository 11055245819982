/** @jsxImportSource @emotion/react */
import "twin.macro";

import { useSelector } from "react-redux";

import { Card, Paper, Skeleton } from "@mui/material";

import _ from "lodash";
import { SmartTable } from "src/components/SmartTable";

import { ResourceError } from "@features/errors";
import { useFilterParams } from "@features/filters";
import { Type } from "@features/ui";
import { useApiResource } from "@services/api";

import OrderSetsWithNoBudgetsAlert from "./OrderSetsWithNoBudgetsAlert";
import OverBudgetByStateTable from "./OverBudgetByStateTable";
import { useOverBudgetBrandsQuery } from "./queries";

const OverBudgetByBrandTable = () => {
  const [filters] = useFilterParams();
  const { orderWindowId } = filters;
  const { currentChannel, currentTerritory } = useSelector(
    (state: any) => state.user
  );

  const { data: brands, ...tableProps } = useApiResource("brands", {
    filter: {
      hasSubmittedPreOrdersForChannel: currentChannel,
      hasSubmittedPreOrdersForTerritoryId: currentTerritory,
    },
    staleTime: 10 * 60 * 1000,
  });
  const params = {
    filter: {
      channel: currentChannel,
      brandIds: brands?.map((b) => +b.id),
      currentTerritory,
    },
  };
  const { data: overBudgetBrands, error: overBudgetBrandsError } =
    useOverBudgetBrandsQuery(params, {
      enabled: !!brands?.length,
    });

  const overBudgetLookup = _.keyBy(overBudgetBrands ?? [], (b) => String(b.id));

  const tableRows = _(brands ?? [])
    .map((brand) => ({
      ...brand,
      overBudgetCount: overBudgetLookup?.[brand.id]?.over_budget_count ?? null,
    }))
    .sortBy("name")
    .value();

  if (!orderWindowId) {
    return (
      <Card tw="p-6!">
        <Type.H2 tw="text-neutral-400">
          An active Order Window is required for the overview.
        </Type.H2>
      </Card>
    );
  }

  return (
    <>
      {overBudgetBrandsError && <ResourceError error={overBudgetBrandsError} />}
      <SmartTable
        columns={[
          { id: "name", label: "Brand" },
          {
            id: "overBudgetCount",
            label: "Over Budget",
            align: "right",
            render: (count) =>
              count === null ? (
                <Skeleton
                  variant="text"
                  tw="inline-block"
                  height={24}
                  width={32}
                />
              ) : count === 0 ? (
                <span tw="py-1 px-3 rounded bg-neutral-100 text-neutral-700">
                  OK
                </span>
              ) : (
                <span tw="py-1 px-3 rounded bg-red-50 text-red-900">{`${count} Over Budget`}</span>
              ),
          },
        ]}
        rows={tableRows}
        collapsibleContent={(row) => (
          <div tw="space-y-2">
            <OrderSetsWithNoBudgetsAlert brand={row} />
            <Paper tw="overflow-hidden">
              <OverBudgetByStateTable brandId={row.id} ocmId={orderWindowId} />
            </Paper>
          </div>
        )}
        {...tableProps}
      />
    </>
  );
};

export default OverBudgetByBrandTable;
