import axios from "axios";
import Jsona from "jsona";

import { captureAxiosException } from "@services/sentry";

import { handleErrors } from "./apiFunctions";

export const dataFormatter = new Jsona();

const writeHeaders = {
  headers: {
    Accept: "application/vnd.api+json",
    "Content-Type": "application/vnd.api+json",
  },
};

/**
 * Use this method when using the axios calls below with async pools/promise.all.
 * Will actually throw an error rather than catching and continuing the promise pool.
 */

axios.interceptors.response.use(
  (res) => res,
  (error) => {
    captureAxiosException(error);
    return Promise.reject(error);
  }
);

export const axiosGet = async (url) => {
  const response = {
    status: "",
    error: null,
    data: null,
    orderLink: null,
  };
  await axios
    .get(url)
    .then((res) => {
      let data = dataFormatter.deserialize(res.data);
      response.status = "ok";
      response.data = data;
      response.orderLink = res?.data?.data?.relationships?.orders?.links
        ?.related
        ? res?.data?.data?.relationships?.orders?.links?.related
        : res?.data?.data?.[0]?.relationships?.orders?.links?.related
        ? res?.data?.data?.[0]?.relationships?.orders?.links?.related
        : null;
    })
    .catch((err) => {
      console.error(err);
      const error = handleErrors(err);
      response.status = "error";
      response.error = error;
    });
  return response;
};

export const axiosGetWithNext = async (url) => {
  const response = { status: "", error: null, data: null };
  await axios
    .get(url)
    .then((res) => {
      let dataObject = { data: null, totalEntries: null, nextLink: null };
      let data = dataFormatter.deserialize(res.data);
      dataObject.data = data;
      dataObject.totalEntries = res.data.meta
        ? res.data.meta.total_entries
        : null;
      dataObject.nextLink = res.data.links ? res.data.links.next : null;
      response.status = "ok";
      response.data = dataObject;
    })
    .catch((err) => {
      const error = handleErrors(err);
      console.log(error);
      response.status = "error";
      response.error = error;
    });
  return response;
};

export const axiosGetNoFormat = async (url, opts) => {
  const response = { status: "", error: null, data: null };
  await axios
    .get(url, opts)
    .then((res) => {
      let data = res.data;
      response.status = "ok";
      response.data = data;
    })
    .catch((err) => {
      console.error(err);
      const error = handleErrors(err);
      response.status = "error";
      response.error = error;
    });
  return response;
};

export const axiosPatch = async (url, patchData) => {
  const response = { status: "", error: null, data: null };
  await axios
    .patch(url, patchData, writeHeaders)
    .then((res) => {
      let data = dataFormatter.deserialize(res.data);
      response.status = "ok";
      response.data = data;
    })
    .catch((err) => {
      const error = handleErrors(err);
      console.log(error);
      response.status = "error";
      response.error = error;
    });
  return response;
};

export const axiosPut = async (url, putData, additionalHeaders) => {
  const response = { status: "", error: null, data: null };
  await axios
    .put(url, putData, { ...writeHeaders, ...additionalHeaders })
    .then((res) => {
      let data = dataFormatter.deserialize(res.data);
      response.data = data;
      response.status = "ok";
    })
    .catch((err) => {
      const error = handleErrors(err);
      console.log(error);
      response.status = "error";
      response.error = error;
    });
  return response;
};

export const axiosPost = async (url, postData, options) => {
  const { rawErrors, ...otherHeaders } = options ?? {};
  const response = { status: "", error: null, data: null };
  await axios
    .post(url, postData, { ...writeHeaders, ...otherHeaders })
    .then((res) => {
      let data;
      if (typeof res.data === "string") {
        data = res.data;
      } else {
        data = dataFormatter.deserialize(res.data);
      }
      response.status = "ok";
      response.data = data;
    })
    .catch((err) => {
      if (rawErrors) {
        response.error = err;
      } else {
        const error = handleErrors(err);
        response.status = "error";
        response.error = error;
      }
    });
  return response;
};

export const axiosDelete = async (url, deleteData) => {
  const response = { status: "", error: null };
  await axios
    .delete(url, deleteData, writeHeaders)
    .then((_) => {
      response.status = "ok";
    })
    .catch((err) => {
      const error = handleErrors(err);
      console.log(error);
      response.status = "error";
      response.error = error;
    });
  return response;
};
