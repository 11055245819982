/** @jsxImportSource @emotion/react */
import "twin.macro";

import { Close } from "@mui/icons-material";
import { DialogTitle } from "@mui/material";
import CircularProgress from "@mui/material/CircularProgress";
import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import IconButton from "@mui/material/IconButton";

import { ResourceError } from "@features/errors";

import useImperialPunchoutUrlQuery from "./useImperialPunchoutUrlQuery";

const TemplatedCouponModal = ({
  handleClose,
  programId,
}: {
  handleClose: () => void;
  programId: string;
}) => {
  const {
    data: iframeUrl,
    error,
    isLoading,
  } = useImperialPunchoutUrlQuery(programId);

  return (
    <Dialog open onClose={handleClose} fullWidth maxWidth="lg">
      <DialogTitle tw="flex items-baseline justify-between">
        Templated Coupon Wizard
        <IconButton onClick={handleClose}>
          <Close />
        </IconButton>
      </DialogTitle>
      <DialogContent>
        <p tw="mb-4 text-neutral-600">
          Complete the wizard to create the custom coupon. Closing the modal
          will cause the wizard to start over.
        </p>
        {error && <ResourceError error={error} />}
        {isLoading && <CircularProgress />}
        {iframeUrl && (
          <iframe
            src={iframeUrl}
            title="Create a Coupon"
            tw="block w-full h-[67vh]"
          />
        )}
      </DialogContent>
    </Dialog>
  );
};

export default TemplatedCouponModal;
