import React from "react";

import cld from ".";

const CldVideo = ({ cloudinaryId }: { cloudinaryId: string }) => {
  const video = cld.videoTag(cloudinaryId, { controls: true }).toHtml();
  return <div dangerouslySetInnerHTML={{ __html: video }} />;
};

export default CldVideo;
