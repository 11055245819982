/** @jsxImportSource @emotion/react */
import tw from "twin.macro";

import React from "react";

import { ExpandMore } from "@mui/icons-material";
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Card,
} from "@mui/material";

import { BackButton } from "@features/ui";

import ExportOrderSetButton from "../orderSet/ExportOrderSetButton";
import PreOrderProgramSelector from "./PreOrderProgramSelector";
import PreOrderProgramsSummary from "./PreOrderProgramsSummary";

const PreOrderHeader = () => {
  return (
    <Card>
      <Accordion component={Card} tw="rounded-xl!">
        <AccordionSummary
          expandIcon={<ExpandMore />}
          css={{
            ".MuiAccordionSummary-content": tw`min-w-0`,
          }}
        >
          <div tw="flex-1 flex justify-between items-center mr-3 gap-3 min-w-0">
            <div tw="flex flex-1 gap-2 items-center min-w-0">
              <BackButton defaultUrl="/programs/national" />
              <PreOrderProgramSelector />
            </div>
            <ExportOrderSetButton />
          </div>
        </AccordionSummary>
        <AccordionDetails>
          <PreOrderProgramsSummary />
        </AccordionDetails>
      </Accordion>
    </Card>
  );
};

export default PreOrderHeader;
