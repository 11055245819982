/** @jsxImportSource @emotion/react */
import "twin.macro";

import { useState } from "react";

import { Button } from "@features/ui";

import RestockAllocationSelectionModal from "./restockModal";

const RestockOrdersButton = (props: { disabled: boolean }) => {
  const [restockModalOpen, setRestockModalOpen] = useState(false);
  return (
    <>
      {restockModalOpen && (
        <RestockAllocationSelectionModal
          onClose={() => setRestockModalOpen(false)}
        />
      )}
      <Button
        tw="whitespace-nowrap"
        fullWidth
        variant="contained"
        color="secondary"
        onClick={() => setRestockModalOpen(true)}
        {...props}
      >
        Re-stock Orders
      </Button>
    </>
  );
};
export default RestockOrdersButton;
