/** @jsxImportSource @emotion/react */
import "twin.macro";

import { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { useLocation, useParams } from "react-router-dom";

import { CircularProgress } from "@mui/material";

import { Contained } from "src/components/StyledComponents";
import { setFiltersOpen } from "src/redux/slices/filterSlice";

import {
  OrderSetActionBar,
  OrderSetOverview,
  PreOrderHeader,
} from "@features/orders";
import OrderSetTable from "@features/orders/orderSet/OrderSetTable";
import { CurrentOrderSetContextProvider } from "@features/orders/orderSet/data/CurrentOrderSetContext";
import OrderSetId from "@features/orders/orderSet/data/OrderSetIDContext";
import { OrderSetBudgetImpactAccordion } from "@features/orders/orderSetOverview/OrderSetBudgetImpact";
import PreOrderProgramInit from "@features/orders/preOrder/PreOrderProgramInit";
import useOrderWindowPrograms from "@features/orders/preOrder/usePreOrderPrograms";
import { Type } from "@features/ui";
import DocTitle from "@utils/DocTitle";

const PreOrder = () => {
  const dispatch = useDispatch();
  const { hash } = useLocation();

  const { programId, orderWindowId } = useParams();

  const { data, orderSetQuery, programsQuery } = useOrderWindowPrograms(
    orderWindowId as string
  );

  const selected = data.find(({ program }) => program.id === programId);

  const [orderSetId, setOrderSetId] = useState<null | string>(null);
  const osIdFromSelected = selected?.orderSet?.id ?? null;

  const onOrderSetDelete = () => {};

  useEffect(() => {
    dispatch(setFiltersOpen({ open: false }));
  }, [dispatch]);

  useEffect(() => {
    setOrderSetId(osIdFromSelected);
  }, [osIdFromSelected]);

  if (programsQuery.isPending) return <CircularProgress />;

  return (
    <OrderSetId.Provider value={orderSetId}>
      <DocTitle
        title={`Pre-Order${selected ? ` | ${selected.program.name}` : ""}`}
      />
      <div>
        <Contained tw="space-y-6">
          <PreOrderHeader />
          {programsQuery.isLoading && <CircularProgress tw="m-6" />}
          {selected && !(osIdFromSelected || orderSetId) && (
            <div tw="rounded-lg bg-neutral-100 px-4 py-8">
              {(orderSetQuery.isLoading || orderSetQuery.hasNextPage) && (
                <div tw="text-xl text-neutral-700 mb-3">Loading Order Set</div>
              )}
              {!orderSetQuery.isLoading && !orderSetQuery.hasNextPage && (
                <PreOrderProgramInit
                  programId={programId!}
                  orderWindowId={orderWindowId!}
                  onSuccess={(data) => setOrderSetId(data.id)}
                />
              )}
            </div>
          )}
          {!programsQuery.isLoading && !selected && (
            <div tw="rounded-lg bg-neutral-100 p-6">
              <Type.H2>
                The program you've selected isn't available on this order window
              </Type.H2>
            </div>
          )}

          {orderSetId && (
            <CurrentOrderSetContextProvider onOrderSetDelete={onOrderSetDelete}>
              {hash === "#overview" ? (
                <OrderSetOverview />
              ) : (
                <>
                  <OrderSetBudgetImpactAccordion />
                  <OrderSetTable />
                  <OrderSetActionBar />
                </>
              )}
            </CurrentOrderSetContextProvider>
          )}
        </Contained>
      </div>
    </OrderSetId.Provider>
  );
};

export default PreOrder;
