/** @jsxImportSource @emotion/react */
import "twin.macro";

import React, { useEffect } from "react";

import _ from "lodash";

import { useAuthUser } from "@features/auth";
import { useOnDemandNationalCouponPrograms } from "@features/programs";

import ControlledResourceFilterAutocomplete from "../components/ControlledResourceFilterAutocomplete";
import ResourceFilterChip from "../components/ResourceFilterChip";
import { Bordered } from "../components/StyledComponents";
import { useFilterContext } from "../filterContext";

const NATIONAL_COUPON_PROGRAM_TYPE_ID = 4;

const Popper = () => {
  const { control } = useFilterContext();
  const { channel } = useAuthUser();
  return (
    <ControlledResourceFilterAutocomplete
      skipsPagination
      name="programs"
      resource="programs"
      inputPlaceholder="Filter Programs"
      requestParams={{
        filter: {
          programTypeIds: [NATIONAL_COUPON_PROGRAM_TYPE_ID],
          isOrderableForOnDemand: true,
          isPreOrder: false,
          channel,
        },
      }}
      control={control}
    />
  );
};

const Chip = (props) => {
  const { filterValues, setValue, submitFilters } = useFilterContext();
  const programs = useOnDemandNationalCouponPrograms();
  const selectedPrograms = filterValues.programs ?? [];

  useEffect(() => {
    if (!filterValues.programs) return;
    if (filterValues.programs.some((v) => !programs.find((p) => p.id === v))) {
      setValue("programs", []);
      submitFilters();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  if (programs.length === 0 && selectedPrograms.length === 0)
    return (
      <Bordered tw="px-4 bg-neutral-200 text-neutral-500">
        No Coupon Programs
      </Bordered>
    );

  const hasNonCouponPrograms =
    selectedPrograms.length > programs.length ||
    programs.some((p) => !selectedPrograms.includes(p.id));

  const resourceFilterChipProps = hasNonCouponPrograms
    ? { resource: "programs" }
    : { options: programs };

  return (
    <ResourceFilterChip
      {...resourceFilterChipProps}
      ids={selectedPrograms}
      prefix="Program is"
      contentWhenEmpty="Select Coupon Program"
      {...props}
    />
  );
};

const brandsFilter = {
  name: "couponPrograms",
  formControls: ["programs"],
  menuText: "Coupon Programs",
  Popper: Popper,
  Chip: Chip,
  alwaysShow: true,
};

export default brandsFilter;
