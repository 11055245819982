/** @jsxImportSource @emotion/react */
import "twin.macro";

import Checkbox from "@mui/material/Checkbox";
import Divider from "@mui/material/Divider";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import Typography from "@mui/material/Typography";
import makeStyles from "@mui/styles/makeStyles";

import _ from "lodash";

import { Contained } from "@components/StyledComponents";
import { useAuthUser } from "@features/auth";
import { PageTitle } from "@features/ui";

const useStyles = makeStyles((theme: any) => ({
  ...theme.global,
}));

const ProfileGeneral = () => {
  const classes: any = useStyles();

  const user = useAuthUser();

  return (
    <Contained>
      <PageTitle title="Profile Information" />
      <br />
      <List classes={{ root: classes.root }}>
        <ListItem disableGutters>
          <Typography className={classes.bodyText}>
            Name: {user.name}
          </Typography>
        </ListItem>
        <Divider />
        <ListItem disableGutters>
          <Typography className={classes.bodyText}>
            Email: {user.email}
          </Typography>
        </ListItem>
        <Divider />
        <ListItem disableGutters>
          <Typography className={classes.bodyText}>
            Role: {_.startCase(user.role)}
          </Typography>
        </ListItem>
        <Divider />
        <ListItem
          disableGutters
          style={{
            display: "flex",
            flexDirection: "column",
            alignItems: "flex-start",
          }}
        >
          <Typography className={classes.bodyText}>
            Assigned Territories:
          </Typography>
          <br />
          <ul tw="columns-3 md:columns-4 lg:columns-5 xl:columns-6">
            {_.sortBy(user.territories, "name").map((terr) => (
              <Typography
                className={classes.bodyText}
                component="li"
                key={terr.id}
              >
                {terr.name}
              </Typography>
            ))}
          </ul>
        </ListItem>
        <Divider />
        <ListItem
          disableGutters
          style={{
            display: "flex",
            flexDirection: "column",
            alignItems: "flex-start",
          }}
        >
          <Typography className={classes.bodyText}>Assigned States:</Typography>
          <br />
          <Typography className={classes.bodyText}>
            {user.states.map((state) => state.code).join(", ")}
          </Typography>
        </ListItem>
        <Divider />
        <ListItem disableGutters>
          <div style={{ display: "flex", flexDirection: "row" }}>
            <Typography className={classes.bodyText}>
              On Premise:
              <Checkbox disabled checked={user.isOnPremise ? true : false} />
            </Typography>
            <Typography className={classes.bodyText}>
              Retail:
              <Checkbox disabled checked={user.isRetail ? true : false} />
            </Typography>
          </div>
        </ListItem>
      </List>
    </Contained>
  );
};

export default ProfileGeneral;
