import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";

import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import Accordion from "@mui/material/Accordion";
import AccordionDetails from "@mui/material/AccordionDetails";
import AccordionSummary from "@mui/material/AccordionSummary";
import Button from "@mui/material/Button";
import CircularProgress from "@mui/material/CircularProgress";
import Typography from "@mui/material/Typography";
import makeStyles from "@mui/styles/makeStyles";

import {
  fetchFavAddresses,
  newFavoriteAddressList,
} from "../../redux/slices/addresses/addressSlice";
import FavoriteAddressList from "./FavoriteAddressList";

const useStyles = makeStyles((theme) => ({
  ...theme.global,
}));
const FavoriteAddresses = () => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const [lists, setLists] = useState(null);
  const [currentList, setCurrentList] = useState(false);

  const currentAddressLists = useSelector(
    (state) => state.addresses.favoriteAddressList
  );
  const isFavListLoading = useSelector(
    (state) => state.addresses.isFavListLoading
  );
  const currentTerritory = useSelector((state) => state.user.currentTerritory);

  const handleExpand = (id) => (evt, isExpanded) => {
    setCurrentList(isExpanded ? id : false);
  };

  useEffect(() => {
    if (
      (!lists && currentAddressLists.length > 0) ||
      (lists &&
        lists.length !== currentAddressLists.length &&
        currentAddressLists.length > 0)
    ) {
      setLists(currentAddressLists);
      setCurrentList(currentAddressLists[currentAddressLists.length - 1].id);
    }
  }, [lists, currentAddressLists]);

  useEffect(() => {
    dispatch(fetchFavAddresses(currentTerritory));
  }, [dispatch, currentTerritory]);

  return (
    <>
      {currentAddressLists.length > 0 && (
        <div>
          {currentAddressLists.map((distList) => (
            <Accordion
              key={distList.id}
              expanded={currentList === distList.id}
              onChange={handleExpand(distList.id)}
              elevation={1}
            >
              <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls={distList.name}
                id={`${distList.name}-${distList.id}`}
              >
                <Typography className={classes.headerText}>
                  {distList.name}
                </Typography>
              </AccordionSummary>
              <AccordionDetails style={{ width: "100%" }}>
                <FavoriteAddressList id={distList.id} />
              </AccordionDetails>
            </Accordion>
          ))}
        </div>
      )}
      {isFavListLoading && <CircularProgress />}
      {!isFavListLoading && currentAddressLists.length === 0 && (
        <Typography
          className={classes.headerText}
          style={{ marginBottom: "20px" }}
        >
          You currently do not have any Favorite Address Lists....
        </Typography>
      )}
      <Button
        className={classes.largeButton}
        variant="contained"
        color="secondary"
        onClick={() => {
          dispatch(
            newFavoriteAddressList(currentAddressLists.length, currentTerritory)
          );
        }}
      >
        ADD NEW LIST
      </Button>
    </>
  );
};

export default FavoriteAddresses;
