/** @jsxImportSource @emotion/react */
import "twin.macro";
import tw from "twin.macro";

import { useState } from "react";
import { Link } from "react-router-dom";

import { format } from "date-fns";
import _, { capitalize } from "lodash";
import {
  formatDateString,
  formatMoneyString,
  utcDate,
} from "src/utility/utilityFunctions";

import { insertIf, showFirst } from "@components/SmartTable";
import SmartTable, {
  PaginatedResourceProps,
} from "@components/SmartTable/SmartTable";
import ItemPreviewImageButton from "@features/items/components/ItemPreviewImageButton";
import { PaperTooltip } from "@features/ui";
import { OrderVariant } from "@models/OrderVariant";
import { Variant } from "@models/Variant";
import useRoleIs from "@utils/useRoleIs";

import {
  BillableBeaconCost,
  BillableItemCost,
  BillableShippingCost,
  BillableTaxCost,
} from "../BillableCosts";
import {
  getExtendedCost,
  getVariantName,
  handleComplianceStatus,
  orderTypeLabel,
} from "../helpers";
import OrderVariantTrackingModal from "./OrderVariantTrackingModal";

type OrderHistoryVariantTableProps = PaginatedResourceProps & {
  data: OrderVariant[];
  tableRef: React.MutableRefObject<any>;
};

const monthFromDateString = (dateString) =>
  dateString && format(utcDate(dateString), "MMMM");

const OrderHistoryVariantTable = ({
  data,
  ...props
}: OrderHistoryVariantTableProps) => {
  const [trackingVariant, setTrackingVariant] = useState<null | OrderVariant>(
    null
  );
  const roleIs = useRoleIs();
  return (
    <>
      {trackingVariant && (
        <OrderVariantTrackingModal
          onClose={() => setTrackingVariant(null)}
          orderVariant={trackingVariant}
        />
      )}
      <SmartTable
        rows={data}
        {...props}
        rowCss={(ov) =>
          ov.status === "canceled" && tw`bg-red-50 hover:bg-red-100`
        }
        columns={[
          {
            id: "variant.item",
            label: "Preview",
            render: (item) => <ItemPreviewImageButton item={item} />,
          },
          {
            id: "variant",
            label: "Item",
            sort: "item-number",
            render: (variant: Variant) => (
              <div>
                <div tw="text-neutral-600 font-medium tracking-wide">
                  {variant.variantSku}
                </div>

                {variant.item.comment}
                {variant.selectedVariantOptions.length > 0 && (
                  <div tw="text-sm text-neutral-500">
                    {getVariantName(variant)}
                  </div>
                )}
              </div>
            ),
          },
          {
            id: "_typeOrWindow",
            label: "Order Type / Window",
            render: (__, ov) =>
              ov.orderType === "pre-order"
                ? ov.orderWindowName ?? orderTypeLabel(ov.orderType)
                : orderTypeLabel(ov.orderType),
          },
          {
            id: "_inMarketMonth",
            label: "In Market Month",
            render: (_, ov) =>
              monthFromDateString(ov.inMarketDate) ??
              monthFromDateString(ov.poInMarketDate) ?? (
                <span tw="text-neutral-400">Pending</span>
              ),
          },
          {
            id: "order.id",
            sort: "id", // translated to "order-id"
            label: "Order #",
            render: (id) => (
              <Link
                tw="underline font-medium tracking-wide"
                to={`/orders/history/single-order/${id}`}
              >
                {id}
              </Link>
            ),
          },
          {
            id: "anaplanSubProgramCodes",
            label: "12 Digit Code(s)",
            render: (codes) => codes.join(", "),
          },
          {
            id: "anaplanProgramTypes",
            label: "Anaplan Program Types",
            render: (types) => types.join(", "),
          },
          ...insertIf(
            roleIs([
              "super",
              "purchaser",
              "select-purchaser",
              "read-only",
              "compliance",
            ]),
            { id: "poId", label: "PO #" }
          ),
          { id: "orderUserName", label: "Ordered By" },
          {
            id: "variant.item.brands",
            label: "Brand",
            sort: "brand",
            render: (brands) => showFirst(brands.map((b) => b.name)),
          },
          {
            id: "_businessUnitNames",
            label: "BU",
            render: (__, ov) =>
              _(ov.variant.item.brands).map("businessUnit.name").join(", "),
          },
          { id: "programNames", label: "Program", render: showFirst },
          {
            id: "itemTypeDescription",
            label: "Item Type",
            sort: "item-type-description",
          },
          {
            id: "addressName",
            label: "Distributor / Name",
            sort: "address-name",
          },
          { id: "addressAbn", label: "ABN" },
          { id: "state", label: "State" },
          { id: "territoryName", label: "Territory" },
          { id: "qty", label: "Qty" },
          {
            id: "estimatedCost",
            label: "Est. Cost/Unit",
            align: "right",
            render: (v) => formatMoneyString(v),
          },
          {
            id: "_perUnitActualCost",
            label: "Actual Cost/Unit",
            align: "right",
            render: (_, ov) =>
              formatMoneyString(+ov.totalActualCost / (ov.qty || 1)),
          },
          {
            id: "_totalProductCost",
            label: "Total Product Cost",
            align: "right",
            render: (_, ov) => (
              <BillableItemCost costs={ov} orderType={ov.orderType} />
            ),
          },
          {
            id: "totalEstimatedShippingCost",
            label: "Est. Freight",
            align: "right",
            render: (_, ov) => (
              <BillableShippingCost costs={ov} orderType={ov.orderType} />
            ),
          },
          {
            id: "totalEstimatedTax",
            label: "Est. Tax",
            align: "right",
            render: (_, ov) => (
              <BillableTaxCost costs={ov} orderType={ov.orderType} />
            ),
          },
          {
            id: "totalBeaconCost",
            label: "Total Beacon Cost",
            align: "right",
            render: (_, ov) => (
              <BillableBeaconCost costs={ov} orderType={ov.orderType} />
            ),
          },
          {
            id: "_extendedTotal",
            label: "Est. Total Cost",
            align: "right",
            render: (_, ov) =>
              formatMoneyString(getExtendedCost(ov, ov.orderType)),
          },
          {
            id: "orderSubmittedAt",
            label: "Order Date",
            sort: "submitted-at", // translated to "order-submitted-at"
            render: (date) => format(new Date(date), "MM/dd/yyyy"),
          },
          {
            id: "_shipDateOrInMarketDate",
            label: "In Market Date",
            sort: "in-market-date",
            render: (_, ov) =>
              formatDateString(ov.poInMarketDate ?? ov.inMarketDate),
          },
          {
            id: "_actualShipDate",
            label: "Act. Ship Date",
            sort: "shipped-at", // translated to "order-shipped-at"
            render: (_, ov) =>
              formatDateString(
                ov.shippingParameterVariant?.actualShipDate ?? ov.actualShipDate
              ),
          },
          {
            id: "trackingData",
            label: "Tracking",
            render: (d: OrderVariant["trackingData"], ov) =>
              ov.isTransferred
                ? "Transferred qtys from other territories"
                : d && (
                    <div>
                      <div tw="uppercase tracking-wide">{d.carrier}</div>
                      <button
                        onClick={() => setTrackingVariant(ov)}
                        tw="underline text-left"
                      >
                        {d.number}
                      </button>
                    </div>
                  ),
          },
          {
            id: "_status",
            label: "Status",
            render: (_s, ov) =>
              ov.status === "canceled" ? (
                <div tw="text-red-900 whitespace-nowrap">
                  <span>
                    {ov.cancelationType === "compliance"
                      ? "Compliance Canceled"
                      : "Canceled"}
                  </span>
                  {ov.cancelationNote && (
                    <PaperTooltip title={ov.cancelationNote}>
                      <span tw="underline pl-2">(note)</span>
                    </PaperTooltip>
                  )}
                </div>
              ) : (
                _.capitalize(ov.orderStatus)
              ),
          },
          {
            id: "_compliance",
            label: "Compliance Status",
            render: (_, ov) => {
              const rules = [
                ...ov.triggeredRules,
                ...ov.priorApprovalTriggeredRules,
              ];
              const complianceStatus = handleComplianceStatus(rules);
              return (
                <span>
                  {complianceStatus.includes("Overridden") && rules.length > 0
                    ? `(${rules
                        .map((ruleObj) => capitalize(ruleObj.ruleType))
                        .join(", ")}) `
                    : ""}
                  {complianceStatus}
                </span>
              );
            },
          },
          {
            id: "includeBeacon",
            label: "Beacon",
            render: (includeBeacon) => (includeBeacon ? "Yes" : "No"),
          },
          { id: "approvalLogging", label: "Approval Logs" },
        ]}
      />
    </>
  );
};

export default OrderHistoryVariantTable;
