import { useEffect, useState } from "react";
import { useSelector } from "react-redux";

import {
  availableInventoryHeaders,
  cdcShipListHeaders,
  cdcSummaryHeaders,
  cdcTransferAdviceHeaders,
  customCouponHeaders,
  financeBillingHeaders,
  financeJournalHeaders,
  financeTrackerHeaders,
  focusDetailHeaders,
  focusSummaryHeaders,
  inventoryHeaders,
  invoiceDetailHeaders,
  invoiceDoubleCheckHeaders,
  invoicingHeaders,
  openPurchaseOrdersHeaders,
  orderDetailHeaders,
  orderWindowItemsHeaders,
  orderedItemsHeaders,
  poComplianceHeaders,
  preOrderNonParticipantHeaders,
  printInvoiceHeaders,
  purchaseOrderReportHeaders,
  rfpSummaryHeaders,
  roadmapForecastHeaders,
  supplierDetailHeaders,
  supplierSummaryHeaders,
} from "./csvHeaders";

const reportTypeMap = {
  "finance-tracker": financeTrackerHeaders,
  "order-window-summary": focusSummaryHeaders,
  "order-window-detail": focusDetailHeaders,
  "rfp-summary": rfpSummaryHeaders,
  "po-compliance": poComplianceHeaders,
  "available-inventory": availableInventoryHeaders,
  inventory: inventoryHeaders,
  coupon: customCouponHeaders,
  "inbound-item-summary": cdcSummaryHeaders,
  "ship-list": cdcShipListHeaders,
  "transfer-advice": cdcTransferAdviceHeaders,
  "roadmap-forecast": roadmapForecastHeaders,
  "supplier-summary": supplierSummaryHeaders,
  "supplier-detail": supplierDetailHeaders,
  "finance-billing": financeBillingHeaders,
  "finance-journal": financeJournalHeaders,
  "ordered-items": orderedItemsHeaders,
  invoicing: invoicingHeaders,
  "invoice-double-check": invoiceDoubleCheckHeaders,
  "invoice-detail": invoiceDetailHeaders,
  "print-invoice": printInvoiceHeaders,
  "order-window-items": orderWindowItemsHeaders,
  "order-detail": orderDetailHeaders,
  "open-purchase-orders": openPurchaseOrdersHeaders,
  "purchase-order-report": purchaseOrderReportHeaders,
  "pre-order-non-participant": preOrderNonParticipantHeaders,
};

export const useReportCsv = (
  type,
  hasGenerated,
  setHasGenerated,
  isLoading,
  report
) => {
  const [csvData, setCsvData] = useState({ data: [], headers: [] });
  const currentSuppliers = useSelector((state) => state.suppliers.supplierList);
  const reportType = useSelector((state) => state.reports.reportType);

  useEffect(() => {
    if (
      ((hasGenerated && !isLoading && report.length > 0) ||
        (report.length > 0 && csvData.data.length === 0)) &&
      reportType === type
    ) {
      let headers = reportTypeMap[type];
      let data = [];
      report.forEach((repItem) => {
        let dataObject = {
          ...repItem,
        };
        data.push(dataObject);
      });
      setCsvData({ data: data, headers: headers });
      setHasGenerated(false);
    }
  }, [
    csvData,
    currentSuppliers,
    reportType,
    hasGenerated,
    isLoading,
    report,
    setHasGenerated,
    type,
  ]);

  return {
    setCsvData: setCsvData,
    data: csvData.data,
    headers: csvData.headers,
  };
};
