import { useEffect, useState } from "react";
import { useForm } from "react-hook-form";

import { InputAdornment } from "@mui/material";

import _ from "lodash";

import { sorted } from "./utilityFunctions";

// Use with react-hook-form's useForm() to handle errors as you register the input
const resolveObjPath = (path, obj, separator = ".") => {
  const properties = Array.isArray(path) ? path : path.split(separator);
  return properties.reduce((prev, curr) => prev && prev[curr], obj);
};

// Defaults null values to "", keep booleans, convert numbers to strings
export const defaultFormValues = (obj) =>
  Object.entries(obj).reduce((o, [key, value]) => {
    o[key] =
      value === null || value === undefined
        ? ""
        : typeof value === "number"
        ? String(value)
        : _.isArray(value)
        ? sorted(value)
        : value;
    return o;
  }, {});

export const transformEventValue = (e, fn) => {
  const _e = { ...e };
  _e.target.value = fn(_e.target.value);
  return _e;
};

export const useCustomRegister = (register, errors, options) => (name, ops) => {
  const { onBlur: globalOnBlur } = options || {};
  const { transform, transformOnBlur, ...checks } = ops || {};

  const { onChange, onBlur, ...registeredProps } = register(name, checks);

  const handleOnBlur = (e) => {
    const _e = transformOnBlur ? transformEventValue(e, transformOnBlur) : e;
    transformOnBlur && onChange(_e); // trigger value change
    onBlur && onBlur(_e);
    globalOnBlur && globalOnBlur(_e);
  };

  return {
    ...registeredProps,
    onChange: transform
      ? (e) => onChange(transformEventValue(e, transform))
      : onChange,
    onBlur: handleOnBlur,
    error: !!resolveObjPath(name, errors),
    helperText: resolveObjPath(name, errors)?.message,
  };
};

// Adds a saved state to useForm, compares form values with defaultValues
export const useCustomForm = ({
  defaultValues: dirtyDefaultValues,
  compareFunction,
  ...formArgs
}) => {
  const defaultValues = defaultFormValues(dirtyDefaultValues);
  const useFormValues = useForm({
    ...formArgs,
    defaultValues,
  });
  const {
    formState: { isDirty },
    getValues,
    reset,
  } = useFormValues;

  const [isSaved, setIsSaved] = useState(true);
  const compare = compareFunction ?? _.isEqual;

  useEffect(() => {
    if (!isSaved && compare(defaultFormValues(getValues()), defaultValues)) {
      reset(defaultValues);
      setIsSaved(true);
    }
    // if (!_.isEqual(defaultFormValues(getValues()), defaultValues)) {
    //   console.log(diff(defaultFormValues(getValues()), defaultValues));
    // }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isSaved, defaultValues]);

  useEffect(() => {
    if (isDirty && isSaved) setIsSaved(false);
  }, [isDirty, isSaved]);

  return {
    ...useFormValues,
    isSaved,
  };
};

export const moneyValidation = {
  pattern: {
    value: /^\d+\.?(\d+)?$/,
    message: "Must be a valid dollar value",
  },
  transform: (v) => v.replace(/[^0-9.]/g, ""),
  transformOnBlur: (v) => (!v || isNaN(v) ? v : Number(v).toFixed(2)),
};

export const intValidation = {
  pattern: {
    value: /^\d+$/,
    message: "Must be an integer",
  },
  transform: (v) => v.replace(/[^0-9]/g, ""),
};
export const numberValidation = {
  pattern: {
    value: /^\d+\.?(\d+)?$/,
    message: "Must be an number",
  },
  transform: (v) => v.replace(/[^0-9.]/g, ""),
  transformOnBlur: (v) => (!v || isNaN(v) ? v : Number(v)),
};

export const dateValidation = {
  pattern: {
    value: /\d{4}-\d{2}-\d{2}/,
    message: "Invalid date",
  },
};

export const moneyAdornment = {
  startAdornment: <InputAdornment position="start">$</InputAdornment>,
};
