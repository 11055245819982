import React from "react";

import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import makeStyles from "@mui/styles/makeStyles";

import PropTypes from "prop-types";

import { useLocalPagination } from "../../hooks/useLocalPagination";
import {
  availableInventoryHeaders,
  cdcShipListHeaders,
  cdcSummaryHeaders,
  cdcTransferAdviceHeaders,
  customCouponHeaders,
  financeBillingHeaders,
  financeJournalHeaders,
  financeTrackerHeaders,
  focusDetailHeaders,
  focusSummaryHeaders,
  inventoryHeaders,
  invoiceDetailHeaders,
  invoiceDoubleCheckHeaders,
  invoicingHeaders,
  openPurchaseOrdersHeaders,
  orderDetailHeaders,
  orderWindowItemsHeaders,
  orderedItemsHeaders,
  poComplianceHeaders,
  preOrderNonParticipantHeaders,
  printInvoiceHeaders,
  purchaseOrderReportHeaders,
  rfpSummaryHeaders,
  roadmapForecastHeaders,
  supplierDetailHeaders,
  supplierSummaryHeaders,
  variantTerritoryHeaders,
} from "./tableHeaders";

const reportTypeMap = {
  "finance-tracker": financeTrackerHeaders,
  "order-window-summary": focusSummaryHeaders,
  "order-window-detail": focusDetailHeaders,
  "rfp-summary": rfpSummaryHeaders,
  "po-compliance": poComplianceHeaders,
  "available-inventory": availableInventoryHeaders,
  inventory: inventoryHeaders,
  coupon: customCouponHeaders,
  "inbound-item-summary": cdcSummaryHeaders,
  "ship-list": cdcShipListHeaders,
  "transfer-advice": cdcTransferAdviceHeaders,
  "roadmap-forecast": roadmapForecastHeaders,
  "supplier-detail": supplierDetailHeaders,
  "supplier-summary": supplierSummaryHeaders,
  "finance-billing": financeBillingHeaders,
  "finance-journal": financeJournalHeaders,
  "ordered-items": orderedItemsHeaders,
  invoicing: invoicingHeaders,
  "invoice-double-check": invoiceDoubleCheckHeaders,
  "invoice-detail": invoiceDetailHeaders,
  "print-invoice": printInvoiceHeaders,
  "order-window-items": orderWindowItemsHeaders,
  "order-detail": orderDetailHeaders,
  "open-purchase-orders": openPurchaseOrdersHeaders,
  "variant-allocation": variantTerritoryHeaders,
  "purchase-order-report": purchaseOrderReportHeaders,
  "pre-order-non-participant": preOrderNonParticipantHeaders,
};

const ReportTableHead = ({ classes, type }) => (
  <TableHead>
    <TableRow>
      {reportTypeMap[type].map((cell) => (
        <TableCell className={classes.headerText} key={cell.id} align="left">
          {cell.label}
        </TableCell>
      ))}
    </TableRow>
  </TableHead>
);

ReportTableHead.propTypes = {
  classes: PropTypes.object.isRequired,
  type: PropTypes.string.isRequired,
};

const useStyles = makeStyles((theme) => ({
  ...theme.global,
}));

const ReportTable = ({ report, type }) => {
  const classes = useStyles();

  const { scrollRef, reportData } = useLocalPagination(report);

  return (
    <TableContainer
      className={classes.tableContainer}
      style={{ maxHeight: "Calc(100vh - 400px)", marginBottom: "25px" }}
      ref={scrollRef}
    >
      <Table className={classes.table} aria-label={type} stickyHeader>
        <ReportTableHead classes={classes} type={type} />
        <TableBody>
          {reportData.map((item, index) => (
            <TableRow key={index}>
              {reportTypeMap[type].map((cell, i) => (
                <TableCell key={i} align="left">
                  {item[cell.id]}
                </TableCell>
              ))}
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
};

ReportTable.propTypes = {
  report: PropTypes.array,
  type: PropTypes.string.isRequired,
};

export default React.memo(
  ReportTable,
  (prev, next) => prev.report.length === next.report.length
);
